import { put, takeLatest, select } from "redux-saga/effects";
import {
  getBookingFail,
  getBookingSuccess,
  saveBookingFail,
  saveBookingSuccess,
  saveAcceptFail,
  saveAcceptSuccess,
  getSelectRoomSuccess,
  getSelectRoomFail,
  saveSelectRoomSuccess,
  saveSelectRoomFail
} from "../actions/booking";
import { roomDetailSuccess } from "../actions/room";

import {
  GET_BOOKING_REQUEST,
  SAVE_BOOKING_REQUEST,
  SAVE_ACCEPT_REQUEST,
  GET_SELECTROOM_REQUEST,
  SAVE_SELECTROOM_REQUEST
} from "../constants/booking";
import { db } from "../rsf";
import { stateUpdateRequest } from "../actions/state";

function* getSelectRoomRequestHandler({ payload }) {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  try {
    let userInfo = yield db
      .collection("users")
      .doc(cUser.uid)
      .get();
    userInfo = userInfo.data();
    yield put(getSelectRoomSuccess({ selectRoom: userInfo.selectRoomId }));
  } catch (error) {
    yield put(getSelectRoomFail(error));
  }
}

function* saveSelectRoomRequestHandler({ payload }) {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  const wishList = state.room.wishlist;
  const roomId = wishList[payload];
  try {
    yield db
      .collection("users")
      .doc(cUser.uid)
      .update({
        selectRoomId: roomId
      });
    let roomData = yield db
      .collection("rooms")
      .doc(roomId)
      .get();
    roomData = roomData.data();
    yield put(
      roomDetailSuccess({ id: roomId, data: roomData.data, selected: true })
    );
    // const state = { agreeTerm: true };
    // yield put(stateUpdateRequest(state));
  } catch (error) {
    yield put(saveSelectRoomFail(error));
  }
}

function* getBookingRequestHandler({ payload }) {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  try {
    let userInfo = yield db
      .collection("users")
      .doc(cUser.uid)
      .get();
    let userAInfo = yield db
      .collection("users")
      .doc(cUser.uid)
      .collection("application")
      .doc("1")
      .get();
    userAInfo = userAInfo.data();
    userInfo = userInfo.data();
    // //console.log("userInfo", userInfo, userAInfo);
    yield put(getBookingSuccess({ userInfo: userInfo, userAInfo: userAInfo }));
  } catch (error) {
    yield put(getBookingFail(error));
  }
}

function* saveBookingRequestHandler({ payload }) {
  const cardURL = payload.avatarURL;
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  try {
    yield db
      .collection("users")
      .doc(cUser.uid)
      .update({
        cardURL: cardURL
      });
    // const state = { agreeTerm: true };
    // yield put(stateUpdateRequest(state));
    yield put(saveBookingSuccess(cardURL));
  } catch (error) {
    yield put(saveBookingFail(error));
  }
}

function* saveAcceptRequestHandler() {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  try {
    yield db
      .collection("users")
      .doc(cUser.uid)
      .update({
        acceptRegulations: 1
      });
  } catch (error) {
    // //console.log(error);
    yield put(saveAcceptFail(error));
  }
}

export default function* bookingSaga() {
  yield takeLatest(GET_BOOKING_REQUEST, getBookingRequestHandler);
  yield takeLatest(SAVE_BOOKING_REQUEST, saveBookingRequestHandler);
  yield takeLatest(SAVE_ACCEPT_REQUEST, saveAcceptRequestHandler);
  yield takeLatest(GET_SELECTROOM_REQUEST, getSelectRoomRequestHandler);
  yield takeLatest(SAVE_SELECTROOM_REQUEST, saveSelectRoomRequestHandler);
}
