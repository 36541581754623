import {
  SET_START_BOOKING_REQUEST,
  SET_START_BOOKING_SUCCESS,
  SET_START_BOOKING_FAIL,
  GET_START_BOOKING_REQUEST,
  GET_START_BOOKING_FAIL,
  GET_START_BOOKING_SUCCESS
} from "../constants/end";

const initialState = {
  loading: false,
  startmybooking: false,
  status: null,
  error: null
};

export default function questionsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_START_BOOKING_REQUEST:
      return {
        ...state,
        startmybooking: false,
        status: type,
        error: null
      };
    case SET_START_BOOKING_SUCCESS:
      return {
        ...state,
        startmybooking: payload,
        status: type,
        error: null
      };
    case SET_START_BOOKING_FAIL:
      return {
        ...state,
        startmybooking: false,
        status: type,
        error: payload
      };
    case GET_START_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        startmybooking: false,
        status: type,
        error: null
      };
    case GET_START_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        startmybooking: payload,
        status: type,
        error: null
      };
    case GET_START_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        startmybooking: false,
        status: type,
        error: payload
      };
    default:
      return state;
  }
}
