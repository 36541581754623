import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import { ReactComponent as IconBed } from "../../assets/SVG/bed.svg";
import { ReactComponent as IconFloor } from "../../assets/SVG/stairs.svg";
import { ReactComponent as IconKitchen } from "../../assets/SVG/kitchen.svg";
import { ReactComponent as IconBath } from "../../assets/SVG/bathtub.svg";
import { ReactComponent as IconSofa } from "../../assets/SVG/sofa.svg";
import { ReactComponent as IconFacade } from "../../assets/SVG/facade.svg";
import { ReactComponent as IconBalcony } from "../../assets/SVG/balcony.svg";
import { ReactComponent as IconFence } from "../../assets/SVG/fence.svg";
import { IconLeft, IconRight } from "../../components/Icon";

import Slider from "react-slick";

const styles = theme => ({
  root: {
    width: "310px",
    margin: "auto"
  }
});

// backyard: true;
// backyard_will_be_shared_with: 1;
// balcony: false;
// balcony_will_be_shared_with: 1;
// floor: "2";
// id: 1;
// livingroom_will_be_shared_with: 1;
// teracce: false;
// teracce_will_be_shared_with: 1;
// const Common = ({ common, width }) => {
class Common extends React.Component {
  render() {
    const { width, common, classes } = this.props;

    const {
      floor,
      images,
      living_room_shared,
      teracce_will_be_shared_with
    } = common;

    return (
      <div className="roomdetail__description__common">
        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <div className="roomdetail__description__common--item">
              <IconFloor className="icon" />
              <div>
                <p>Floor</p>
                <span>{floor}</span>
              </div>
            </div>

            <div className="roomdetail__description__common--item">
              <IconSofa className="icon" />
              <div>
                <p>Living room</p>
                <span>Shared with {living_room_shared} people</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="roomdetail__description__common--item">
              <IconFacade className="icon" />
              <div>
                <p>Terrace</p>
                <span>Shared with {teracce_will_be_shared_with} people</span>
              </div>
            </div>
            {/* <div className="roomdetail__description__common--item">
            <IconBalcony className="icon" />
            <div>
              <p>Balcony</p>
              <span>
                Shared with {common.balcony_will_be_shared_with}{" "}
                people
              </span>
            </div>
          </div> */}
          </Grid>
        </Grid>

        <div className="roomdetail__description__description">
          <Slider
            dots={true}
            slidesToShow={width < 800 ? 1 : images.length >= 2 ? 3 : 1}
            slidesToScroll={1}
            prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
            nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
            className={
              width < 800 ? null : images.length < 2 ? classes.root : null
            }
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={image.image_title}
                  style={
                    width < 800
                      ? { width: "220px", margin: "auto" }
                      : images.length < 3
                      ? { margin: "0 auto", marginTop: "10px" }
                      : null
                  }
                />
              </div>
            ))}
            {images.length <= 3 &&
              images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={image.image_title}
                    style={
                      width < 800
                        ? { width: "220px", margin: "auto" }
                        : images.length < 3
                        ? { margin: "0 auto", marginTop: "10px" }
                        : null
                    }
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Common);
