import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  withStyles
} from "@material-ui/core";
import { IconCalendar, IconTag } from "../../components/Icon";
import "./styles.scss";

import SignaturePad from "react-signature-pad";

const styles = theme => ({
  backButton: {
    width: "130px !important",
    borderRadius: "24.5px",
    border: "2px solid #f2b143",
    fontSize: "14px",
    textAlign: "center",
    color: "#f2b143",
    textTransform: "initial",
    padding: "12px",
    backgroundColor: "#ffffff",
    margin: "30px 0"
  },
  acceptButton: {
    width: "135px !important",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "24.5px",
    fontSize: "14px",
    textWeight: "bold",
    textTransform: "initial",
    padding: "10px 40px 10px 40px",
    color: "#ffffff",
    margin: "30px 0"
  }
});

class Signature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signature: null
    };
  }

  componentDidMount() {
    const sig = this.signature();
    this.setState({
      signature: sig
    });
  }

  signature = () => {
    var signature = this.refs.mySignature;
    var data;
    if (signature) {
      data = signature.toDataURL();
    } else data = null;
    return data;
  };

  onClear = () => {
    var signature = this.refs.mySignature;
    signature.clear();
  };

  onSubmit = () => {
    var signature = this.refs.mySignature;
    const sign = this.signature();
    if (sign == this.state.signature) return;
    this.props.updateSign(sign);
  };

  render() {
    const { open, onClose, classes } = this.props;
    const {} = this.state;
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" className="dialog">
        <DialogContent className="signup" style={{}}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="signature">
            <p className="signature__text">
              Put your signature below to sign the contract.
            </p>
            <div className="signature__body">
              <SignaturePad ref="mySignature" />
            </div>
            <div className="signature__buttons1">
              <Button
                //   type="gradient"
                onClick={this.onClear}
                className={classes.backButton}
              >
                Clear
              </Button>
              <Button
                //     type="gradient"
                onClick={this.onSubmit}
                className={classes.acceptButton}
              >
                Submit
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Signature));
