import React from "react";
import { connect } from "react-redux";
import ErrorMessage from "../../components/ErrorMessage";
import Grid from "@material-ui/core/Grid";
import { Button, withStyles, List, ListItem } from "@material-ui/core";

import { addMessageRequest, getMessageRequest } from "../../actions/questions";

import TitleText from "../../components/TitleText";
import rightArrow from "../../assets/SVG/right_arrow.svg";
import "./style.scss";

const questions = [
  "Why do you want to live with Cubahaus and join our community?",
  "Tell us your living habits, lifestyle preferences, hobbies, and any other important details. Show us your personality!",
  "Try to convince us that you are clean and tidy.",
  "What is your field of work/study? Where will you be working/studying in Montreal?",
  "Email us a few photos of yourself or some others that represents your personality and lifestyle at: 'info@cubahaus.com'   We want to get a quick idea of who you are! A link to your social medias would be great as well."
];

const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 5px",
    minWidth: "135px"
  }
});

class Questions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: ""
    };
  }

  componentDidMount() {
    this.props.getMessageRequest();
    this.setState({ message: this.props.message });
  }

  handleFormInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      messageError: false
    });
  };

  nextPage = () => {
    this.submitForm();
  };

  submitForm = () => {
    const { message } = this.state;
    //console.log(message);
    if (message === undefined || message === null) {
      this.setState({ messageError: "Please answer the questions to proceed" });
    } else if (message.length < 51) {
      this.setState({
        messageError: "The length of the text must be over 50."
      });
    } else {
      this.props.addMessageRequest(message);
      window.amplitude.getInstance().logEvent("APPLICATION_COMPLETE");
      var metadata = {
        message: message
      };
      window.Intercom("trackEvent", "application-complete", metadata);
    }
  };

  render() {
    const { classes, loading } = this.props;
    const { message, messageError } = this.state;
    console.log("loading", loading);

    if (loading) {
      return null;
    }

    return (
      <div className="content">
        <div className="main">
          <div className="form">
            <div className="questions">
              <div className="questions__title">
                <TitleText>
                We need to know a little about who you are and why you think
                  Cubahaus is suitable for you.
                </TitleText>
              </div>
              <span className="questions__description">
              Show us why Cubahaus needs you in a
                descriptive paragraph.
              </span>
              <Grid
                container
                direction="row"
                justify="space-between"
                className="questions__body"
              >
                <List className="questions__answers">
                  {questions.map((q, index) => (
                    <div className="questions__item" key={index}>
                      <ListItem
                        className="questions__itemFont"
                        component="h2"
                        key={index}
                      >
                        {q}
                      </ListItem>
                    </div>
                  ))}
                </List>
                <textarea
                  rows={5}
                  name="message"
                  type="text"
                  onChange={this.handleFormInput}
                  value={message || ""}
                  state={message}
                  className="questions__input"
                  margin="normal"
                />
              </Grid>
              <p className="answerText">{messageError} </p>
            </div>
            <div className="form__button__container">
              <Button className={classes.button} onClick={this.nextPage}>
                <span className="form__nextButton__text form__nextButton__text--right">
                  Complete
                </span>
                <img
                  src={rightArrow}
                  className="form__arrow form__arrow--right"
                  alt="rightArrow"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ questions }) => {
  //console.log(questions, "--------");
  return {
    message: questions.detail,
    loading: questions.loading
  };
};

const mapDispatchToProps = {
  getMessageRequest,
  addMessageRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Questions));
