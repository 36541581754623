import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import { communityDetailRequest } from "../../actions/community";
import { roomListRequest } from "../../actions/room";

import coummunitySVG11 from "../../assets/mainSVG/community11.svg";
import coummunitySVG12 from "../../assets/mainSVG/community12.svg";
import coummunitySVG13 from "../../assets/mainSVG/community13.svg";
import { IconLocationPin, IconLeft, IconRight } from "../Icon";

import logo from "../../assets/logo.svg";
import "./style.scss";

const styles = theme => ({
  root: {
    display: "flex !important",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  slider: {
    width: "1200px"
  },
  padSlider: {
    width: "410px"
  },
  mobileSlider: {
    width: "410px"
  },
  mobileSlider1: {
    width: "315px",
    overflow: "hidden"
  }
});

class CommunitySlider extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      photoArray: [],
      photoAssetsArray: []
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateCommunity = current => {
    const { communityDetailRequest, roomListRequest } = this.props;
    this.props.updateCurrentCommuinity(current);
    // communityDetailRequest(this.props.communityArray[current].name);
    // roomListRequest(this.props.communityArray[current].name);
  };

  render() {
    const { classes, width } = this.props;
    let { showCount, communityArray, currentCommunity } = this.props;
    if (communityArray.length <= showCount) showCount = 1;
    //  showCount = 3;
    return (
      <Slider
        dots={true}
        infinite={true}
        centerMode={true}
        slidesToShow={showCount > 3 ? 3 : 1}
        prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
        nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
        centerPadding={"0px"}
        slidesToScroll={1}
        autoplay={false}
        initialSlide={currentCommunity}
        className={
          showCount === 3
            ? classes.slider
            : showCount === 2
            ? classes.padSlider
            : width > 450
            ? classes.mobileSlider
            : classes.mobileSlider1
        }
        beforeChange={(old, current) => this.updateCommunity(current)}
        //  onSwipe={e => //console.log("eee", e)}
      >
        {communityArray &&
          communityArray.map((community, i) => (
            <div key={i} className="community__item">
              <div className="community__item__imageDiv">
                <img
                  src={community.thumbnail}
                  className="community__item__image"
                  alt=""
                  style={
                    width <= 450 ? { width: "120px", height: "120px" } : null
                  }
                />
              </div>
              <div
                className="community__item__container"
                style={
                  width <= 450
                    ? {
                        height: "155px",
                        paddingLeft: "75px",
                        width: "150px",
                        paddingRight: "15px"
                      }
                    : null
                }
              >
                <p className="community__item__name">{community.name}</p>
                <p className="community__item__text">Community</p>
                <img
                  src={logo}
                  className="community__item__logo"
                  alt=""
                  style={{ width: "52px", height: "52px" }}
                />
              </div>
            </div>
          ))}
        {/* {communityArray &&
          communityArray.map((community, i) => (
            <div key={i} className="community__item">
              <div className="community__item__imageDiv">
                <img
                  src={community.thumbnail}
                  className="community__item__image"
                  alt=""
                  style={
                    width <= 450 ? { width: "120px", height: "120px" } : null
                  }
                />
              </div>
              <div
                className="community__item__container"
                style={
                  width <= 450
                    ? {
                        height: "155px",
                        paddingLeft: "75px",
                        width: "150px",
                        paddingRight: "15px"
                      }
                    : null
                }
              >
                <p className="community__item__name">{community.name}</p>
                <p className="community__item__text">Community</p>
                <img
                  src={logo}
                  className="community__item__logo"
                  alt=""
                  style={{ width: "52px", height: "52px" }}
                />
              </div>
            </div>
          ))} */}
      </Slider>
    );
  }
}

const mapStateToProps = ({ community, room }) => ({
  ...community,
  room
});

const mapDispatchToProps = {
  communityDetailRequest,
  roomListRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CommunitySlider));
