// import firebase from "firebase";
import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import {
  APPLICATION_DETAIL_REQUEST,
  APPLICATION_DETAIL_UPDATE_REQUEST,
  GENDER_LIST_REQUEST,
  DATES_LIST_REQUEST,
  LENGTH_LIST_REQUEST,
  SEMESTER_LIST_REQUEST
} from "../constants/application";
import {
  applicationDetailSuccess,
  applicationDetailFail,
  applicationUpdateSuccess,
  applicationUpdateFail,
  // genderPreferenceListRequest,
  genderPreferenceListSuccess,
  genderPreferenceListFail,
  // datesListRequest,
  datesListSuccess,
  datesListFail,
  // lengthListRequest,
  lengthListSuccess,
  lengthListFail,
  universityListSuccess,
  semesterListSuccess,
  semesterListFail
} from "../actions/application";

import { stateRequest } from "../actions/state";
import { rsf } from "../rsf";

export function* applicationDetailRequestHandler({ payload }) {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    const res = yield call(
      rsf.firestore.getCollection,
      `users/${uid}/application`
    );

    let application;
    res.forEach(item => {
      application = item.data();
    });
    const universities = yield call(
      rsf.firestore.getCollection,
      "universities"
    );

    let universityList = [];
    universities.forEach(item => {
      universityList.push(item.data());
    });
    const payload = {
      ...application
    };
    yield put(applicationDetailSuccess(payload));
    yield put(universityListSuccess(universityList));
    yield put(stateRequest());
  } catch (err) {
    yield put(applicationDetailFail(err.response));
  }
}

export function* applicationUpdateRequestHandler({ payload }) {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    yield call(
      rsf.firestore.updateDocument,
      `users/${uid}/application/1`,
      payload
    );
    yield put(
      applicationUpdateSuccess({
        ...payload
      })
    );
    yield call(rsf.firestore.updateDocument, `users/${uid}`, "status", 1);
    yield put(applicationUpdateSuccess(payload));
    yield put(stateRequest());
    payload = {
      url: window.location.href,
      referrer: document.referrer,
      userId: uid,
      eventType: 'information'
    }
    const params = {
      url: "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev/collect",
      method: "post",
      data: payload
    };

    yield call(axios.request, params);


  } catch (err) {
    yield put(applicationUpdateFail(err.response));
  }
}

export function* genderPreferenceListRequestHandler() {
  try {
    const res = yield call(rsf.firestore.getCollection, "genderPreference");
    let genderPreferenceList = [];
    res.forEach(item => {
      genderPreferenceList.push(item.data());
    });
    yield put(genderPreferenceListSuccess(genderPreferenceList));
  } catch (err) {
    yield put(genderPreferenceListFail(err.response));
  }
}

export function* semesterListRequestHandler() {
  try {
    const res = yield call(rsf.firestore.getCollection, "semester");
    let semesterList = [];
    res.forEach(item => {
      semesterList.push(item.data());
    });

    yield put(semesterListSuccess(semesterList));
  } catch (err) {
    yield put(semesterListFail(err.response));
  }
}

export function* DatesListRequestHandler() {
  try {
    const res = yield call(rsf.firestore.getCollection, "dates");
    let datesList = [];
    res.forEach(item => {
      datesList.push(item.data());
    });
    yield put(datesListSuccess(datesList));
  } catch (err) {
    yield put(datesListFail(err.response));
  }
}

export function* LengthListRequestHandler() {
  try {
    const res = yield call(rsf.firestore.getCollection, "length");
    let lengthList = [];
    res.forEach(item => {
      lengthList.push(item.data());
    });
    yield put(lengthListSuccess(lengthList));
  } catch (err) {
    yield put(lengthListFail(err.response));
  }
}

export default function* authSaga() {
  yield takeLatest(APPLICATION_DETAIL_REQUEST, applicationDetailRequestHandler);
  yield takeLatest(
    APPLICATION_DETAIL_UPDATE_REQUEST,
    applicationUpdateRequestHandler
  );
  yield takeLatest(GENDER_LIST_REQUEST, genderPreferenceListRequestHandler);
  yield takeLatest(DATES_LIST_REQUEST, DatesListRequestHandler);
  yield takeLatest(LENGTH_LIST_REQUEST, LengthListRequestHandler);
  yield takeLatest(SEMESTER_LIST_REQUEST, semesterListRequestHandler);
}
