import React, { useState, useEffect } from "react";
import { Button, withStyles, makeStyles } from "@material-ui/core";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PostalCodeElement
} from "@stripe/react-stripe-js";
import "./styles.scss";
import visaIcon from "../../assets/SVG/visa.svg";
import mastercardIcon from "../../assets/SVG/mastercard.svg";
import CheckoutStepper from "./stepper";
import ErrorMessage from "../../components/ErrorMessage";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

const useStyles = makeStyles(theme => ({
  submitButton: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    borderRadius: "43.5px",
    lineHeight: "normal",
    fontSize: "33.5px",
    textWeight: "bold",
    textTransform: "initial",
    padding: "30px 60px",
    color: "#ffffff",
    justifyContent: "flex-start",
    margin: "30px 0"
  }
}));

const handleBlur = () => {
  // //console.log("[blur]");
};
const handleChange = change => {
  // //console.log("[change]", change);
};
const handleClick = () => {
  // //console.log("[click]");
};
const handleFocus = () => {
  // //console.log("[focus]");
};
const handleReady = () => {
  // //console.log("[ready]");
};

export default function CheckoutForm({ checkoutRequest }) {
  const [elementFontSize, setFontSize] = useState(
    window.innerWidth < 450 ? "14px" : "18px"
  );
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [nameFirst, setNameFirst] = useState("");
  const [nameLast, setNameLast] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleReset = () => {
    setPaymentMethod(null);
    setIsLoading(false);
    setNameFirst("");
    setNameLast("");
    setAddress1("");
    setAddress2("");
    setError(null);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 450 && elementFontSize !== "14px") {
        setFontSize("14px");
      } else if (window.innerWidth >= 450 && elementFontSize !== "18px") {
        setFontSize("18px");
      }
    });
  });

  const handleSubmit = async ev => {
    ev.preventDefault();
    setErrorMessage(null);
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    // const result = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    //   billing_details: {
    //     name: nameFirst + " " + nameLast,
    //     address: { line1: address1, line2: address2 }
    //   }
    // });

    const result = await stripe.createToken(cardElement);
    setIsLoading(false);
    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      setError(null);
      setErrorMessage(null);
      console.log("payload", result);
      checkoutRequest(result);
    }
  };

  const classes = useStyles();

  return (
    <div
      className="cardcheck"
      //  isLoading={isLoading}
      //  paymentMethod={paymentMethod}
      //  onReset={handleReset}
    >
      {stripe && elements && (
        <form onSubmit={handleSubmit}>
          {/* <CardForm fontSize={elementFontSize} /> */}
          <div style={{ display: "flex" }}>
            <div className="payment1">
              <p className="checkout__step1__title">
                <span>Step 2:</span> Payment info
              </p>
              <div className=" d-flex justify-content-between">
                <label>
                  First Name
                  <div className="input-group inputItem">
                    <input
                      type="text"
                      id="nameFirst"
                      name="nameFirst"
                      placeholder="First Name"
                      className="form-control inputItem__input"
                      value={nameFirst}
                      onChange={event => {
                        setNameFirst(event.target.value);
                      }}
                      {...createOptions(elementFontSize)}
                      required
                    />
                  </div>
                </label>
                <label>
                  Last Name
                  <div className="input-group inputItem">
                    <input
                      type="text"
                      id="nameLast"
                      name="nameLast"
                      placeholder="Last Name"
                      className="form-control inputItem__input"
                      value={nameLast}
                      onChange={event => {
                        setNameLast(event.target.value);
                      }}
                      {...createOptions(elementFontSize)}
                      required
                    />
                  </div>
                </label>
              </div>
              <div className=" d-flex justify-content-between">
                <div className=" d-flex" style={{ width: "45%" }}>
                  <img
                    alt=""
                    src={visaIcon}
                    style={{ height: "35px", marginRight: "20px" }}
                  />
                  <img alt="" src={mastercardIcon} style={{ height: "35px" }} />
                </div>
                <label className="payment__item">
                  Credit Card Number
                  <CardNumberElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    {...createOptions(elementFontSize)}
                  />
                </label>
              </div>
              <div className=" d-flex justify-content-between">
                <label className="payment1__item">
                  Expiration Date
                  <CardExpiryElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    {...createOptions(elementFontSize)}
                  />
                </label>
                <label className="payment1__item display--Block">
                  CVV
                  <CardCvcElement
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onReady={handleReady}
                    {...createOptions(elementFontSize)}
                  />
                </label>
              </div>
              <div className=" d-flex justify-content-between">
                <label>
                  Billing Address
                  <div className="input-group inputItem">
                    <input
                      type="text"
                      id="address1"
                      name="address1"
                      placeholder="Address 1"
                      className="form-control inputItem__input"
                      value={address1}
                      onChange={event => {
                        setAddress1(event.target.value);
                      }}
                      {...createOptions(elementFontSize)}
                      required
                    />
                  </div>
                </label>
                <label>
                  Billing Address 2 (optional)
                  <div className="input-group inputItem">
                    <input
                      type="text"
                      id="address2"
                      name="address2"
                      placeholder="Address 2"
                      className="form-control inputItem__input"
                      value={address2}
                      onChange={event => {
                        setAddress2(event.target.value);
                      }}
                      {...createOptions(elementFontSize)}
                      required
                    />
                  </div>
                </label>
              </div>
            </div>
            <div style={{ width: "50%", paddingLeft: "150px" }}>
              <p
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  color: "#232651",
                  marginBottom: "0.5rem"
                }}
              >
                What happens next?
              </p>
              <CheckoutStepper />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={classes.submitButton}
              //     type="gradient"
              disabled={!stripe}
              type="submit"
            >
              Pay and Sign
            </Button>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {errorMessage && <ErrorMessage msg={errorMessage} />}
          </div>
          <p className="cardcheck__text">
            <span>*</span> You will complete the payment and sign
            <br /> the contract by clicking this button.
          </p>
        </form>
      )}
    </div>
  );
}

// export default withStyles(styles)(CheckoutForm);
// export default injectStripe(CheckoutForm);
