import React from "react";
import leftArrow from "../assets/SVG/left_arrow_orange.svg";
import { Button, withStyles } from "@material-ui/core";
import {
  IconHeart,
  IconClose,
  IconLeft1,
  IconInfo,
  IconLeft,
  IconRight
} from "./Icon";


const styles = theme => ({
  text: {
    fontFamily: "Montserrat",
    fontSize: 15.5,
    fontWeight: 500,
    lineHeight: 2.94,
    letterSpacing: 0.4,
    textAlign: "left",
    color: "#596390",
    textTransform: "none"
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "13px",
    padding: "14px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "50%",
    boxShadow: "0 0 8px 3px rgba(208, 124, 29, 0.3)",
    fill: "#ffffff"
  },

});

const BackButton = ({ classes, onClick }) => {
  return (
    <Button
      disableRipple={true}
      disableFocusRipple={true}
      onClick={onClick}
      variant="text"
      classes={{ text: classes.text }}
    >
      {/* <img src={leftArrow} className={classes.icon} alt="" /> */}
      <IconLeft1 className={classes.icon} alt="" />
      Back
    </Button>
  );
};

export default withStyles(styles)(BackButton);
