import {
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_FAIL,
  SAVE_BOOKING_REQUEST,
  SAVE_BOOKING_SUCCESS,
  SAVE_BOOKING_FAIL,
  SAVE_ACCEPT_REQUEST,
  SAVE_ACCEPT_SUCCESS,
  SAVE_ACCEPT_FAIL,
  GET_SELECTROOM_REQUEST,
  GET_SELECTROOM_SUCCESS,
  GET_SELECTROOM_FAIL,
  SAVE_SELECTROOM_REQUEST,
  SAVE_SELECTROOM_SUCCESS,
  SAVE_SELECTROOM_FAIL
} from "../constants/booking";

export const getSelectRoomRequest = payload => ({
  type: GET_SELECTROOM_REQUEST,
  payload
});

export const getSelectRoomSuccess = payload => ({
  type: GET_SELECTROOM_SUCCESS,
  payload
});

export const getSelectRoomFail = error => ({
  type: GET_SELECTROOM_FAIL,
  error
});

export const saveSelectRoomRequest = payload => ({
  type: SAVE_SELECTROOM_REQUEST,
  payload
});

export const saveSelectRoomSuccess = payload => ({
  type: SAVE_SELECTROOM_SUCCESS,
  payload
});

export const saveSelectRoomFail = error => ({
  type: SAVE_SELECTROOM_FAIL,
  error
});

export const saveBookingRequest = payload => ({
  type: SAVE_BOOKING_REQUEST,
  payload
});

export const saveBookingSuccess = payload => ({
  type: SAVE_BOOKING_SUCCESS,
  payload
});

export const saveBookingFail = error => ({
  type: SAVE_BOOKING_FAIL,
  error
});

export const getBookingRequest = payload => ({
  type: GET_BOOKING_REQUEST,
  payload
});

export const getBookingSuccess = payload => ({
  type: GET_BOOKING_SUCCESS,
  payload
});

export const getBookingFail = error => ({
  type: GET_BOOKING_FAIL,
  error
});

export const saveAcceptRequest = payload => ({
  type: SAVE_ACCEPT_REQUEST,
  payload
});

export const saveAcceptSuccess = payload => ({
  type: SAVE_ACCEPT_SUCCESS,
  payload
});

export const saveAcceptFail = error => ({
  type: SAVE_ACCEPT_FAIL,
  error
});
