export const ROOM_LIST_REQUEST = 'ROOM/ROOM_LIST_REQUEST'
export const ROOM_LIST_SUCCESS = 'ROOM/ROOM_LIST_SUCCESS'
export const ROOM_LIST_FAIL = 'ROOM/ROOM_LIST_FAIL'

export const ROOM_DETAIL_REQUEST = 'ROOM/ROOM_DETAIL_REQUEST'
export const ROOM_DETAIL_SUCCESS = 'ROOM/ROOM_DETAIL_SUCCESS'
export const ROOM_DETAIL_FAIL = 'ROOM/ROOM_DETAIL_FAIL'

export const ROOM_SELECT_REQUEST = 'ROOM/ROOM_SELECT_REQUEST'
export const ROOM_SELECT_SUCCESS = 'ROOM/ROOM_SELECT_SUCCESS'
export const ROOM_SELECT_FAIL = 'ROOM/ROOM_SELECT_FAIL'

export const ROOM_BOOKING_REQUEST = 'ROOM/ROOM_BOOKING_REQUEST'
export const ROOM_BOOKING_SUCCESS = 'ROOM/ROOM_BOOKING_SUCCESS'
export const ROOM_BOOKING_FAIL = 'ROOM/ROOM_BOOKING_FAIL'

export const WISH_LIST_REQUEST = 'WISH/WISH_LIST_REQUEST'
export const WISH_LIST_SUCCESS = 'WISH/WISH_LIST_SUCCESS'
export const WISH_LIST_FAIL = 'WISH/WISH_LIST_FAIL'
export const WISH_LIST_COMPLETE = 'WISH/WISH_LIST_COMPLETE'

export const FINAL_SELECT_REQUEST = 'WISH/FINAL_SELECT_REQUEST'
export const FINAL_SELECT_SUCCESS = 'WISH/FINAL_SELECT_SUCCESS'
export const FINAL_SELECT_FAIL = 'WISH/FINAL_SELECT_FAIL'

export const TRIGGER_FAVORITE_REQUEST = 'ROOM/TRIGGER_FAVORITE_REQUEST'