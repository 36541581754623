import React from "react";
import { Provider } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
// import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor, history } from "./store";
import { checkAuthorization } from "./actions/auth";
import Intercom from "react-intercom";
// import { StripeProvider } from "react-stripe-elements";
import Routes from "./routes";
import COLORS from "./config/colors";

import "./App.scss";

// axios.defaults.baseURL = "https://cubahaus-api.herokuapp.com";
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: COLORS.PRIMARY_COLOR
    }
  }
});

store.dispatch(checkAuthorization());

class App extends React.Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }

  componentDidMount() {
    // const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK;
    // if (window.Stripe) {
    //   this.setState({
    //     stripe: window.Stripe(STRIPE_PK_KEY)
    //   });
    // } else {
    //   document.querySelector("#stripe-js").addEventListener("load", () => {
    //     // Create Stripe instance once Stripe.js loads
    //     this.setState({
    //       stripe: window.Stripe(STRIPE_PK_KEY)
    //     });
    //   });
    // }
  }

  render() {
    return (
      /* <StripeProvider apiKey= {this.state.stripe}> */
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider>
            <MuiThemeProvider theme={theme}>
              <Routes history={history} />
              <Intercom appID="v332ohrb" />
            </MuiThemeProvider>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
      /* </StripeProvider> */
    );
  }
}

export default App;
