// import axios from "axios";
// import firebase from "firebase";
import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import {
  ROOM_LIST_REQUEST,
  ROOM_DETAIL_REQUEST,
  ROOM_SELECT_REQUEST,
  WISH_LIST_COMPLETE,
  FINAL_SELECT_REQUEST,
  TRIGGER_FAVORITE_REQUEST,
  WISH_LIST_REQUEST,
  ROOM_BOOKING_REQUEST
} from "../constants/room";
import {
  roomListSuccess,
  roomListFail,
  roomDetailSuccess,
  // roomDetailFail,
  roomSelectSuccess,
  roomSelectFail,
  wishListSuccess,
  wishListFail,
  // finalSelectSuccess,
  finalSelectFail,
  roomBookingSuccess,
  roomBookingFail
} from "../actions/room";
import { stateRequest, stateFail, stateUpdateRequest } from "../actions/state";
import { enqueueSnackbar } from "../actions/snackbar";
// import { getHeaders } from "../utils/authHelper";
import { db, rsf } from "../rsf";

export function* roomListRequestHandler({ payload }) {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    let wishRoomList = [],
      wishList = [],
      rooms = [],
      roomList = [];
    //console.log("state.application", state.application);
    let minBudget, maxBudget, lengthOfStay;
    if (!state.application.detail) {
      const res3 = yield call(
        rsf.firestore.getCollection,
        `users/${uid}/application`
      );

      let application;
      res3.forEach(item => {
        application = item.data();
      });
      const universities = yield call(
        rsf.firestore.getCollection,
        "universities"
      );

      let universityList = [];
      universities.forEach(item => {
        universityList.push(item.data());
      });
      const payload = {
        ...application
      };
      minBudget = payload.min_budget;
      maxBudget = payload.max_budget;
      lengthOfStay = payload.lengthPreference;
    } else {
      minBudget = state.application.detail.min_budget;
      maxBudget = state.application.detail.max_budget;
      lengthOfStay = state.application.detail.lengthPreference;
    }
    //console.log("detail", minBudget, maxBudget, lengthOfStay);
    const res = yield db
      .collection("wishlist")
      .where("user", "==", uid)
      .get();
    if (res.size > 0) {
      yield res.forEach(data => {
        wishList = data.data().rooms;
      });
      for (let item of wishList) {
        const rsp = yield db
          .collection("rooms")
          .doc(item)
          .get();
        yield wishRoomList.push({
          id: item,
          data: rsp.data().data,
          selected: true
        });
      }
    }

    let query;

    if (lengthOfStay === "8" || lengthOfStay === "12") {
      query = db
        .collection("rooms")
        .where("data.short_term_only", "==", false)
        .where("data.price", ">", minBudget)
        .where("data.price", "<", maxBudget)
        .where("data.booked", "==", false);
    } else {
      query = db
        .collection("rooms")
        .where("data.short_term_only", "==", true)
        .where("data.price", ">", minBudget)
        .where("data.price", "<", maxBudget)
        .where("data.booked", "==", false);
    }
    const res2 = yield query.get();
    let selected = false;
    yield res2.forEach(item => {
      if (wishList && wishList.indexOf(item.id) > -1) {
        selected = true;
      } else selected = false;
      roomList.push({
        id: item.id,
        data: item.data().data,
        selected: selected
      });
    });

    let bookedList = [];
    const booking = yield db.collection("booking").get();
    booking.forEach((item, i) => {
      bookedList.push(item.data().room_id);
    });

    yield put(roomListSuccess({ rooms, roomList }));
    // yield put(roomListSuccess(rooms));
    yield put(wishListSuccess({ wishList, wishRoomList, bookedList }));
  } catch (err) {
    yield put(roomListFail(err.response));
  }
}

export function* triggerFavoriteRequest({ payload }) {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  const wishList = state.room.wishlist;
  const wishRoomList = state.room.wishRoomList;
  const selectedId = wishList[payload];
  const roomList = state.room.list.roomList;
  wishList.splice(payload, 1);
  wishRoomList.splice(payload, 1);
  let doc;
  const res = yield db
    .collection("wishlist")
    .where("user", "==", cUser.uid)
    .get();
  res.forEach(data => {
    doc = data.id;
  });
  yield db
    .collection("wishlist")
    .doc(doc)
    .update({
      rooms: wishList
    });
  const rooms = state.room.list.rooms;
  rooms.forEach((item, i) => {
    if (item.id === selectedId) rooms[i].selected = false;
  });
  yield put(roomListSuccess({ rooms, roomList }));
  yield put(wishListSuccess({ wishList, wishRoomList }));
}
export function* roomDetailRequestHandler({ payload }) {
  const state = yield select();
  const item = state.room.list.rooms[payload];
  yield put(roomDetailSuccess(item));
}

export function* roomSelectRequestHandler({ payload }) {
  const state = yield select();
  let selectedRoom;
  payload < 10
    ? (selectedRoom = state.room.list.rooms[payload])
    : (selectedRoom = payload);
  const item = selectedRoom.id;
  const uid = state.auth.loggedInUser.uid;
  let rooms = state.room.list.rooms;
  let index,
    isFav = true;
  try {
    window.Intercom("trackEvent", "room-select", { item: item });
    window.amplitude.getInstance().logEvent("ROOM_SELECTION");


    let doc,
      wishList = [],
      wishRoomList = state.room.wishRoomList || [];
    const res = yield db
      .collection("wishlist")
      .where("user", "==", uid)
      .get();
    if (res.size > 0) {
      res.forEach(data => {
        doc = data.id;
        wishList = data.data().rooms;
      });
      index = wishList.indexOf(item);
      if (index > -1) {
        wishList.splice(index, 1);
        wishRoomList.splice(index, 1);
        isFav = !isFav;
      } else {
        wishList.push(item);
        const res = yield db
          .collection("rooms")
          .doc(item)
          .get();
        wishRoomList.push({
          id: item,
          data: res.data().data,
          selelcted: true
        });
      }
      yield db
        .collection("wishlist")
        .doc(doc)
        .update({
          rooms: wishList
        });


    } else {
      wishList.push(item);
      yield db.collection("wishlist").add({
        user: uid,
        rooms: wishList
      });
      const res = yield db
        .collection("rooms")
        .doc(item)
        .get();
      wishRoomList.push({
        id: item,
        data: res.data().data,
        selelcted: true
      });
    }
    if (typeof payload !== "number") {
      rooms.map((item, i) => {
        if (item === payload) payload = i;
      });
    }
    rooms[payload].selected = isFav;

    // yield roomDetailRequestHandler({ payload });
        // console.log(state.auth);
    payload = {
      url: window.location.href,
      referrer: document.referrer,
      userId: uid,
      eventType: 'room_select'
    }
    // const params = {
    //   url: "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev/collect",
    //   method: "post",
    //   data: payload
    // };


    const detail = {
      ...selectedRoom,
      selected: isFav
    };
    yield put(roomDetailSuccess(detail));
    yield put(
      roomSelectSuccess({
        index,
        isFav,
        payload,
        wishList,
        wishRoomList,
        rooms
      })
    );
    // yield call(axios.request, params);
  } catch (err) {
    yield put(roomSelectFail(err.response));
  }
}

export function* wishListRequestHandler() {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  let room;
  let wishList,
    bookedList = [],
    wishRoomList = [];
  try {
    const booking = yield db.collection("booking").get();
    booking.forEach((item, i) => {
      bookedList.push(item.data().room_id);
    });
    const res = yield db
      .collection("wishlist")
      .where("user", "==", uid)
      .get();
    yield res.forEach(item => {
      wishList = item.data();
    });
    wishList = wishList.rooms;
    for (let item of wishList) {
      room = yield db
        .collection("rooms")
        .doc(item)
        .get();
      room = room.data();
      wishRoomList.push(room);
    }
    room = {
      wishList,
      wishRoomList,
      bookedList
    };
    yield put(wishListSuccess(room));
  } catch (err) {
    yield put(wishListFail(err.response));
  }
}

export function* wishListCompleteHandler() {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    yield db
      .collection("users")
      .doc(uid)
      .update({ status: 2 });
    yield put(stateRequest());

    const payload = {
      url: window.location.href,
      referrer: document.referrer,
      userId: uid,
      eventType: 'wishlist_complete'
    }
    // const params = {
    //   url: "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev/collect",
    //   method: "post",
    //   data: payload
    // };

    // yield call(axios.request, params);
    
  } catch (err) {
    yield put(stateFail(err));
  }
}

export function* finalSelectRequestHandler({ payload }) {
  const state = yield select();
  const room = state.room;
  payload = payload.bookedRoom;
  const selectedRoomId = room.wishlist[payload];
  const roomDetail = room.wishRoomList[payload];
  roomDetail.data.booked = true;
  try {
    yield db
      .collection("rooms")
      .doc(selectedRoomId)
      .set({
        data: roomDetail.data
      });
    yield put(roomDetailSuccess(roomDetail.data));
    yield put(stateUpdateRequest({ agreeTerm: true }));
  } catch (err) {
    yield put(finalSelectFail(err.response));
    yield put(
      enqueueSnackbar({
        message: "Booking Failed",
        options: { variant: "error" }
      })
    );
  }
}

function* roomBookingRequestHandler({ payload }) {
  try {
    // const bookedList = yield db.collection("booking").get();
    const booked = yield db
      .collection("rooms")
      .doc(payload)
      .get();
    // let isBooked = booked.data().data.booked;
    // booked.data().data.booked = !isBooked;
    let data = booked.data();
    let isBooked = data.data.booked;
    data.data.booked = isBooked ? false : true;

    yield db
      .collection("rooms")
      .doc(payload)
      .set(data);
    // .set(booked);
    yield put(roomBookingSuccess());
  } catch (err) {
    // //console.log(err);
    yield put(roomBookingFail());
  }
}

export default function* authSaga() {
  yield takeLatest(ROOM_LIST_REQUEST, roomListRequestHandler);
  yield takeLatest(ROOM_DETAIL_REQUEST, roomDetailRequestHandler);
  yield takeEvery(ROOM_SELECT_REQUEST, roomSelectRequestHandler);
  yield takeLatest(WISH_LIST_COMPLETE, wishListCompleteHandler);
  yield takeLatest(FINAL_SELECT_REQUEST, finalSelectRequestHandler);
  yield takeLatest(WISH_LIST_REQUEST, wishListRequestHandler);
  yield takeLatest(ROOM_BOOKING_REQUEST, roomBookingRequestHandler);
  yield takeLatest(TRIGGER_FAVORITE_REQUEST, triggerFavoriteRequest);
}
