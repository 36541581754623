import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, withStyles } from "@material-ui/core";
import ErrorMessage from "../../components/ErrorMessage";
import TitleText from "../../components/TitleText";
import BackButton from "../../components/BackButton";
import Timer from "../../components/Timer";
import moment from "moment";
import StripeSection from "../../components/Stripe1";
import SignaturePad from "react-signature-pad";
import {
  getCheckoutPreviewRequest,
  saveTokenRequest,
  moveToSupportRequest
} from "../../actions/checkout";
import { getSelectRoomRequest } from "../../actions/booking";
import CheckingDetail from "../BookingDetail";
import { roomListRequest } from "../../actions/room";
import { stateRequest } from "../../actions/state";
import { status } from "../../constants/user_status";
import "./styles.scss";
import Contract from "../../components/Contract";
import Signature from "../../components/Signature";
import { IconChecked, IconTag, IconGroup } from "../../components/Icon";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/fourSeconds.json";

const styles = theme => ({
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "5px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    minWidth: "135px",
    maxWidth: "140px",
    alignSelf: "flex-end",
    textTransform: "capitalize"
  },
  moreButton: {
    padding: "6px 1px",
    minWidth: "unset"
  }
});
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutStep: false,
      signature: null,
      loading: false,
      open: false,
      selectedRoom: null
    };
  }
  startTimeSeconds = 0;
  remainTime = 0;
  componentDidMount() {
    this.props.getSelectRoomRequest();
    // this.props.roomListRequest();
    if (this.props.detail && this.props.detail.id) {
      console.log("detail", this.props.detail);
      this.props.getCheckoutPreviewRequest(this.props.detail.id);
    } else {
      this.props.moveToSupportRequest();
    }

    this.timer();
  }

  componentDidUpdate() {
    this.timer();
  }

  timer() {
    const { startTime, moveToSupportRequest } = this.props;
    // let startTimeSeconds;
    // let remainTime;
    if (startTime == -2) {
      this.remainTime = 30;
      this.startTimeSeconds = 1;
    } else {
      let nowTime = new Date();
      nowTime = nowTime / 1000;
      this.startTimeSeconds = startTime && startTime.seconds;
      this.remainTime = Math.floor((nowTime - this.startTimeSeconds) / 60);

      if (this.startTimeSeconds > 0 && this.remainTime > 30) {
        //console.log("here|,", this.startTimeSeconds, this.remainTime);
        moveToSupportRequest();
        return;
      }

      this.remainTime = 30 - this.remainTime;
    }
  }

  checkoutNext = () => {
    !this.state.checkoutStep
      ? this.setState({
          checkoutStep: true
        })
      : stateRequest(status.roomPreviewed);
  };

  checkoutRequest = token => {
    const { saveTokenRequest } = this.props;
    // const sign = this.signature();
    // if (sign == this.state.signature) return;
    const payload = {
      token: token,
      sign: this.state.signature
    };
    this.setState({
      loading: true
    });
    console.log("data", payload);
    saveTokenRequest(payload);
  };

  updateSign = sign => {
    this.setState({
      signature: sign,
      open: false
    });
  };

  openSign = () => {
    this.setState({
      open: true
    });
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  onSelectRoom = roomData => {
    this.setState({
      selectedRoom: roomData
    });
  };

  unSelectRoom = () => {
    this.setState({
      selectedRoom: null
    });
  };

  render() {
    const { classes, detail, error, startTime, application } = this.props;
    let { loading, open, signature, selectedRoom } = this.state;
    const roomInfo = detail;
    const today = new Date();
    let messageError = error && error.code;
    if (messageError !== null) {
      //  messageError = "Try again with correct info.";
      loading = false;
      console.log("messageError", messageError);
    }

    //console.log("render", this.remainTime, this.startTimeSeconds);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    console.log("render", roomInfo);

    return (
      roomInfo &&
      (selectedRoom ? (
        <CheckingDetail roomData={selectedRoom} onBack={this.unSelectRoom} />
      ) : (
        <div className="content">
          {loading && (
            <div className="checkout__lottie">
              <Lottie options={defaultOptions} height={400} width={400} />
            </div>
          )}
          <Signature
            open={open}
            onClose={this.onClose}
            updateSign={this.updateSign}
          />
          <div className="roomInfoTimer1">
            <div
              className="roomInfo"
              style={
                loading
                  ? {
                      opacity: "0.2",
                      pointerEvents: "none"
                    }
                  : null
              }
            >
              <img
                alt=""
                src={roomInfo.data.images[0]}
                className="roomInfo__img"
              />
              <div>
                <p className="roomInfo__name">{roomInfo.data.room_name}</p>
                <p className="roomInfo__community">
                  {roomInfo.data.community} Community
                </p>
                <div className="roomInfo__price">
                  <IconTag style={{ width: "22px", fill: "#f2b143" }} /> Price $
                  {roomInfo.data.price} CAD
                </div>
                <div className="roomInfo__text">
                  {/* <div>
                  <IconGroup style={{ width: "22px", fill: "#f2b143" }} />{" "}
                  {roomInfo.data.house.house_capacity}
                </div> */}
                  {/* <div>
                  <IconFullSize style={{ width: "22px", fill: "#f2b143" }} />{" "}
                  {roomInfo.data.house.house_capacity}m2
                </div> */}
                </div>
                <Button
                  //  to={{
                  //    pathname: `/application/checkout/${roomInfo.data.room_name}`,
                  //    state: {
                  //      roomData: roomInfo.data
                  //    }
                  //  }}
                  className={classes.moreButton}
                  onClick={() => this.onSelectRoom(roomInfo)}
                >
                  <div className="roomInfo__moreInfo">More</div>
                </Button>
              </div>
            </div>

            <div
              className="timerItem"
              style={
                loading
                  ? {
                      opacity: "0.2",
                      pointerEvents: "none"
                    }
                  : null
              }
            >
              {startTime && (
                <Timer
                  startTime={startTime}
                  remainTime={this.remainTime}
                  startTimeSeconds={this.startTimeSeconds}
                />
              )}
            </div>
          </div>

          <div
            className="checkout"
            style={
              loading
                ? {
                    opacity: "0.1",
                    pointerEvents: "none",
                    height: "1200px",
                    overflowY: "hidden",
                    paddingBottom: "unset"
                  }
                : null
            }
          >
            {/* <BackButton onClick={() => history.goBack()} /> */}
            <div className="checkout__title">
              <TitleText>
                Please, sign the main <br /> contract and add your payment info{" "}
              </TitleText>
            </div>
            <div className="roomInfoTimer">
              <div
                className="roomInfo"
                style={
                  loading
                    ? {
                        opacity: "0.2",
                        pointerEvents: "none"
                      }
                    : { position: "unset" }
                }
              >
                <img
                  alt=""
                  src={roomInfo.data.images[0]}
                  className="roomInfo__img"
                />
                <div>
                  <p className="roomInfo__name">{roomInfo.data.room_name}</p>
                  <p className="roomInfo__community">
                    {roomInfo.data.community} Community
                  </p>
                  <div className="roomInfo__price">
                    <IconTag style={{ width: "22px", fill: "#f2b143" }} /> Price
                    ${roomInfo.data.price} CAD
                  </div>
                  <div className="roomInfo__text">
                    {/* <div>
                  <IconGroup style={{ width: "22px", fill: "#f2b143" }} />{" "}
                  {roomInfo.data.house.house_capacity}
                </div> */}
                    {/* <div>
                  <IconFullSize style={{ width: "22px", fill: "#f2b143" }} />{" "}
                  {roomInfo.data.house.house_capacity}m2
                </div> */}
                  </div>
                  <Button
                    //  to={{
                    //    pathname: `/application/checkout/${roomInfo.data.room_name}`,
                    //    state: {
                    //      roomData: roomInfo.data
                    //    }
                    //  }}
                    className={classes.moreButton}
                    onClick={() => this.onSelectRoom(roomInfo)}
                  >
                    <div className="roomInfo__moreInfo">More</div>
                  </Button>
                </div>
              </div>

              <div
                className="timerItem"
                style={
                  loading
                    ? {
                        opacity: "0.2",
                        pointerEvents: "none"
                      }
                    : { position: "unset" }
                }
              >
                {startTime && (
                  <Timer
                    startTime={startTime}
                    remainTime={this.remainTime}
                    startTimeSeconds={this.startTimeSeconds}
                  />
                )}
              </div>
            </div>
            <div className="checkout__step1">
              <p className="checkout__step1__title">
                <span>Step 1:</span> Sign the main contract
              </p>
              <div>
                <p className="checkout__step1__text">Room Rental Agreement</p>
              </div>
              <p className="checkout__step1__title">Agreement:</p>

              <p className="checkout__step1__text1">
                By the present, the following is agreed on between{" "}
                {this.props.user.first_name} {this.props.user.last_name} the
                Applicant and <span>9357-4978 Quebec Inc.</span>, the Renter:
              </p>

              <p className="checkout__step1__text2">
                1. Leased room address: Applicant shall occupy{" "}
                {roomInfo.data.room_name} in #
                {roomInfo.data.house.house_address}
                <br />
              </p>
              <p className="checkout__step1__text2">
                2. Rental period: from{" "}
                {application.detail &&
                  moment(application.detail.datesPreference).format(
                    "YYYY.DD.MM"
                  )}{" "}
                to{" "}
                {application.detail &&
                  moment(application.detail.datesPreference)
                    .add(application.detail.lengthPreference, "months")
                    .subtract(1, "day")
                    .format("YYYY.DD.MM")}
                <br />
              </p>
              <p className="checkout__step1__text2">
                3. Rent: ${roomInfo.data.price}/month <br />
              </p>
              <p className="checkout__step1__text2">
                4. Included in the rent: Furniture, internet, heat, electricity
                and hot water <br />
              </p>
              <p className="checkout__step1__text2">
                5. Room rental extension: Shall be subject to availabiltiy and
                renter's approval <br />
              </p>
              <p className="checkout__step1__text2">
                6. Shared common areas:
                <br />
                It shall be understood that there will be common areas, such as
                living room, kitchen and bathroom that the applicant will share
                with other occupants in the apartment. <br />
              </p>
              <p className="checkout__step1__text2">
                7.{" "}
                <span style={{}}>
                  Regulations: Applicant confirms to have read both home and
                  building regulations and agrees to respect them during his
                  period of stay in the apartment, a copy herein enclosed.
                </span>
                <br />
              </p>
              {/* <p className="checkout__step1__text2">
                8. Rental Deposit: ${roomInfo.data.price} that covers the first
                and last month of the rental period will be required at the
                signing of this agreement.selected
                <br />
              </p> */}
              <p className="checkout__step1__text2">8. Pets: Not allowed</p>

              {/* <p className="checkout__step1__signtitle">
                {this.props.user.first_name} {this.props.user.last_name}
                I, {application.detail.firstName} {application.detail.lastName}{" "}
                the undersigned, certify that the above information is true and
                correct. I also acknowledge having read the “Building, Home and
                Community Regulations” and agree to fully respect them during
                the period of my stay in this apartment.
              </p>
 */}
              <p className="checkout__step1__signtitle">Agreement Signed:</p>
              <div className="checkout__step1__signItems">
                <div className=" checkout__step1__signItem">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "41px"
                    }}
                  >
                    {signature && (
                      <img
                        alt=""
                        src={signature}
                        className=" checkout__step1__signItem__signature"
                      />
                    )}
                    {signature ? (
                      <IconChecked style={{ width: "27px", fill: "#66f3ff" }} />
                    ) : (
                      <Button
                        onClick={this.openSign}
                        className={classes.button}
                      >
                        Sign here
                      </Button>
                    )}
                  </div>
                  <p className="checkout__step1__signItem__text">
                    Signature of the Applicant
                  </p>
                </div>
                <div className=" checkout__step1__signItem">
                  <p className="checkout__step1__signItem__textDate">
                    <b>{moment(today).format("MM.DD.YYYY")}</b>
                  </p>
                  <p className="checkout__step1__signItem__text">Date</p>
                </div>
              </div>
              {/* <div className="checkout__step1__signItems">
                <div className=" checkout__step1__signItem">
                  <div className=" checkout__step1__signImg"></div>
                  <p className="checkout__step1__signItem__text">
                    Signature of the Applicant
                  </p>
                </div>
                <div className=" checkout__step1__signItem">
                  <p className="checkout__step1__signItem__textDate">
                    <b>{moment(today).format("MM.DD.YYYY")}</b>
                  </p>
                  <p className="checkout__step1__signItem__text">
                  Date:
                  </p>
                </div>
              </div> */}
            </div>
            <div
              className="checkout__step2"
              style={
                signature ? null : { opacity: "0.5", pointerEvents: "none" }
              }
            >
              <div style={{ display: "flex" }}>
                <StripeSection
                  checkoutRequest={this.checkoutRequest}
                  loading={loading}
                />
                <ErrorMessage msg={messageError} />
              </div>
            </div>
          </div>
        </div>
      ))
    );
  }
}

const mapStateToProps = ({ room, auth, checkout, application, booking }) => ({
  room: room,
  application: application,
  error: checkout.error,
  detail: room.detail,
  user: auth.loggedInUser,
  startTime: checkout.startTime,
  selectRoomId: booking.selectRoomId
});

const mapDispatchToProps = {
  getCheckoutPreviewRequest,
  stateRequest,
  saveTokenRequest,
  getSelectRoomRequest,
  roomListRequest,
  moveToSupportRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Checkout));
