import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, withStyles, Grid, IconButton } from "@material-ui/core";
import styled from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet";

import ErrorMessage from "../../components/ErrorMessage";
import Signup from "../../components/Signup";
import Button from "../../components/ButtonContainer";
import InputTextField from "../../components/InputTextField";
import LandingSlider from "../../components/LandingSlider";
import LandingMobileSlider from "../../components/LandingMobileSlider";
import contactImg from "../../assets/landing/17.svg";
import situationImg from "../../assets/mainSVG/calque7.png";
import situationImgM from "../../assets/mainSVG/calque3.png";
import includedImg from "../../assets/landing/3.svg";
import home1 from "../../assets/mainSVG/home1_.svg";
import home2 from "../../assets/mainSVG/home2_.svg";
import home3 from "../../assets/mainSVG/home3_.svg";
import home4 from "../../assets/mainSVG/home4.svg";
import backgroundImg from "../../assets/mainSVG/homebackground1.svg";
import uniteImg from "../../assets/landing/1.png";
import titlebackground from "../../assets/mainSVG/homebackground11.svg";
import logoImg from "../../assets/logo_icon_color.png";

import {
  IconGlobal,
  IconDebate,
  IconBreakfast,
  IconHotel,
  IconWifi,
  IconFlash,
  IconShower,
  IconKitchen,
  IconWashingMachine,
  IconThermometer,
  IconRocket,
  IconMountains,
  IconHighfive,
  IconBicycle,
  IconUniversity,
  IconBooks,
  IconBike
} from "../../components/Icon";

import "./landing.scss";
import Axios from "axios";

const useStyles = {
  textName: {
    marginTop: "70px",
    display: "block",
    fontFamily: "Montserrat",
    fontSize: "18.5px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    textAlign: "left",
    color: "#27346e"
  },
  icon: {
    height: 55,
    width: 55,
    marginRight: 18,
    verticalAlign: "middle",
    fill: "#e6813b"
  },
  ButtonIcon: {
    height: 55,
    width: 55,
    marginRight: 7,
    verticalAlign: "middle",
    fill: "#ffffff"
  },
  commonItem: {
    flexDirection: "row",
    display: "flex",
    width: "100%"
  },
  commonIcon: {
    height: "62px",
    width: "62px",
    marginRight: "25px",
    verticalAlign: "top",
    fill: "#e6813b"
  },
  commonText: {
    width: "100%",
    fontFamily: "Montserrat",
    fontSize: "25.5px",
    fontWeight: "bold",
    lineHeight: 1.14,
    letterSpacing: 0.6,
    textAlign: "left",
    color: "#27346e",
    marginTop: "0px",
    marginBottom: "5px"
  },
  commonSubText: {
    maxWidth: "500px",
    fontSize: "14.5px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontStretch: "normal",
    letterSpacing: "0.4px",
    lineHeight: 2,
    textAlign: "left",
    color: "#4c5788",
    marginBottom: "30px"
  }
};

const Background1 = styled.div`
  width: 356px;
  height: 252px;
  position: absolute;
  background-image: url("/assets/landing/2.svg");
  background-repeat: no-repeat;
  background-size: inherit;
  background-position-x: center;
  clip-path: ellipse(90% 55% at 56% 16%);
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 47.5px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.2px;
  color: #26356b;
`;

const MainText = styled.div`
  font-family: Montserrat;
  font-size: 18.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.97;
  text-align: left;
  letter-spacing: 0.2px;
  margin-top: 42px;
  margin-bottom 61px;
  color: rgba(39, 52, 110, 0.73);
`;

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRef1 = React.createRef();
    this.state = {
      signUpShow: false,
      width: props.width,

      firstName: "",
      lastName: "",
      email: "",
      message: ""
    };

    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.contactUs)
      this.scrollToMyRef1();
    //  this.knowmore();
  }

  componentDidUpdate() {
    if (this.props.location.state && this.props.location.state.contactUs)
      this.scrollToMyRef1();
  }

  componentWillMount() {
    this.updateScreenWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  scrollToMyRef1 = () =>
    window.scrollTo(0, this.myRef1.current.offsetTop - 100);
  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop - 10);

  knowmore = e => {
    this.scrollToMyRef();
  };

  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  handleOpenSignup = e => {
    this.setState({
      signUpShow: true
    });
  };

  onClose = e => {
    this.setState({ signInShow: false, signUpShow: false });
  };

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
      firstnameError: false,
      lastnameError: false,
      emailError: false,
      messageError: false
    });
  };

  send = e => {
    e.preventDefault();

    const { registerRequest } = this.props;
    const { firstName, lastName, email, message } = this.state;
    if (firstName === "" || lastName === "") {
      this.setState({ usernameError: true });
    } else if (email === "") {
      this.setState({ emailError: true });
    } else if (message === "") {
      this.setState({ messageError: true });
    } else {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        message: message
      };
      axios({
        method: "post",
        url: "https://4d99wr2yy3.execute-api.us-east-1.amazonaws.com/prod",
        data: formData,
        config: { headers: { "Cotent-Type": "multipart/form-data" } }
      }).then(res => {
        if (res.data.message === "SUCCESS") {
          //console.log(res);
          alert("we got your message, we will reply as soon as possible");
        }
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      width,
      firstName,
      lastName,
      message,
      email,
      firstnameError,
      lastnameError,
      emailError,
      messageError
    } = this.state;
    const isPad = width >= 600 && 1300 >= width ? true : false;
    const isMobile = width <= 600 ? true : false;
    const space =
      width < 350 ? (
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      ) : width < 400 ? (
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      ) : width < 450 ? (
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      ) : width < 1014 ? (
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      ) : (
        ""
      );
    return (
      <div className="landing">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>International Students Housing | Cubahaus </title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://cubahaus.ca" />
          <meta property="og:url" content="https://cubahaus.ca" />
          <meta
            property="og:title"
            content="Cubahaus International Student Housing"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/cubahaus/image/upload/v1537637586/cubahaus-banner.jpg"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@cuba_haus" />
          <meta
            name="twitter:title"
            content="Cubahaus International Student Housing"
          />
          <meta
            name="twitter:description"
            content="Apply to International Student Housing in Montreal."
          />
          <meta
            name="twitter:image"
            content="https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1527271623/cubahaus-banner.jpg"
          />
          <script type="application/ld+json">{`
       {
              
        "@context":"https:\/\/schema.org",
        "@type":"Organization",
        "url":"https:\/\/cubahaus.ca\/",
        "sameAs":["https:\/\/www.facebook.com\/Cubahaus",
        "http:\/\/instagram.com\/cubahaus\/",
        "https:\/\/twitter.com\/cuba_haus"],
        "@id":"https:\/\/cubahaus.ca\/#organization",
        "name":"Cubahaus",
        "logo":"https://cubahaus.ca/favicon.ico"},
        "contactPoint": [{
          "@type": "ContactPoint",
          "telephone": "+1-514-649-9444",
          "contactType": "customer service"
        }]
        }
      }
        `}</script>
        </Helmet>

        <Signup open={this.state.signUpShow} onClose={this.onClose} />
        {/* <img
          alt="backgroundImg"
          src={backgroundImg}
          className="section1__backgroundImg"
        /> */}
        <Grid className="section1">
          <div className="section1__unite">
            {/* <img
              alt="backgroundImg"
              src={uniteImg}
              className="section1__unite__img"
            /> */}
            <div className="section1__unite__body">
              <Title>Unite great minds</Title>
              <MainText>
              Welcome to Montreal. Join our community and rent your room in our exceptional home.
              </MainText>
              <IconButton
                className="section1__unite__body__button"
                onClick={this.knowmore}
              >
                Learn More
              </IconButton>
            </div>

            {/* <Background1 className="section1__unite__background1" /> */}
          </div>
          <div className="section1__background" />
        </Grid>
        {/* <img className="section1__logo" alt="logoImg" src={logoImg} /> */}
        <Grid className="section2">
          <div ref={this.myRef} className="section2__housing">
            <div className="section2__housing__slider">
              <LandingSlider />
            </div>
            <div className="section2__housing__mobileslider">
              <LandingMobileSlider />
            </div>

            <div
              className="section2__housing__body"
              ref={divElement => (this.divElement = divElement)}
            >
              <Title>
                All-Inclusive <br />
                Student Housing
              </Title>
              <MainText
                style={{
                  lineHeight: 2.0,
                  width: "490px",
                  marginBottom: "unset"
                }}
              >
              In short, we’re renting furnished rooms! Cubahaus is a  community of international students sharing their time. We live in the same neighborhood but separate apartments. You can rent a private or shared bedroom and will share the common spaces with a few housemates. We are tailored for people new to Montreal! 
              </MainText>
            </div>
          </div>
          <div
            className="section2__included"
            style={isPad ? { width: { width } } : null}
          >
            <div className="section2__included__body">
              <Title>What's Included?</Title>
              <div className="section2__included__body__items">
                <div>
                  <Typography
                    //      inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconThermometer className={classes.icon} />
                    Heating
                  </Typography>
                  <Typography
                    //    inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconFlash className={classes.icon} />
                    Electricity
                  </Typography>
                  {/* {width < 769 ? (
                    <Typography
                //      inline = {true}
                      gutterBottom={false}
                      variant="h5"
                      component="h2"
                      className={classes.textName}
                    >
                      <IconWashingMachine className={classes.icon} />
                      Washer &
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dryer
                    </Typography>
                  ) : ( */}
                  <Typography
                    //     inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconWashingMachine className={classes.icon} />
                    Washer & {space}Dryer
                  </Typography>
                  {/* )} */}
                  {/* {width < 769 ? (
                    <Typography
               //       inline = {true}
                      gutterBottom={false}
                      variant="h5"
                      component="h2"
                      className={classes.textName}
                    >
                      <IconHotel className={classes.icon} />
                      Fully
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;furnished
                    </Typography>
                  ) : ( */}
                  <Typography
                    //    inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconHotel className={classes.icon} />
                    Fully {space}furnished
                  </Typography>
                  {/* )} */}
                </div>
                <div style={{ marginLeft: "35px" }}>
                  <Typography
                    //     inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconShower className={classes.icon} />
                    Hot Water
                  </Typography>
                  <Typography
                    //      inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconWifi className={classes.icon} />
                    Internet
                  </Typography>
                  {/* {width < 769 ? (
                    <Typography
              //        inline = {true}
                      gutterBottom={false}
                      variant="h5"
                      component="h2"
                      className={classes.textName}
                    >
                      <IconKitchen className={classes.icon} />
                      Equipped
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;kitchens
                    </Typography>
                  ) : ( */}
                  <Typography
                    //       inline = {true}
                    gutterBottom={false}
                    variant="h5"
                    component="h2"
                    className={classes.textName}
                  >
                    <IconKitchen className={classes.icon} />
                    Equipped {space}kitchens
                  </Typography>
                  {/* )} */}
                </div>
              </div>
            </div>
            <img
              alt="includedImg"
              src={includedImg}
              className="section2__included__img"
            />
          </div>
          <div className="section2__apply">
            <Title> Rent a room with us! </Title>
            <p className="section2__apply__text">
              <b>start the process! </b>
            </p>
            <IconButton
              className="section2__apply__ButtonApply"
              //  onClick={this.handleOpenSignup}
              onClick={() =>
                window.amplitude.getInstance().logEvent("APPLY_MIDDLE_LANDING")
              }
              component={Link}
              to="/register"
              style={{ padding: "unset" }}
            >
              <IconRocket className={classes.ButtonIcon} />
              Apply
            </IconButton>
          </div>
        </Grid>
        <Grid className="section3">
          {/* <Background1 className="section3__background1" /> */}
          <div className="section3__vibes">
            <p className="section3__vibes__title">#cubahaus</p>
            <Title style={{ marginBottom: "50px" }}>Good vibes</Title>
            <div className="section3__vibes__post">
              <div className="section3__vibes__post__items">
                <div className={classes.commonItem}>
                  <IconBike className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Share meals</p>
                    <p className={classes.commonSubText}>
                        We're never too busy to share a meal from Monday night wine and cheeses to international pot luck to 3 am poutines. Eating meals together has been proven to have a long list of benefits like strengthening bonds, reducing stress, and building communication skills.
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconMountains className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Study together</p>
                    <p className={classes.commonSubText}>
        In Cubahaus, we take our academic performance seriously, join the community's work-hard vibe and get together for a study session at home, the libraries, or cafés.
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconHighfive className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>
                      Debate, philosophize, learn
                    </p>
                    <p className={classes.commonSubText}>
                              You’ll be matched with roommates from across the world where you can exchange cultural ideas and discuss art, philosophy, society, politics, technology, and whatever else.
                    </p>
                  </div>
                </div>
              </div>
              <img
                alt="home1"
                src={home2}
                className="section3__vibes__post__img"
              />
            </div>
            <div
              className="section3__vibes__post"
              style={!isMobile ? { flexDirection: "row-reverse" } : null}
            >
              <div className="section3__vibes__post__items">
                <div className={classes.commonItem}>
                  <IconBicycle className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Stay active</p>
                    <p className={classes.commonSubText}>
In addition to its general effects on physical and mental well-being, regular physical activity is linked to improved concentration and learning abilities. With many parks, gyms, swimming pools, and drop-in sport activities located near our houses, you can be sure to stay active.
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconUniversity className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Conquer mountain tops</p>
                    <p className={classes.commonSubText}>
                      Many parks, gyms, swimming pools, and drop-in sports activities located near our houses, you can be sure to stay active.
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconBooks className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>
                      Meet people from across the planet
                    </p>
                    <p className={classes.commonSubText}>
                      Being with people from different countries daily
                      is a unique chance to open your mind and get to know your
                      world.
                    </p>
                  </div>
                </div>
              </div>

              <img
                alt="home2"
                src={home3}
                className="section3__vibes__post__img"
              />
            </div>
            <div className="section3__vibes__post">
              <div className="section3__vibes__post__items">
                <div className={classes.commonItem}>
                  <IconBreakfast className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Explore Montreal</p>
                    <p className={classes.commonSubText}>
                    Randomly feel like exploring the city on a Tuesday night?
                      Whether it’s Old Montreal, The Old Port, China town, Place
                      Des festivals, Mount Royal, Little Italy, our group chats
                      makes it easy to meet up and explore spontaneously
                      Montreal’s many diverse districts.
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconDebate className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Join our road trips</p>
                    <p className={classes.commonSubText}>
                      Cubahaus is famous for its road trips! Get close to nature
                      with our weekend getaways. Disconnect from the city and
                      connect with your new housemates!
                    </p>
                  </div>
                </div>
                <div className={classes.commonItem}>
                  <IconGlobal className={classes.commonIcon} />
                  <div>
                    <p className={classes.commonText}>Make friends for life</p>
                    <p className={classes.commonSubText}>
                    There is nothing more rewarding for us than scrolling down
                      our social media to see past members meeting together
                      several years after their time in Cubahaus!
                    </p>
                  </div>
                </div>
              </div>
              <img
                alt="home3"
                src={home1}
                className="section3__vibes__post__img"
              />
            </div>
          </div>
          <div className="section3__situation">
            <div className="section3__situation__body">
              <Title>A Unique Living Situation</Title>
              <MainText className="section3__situation__text">
                Go on the journey abroad you've always dreamed of. <br />
                <b>
                  Cubahaus is a unique living situation you won’t get anywhere
                  else. 
                </b> <br/>
                Those selected to become a part of Cubahaus are friendly, ambitious young adults who display a strong desire to connect and collaborate with  <b> similar great minds</b>.
                {/* <br />
                We are looking for people calm and quiet at home but interested
                to go out to participate in various activities. <br /> */}
              </MainText>
              <img
                alt="situationImg"
                src={width > 600 ? situationImg : situationImgM}
                className="section3__situation__body__img"
              />
            </div>
            {/* <img alt="home4" src={home4} className="section3__situation__img" /> */}
          </div>
          {/* {isMobile && (
            <img
              alt="situationImg"
              src={situationImg}
              className="section3__situation__body__img"
            />
          )} */}
        </Grid>
        {/* <Grid className="section4">
          <div className="section4__contact">
            <Title>Apply Now</Title>
            <div ref={this.myRef1} className="section4__contact__body">
              <div className="section4__contact__body__items">
                <MainText className="section4__contact__body__text">
                  <b>
                    Check out our rooms, make your wishlist and send us your
                    application letter.
                  </b>
                </MainText>
                <IconButton
                  className="section2__apply__ButtonApply"
                  //  onClick={this.handleOpenSignup}
                  onClick={() =>
                    window.amplitude.getInstance().logEvent("APPLY_NOW_CTA")
                  }
                  component={Link}
                  to="/register"
                  style={{ padding: "unset" }}
                >
                  <IconRocket className={classes.ButtonIcon} />
                  Apply
                </IconButton>
              </div>
              <img
                alt="contactImg"
                src={contactImg}
                className="section4__contact__img"
              />
            </div>
          </div> */}
          {/* <div className="section4__submit">
            <div className="modal">
              <p className="modal__title">Any questions?</p>
              <div className="modal__body">
                <div
                  className="modal__body__input"
                  style={{ width: "360px", height: "300px", display: "grid" }}
                >
                  <InputTextField
                    name="firstName"
                    label="First Name:"
                    type="text"
                    onChange={this.handleInputChange}
                    state={firstName}
                    margin="normal"
                  />
                  <ErrorMessage msg={firstnameError} />
                  <InputTextField
                    name="lastName"
                    label="Last Name:"
                    type="text"
                    onChange={this.handleInputChange}
                    state={lastName}
                    margin="normal"
                  />
                  <ErrorMessage msg={lastnameError} />
                  <InputTextField
                    name="email"
                    label="Email:"
                    type="text"
                    onChange={this.handleInputChange}
                    state={email}
                    margin="normal"
                  />
                  <ErrorMessage msg={emailError} />
                </div>
                <div className="modal__body__msg">
                  <p className="modal__body__label">Short Message:</p>
                  <textarea
                    name="message"
                    rows="5"
                    type="text"
                    onChange={this.handleInputChange}
                    state={message}
                    className="modal__body__message"
                    placeholder="Short Message"
                  />
                  <ErrorMessage msg={messageError} />
                </div>
              </div>
              <IconButton
                style={{ padding: "0px", borderRadius: "25px" }}
                onClick={this.send}
              >
                <Button type="gradient" size="small">
                  Send
                </Button>
              </IconButton>
            </div>
          </div> */}
        {/* </Grid> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

// const mapDispatchToProps = {};

const styledLanding = withStyles(useStyles)(Landing);

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(styledLanding);
