import React from "react";
import { connect } from "react-redux";
import { logoutRequest } from "../../actions/auth";
import styled from "styled-components";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import mobilebackground from "../../assets/landing/background2.svg";

const Background = styled.div`
  width: 100%;
  height: 558px
  position: absolute;
  bottom: 0;
  background-image: url("/assets/background1.png");
  background-size: inherit;
  background-position-x: center;
  background-repeat: no-repeat;
  z-index: -2;
`;

const LeftCircle = styled.div`
  width: 300px;
  height: 300px
  position: absolute;
  top: 40%;
  left: -100px;
  background-image: url("/assets/circle_2.png");
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  z-index: -2;
`;

const RightCircle = styled.div`
  width: 100px;
  height: 200px
  position: absolute;
  top: 15%;
  right: 0px;
  background-image: url("/assets/circle_1.png");
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  z-index: -2;
`;

const HomeBackground = styled.div`
  width: 55%;
  height: 969px;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  background-image: url("/assets/background2.png");
  background-size: inherit;
  background-position-y: center;
  display: ${props => (props.isHome ? "block" : "none")};
`;

const HomeBackgroundImage = styled.div`
  max-width: 784px;
  width: 85%;
  height: 642px;
  background-image: url(/assets/people.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  margin-top: 135px;
`;

class DefaultLayout extends React.PureComponent {
  onClickLogo = () => {
    this.props.history.push("/");
  };

  onClickProfile = () => {
    this.props.history.push("/profile");
  };

  onClickLogout = () => {
    this.props.logoutRequest();
  };

  render() {
    const { isLoggedIn, children, location } = this.props;
    return (
      <div
        className={isLoggedIn ? "container" : ""}
        style={
          this.props.location.pathname === "/book"
            ? { height: "unset", overflowX: "hidden" }
            : { overflowX: "hidden" }
        }
      >
        <Header
          isLoggedIn={isLoggedIn}
          onClickLogo={this.onClickLogo}
          onClickProfile={this.onClickProfile}
          onClickLogout={this.onClickLogout}
          pathName={location.pathname}
        />
        {/* <div className={isLoggedIn ? "content" : ""}>{children}</div> */}
        <div className={isLoggedIn ? "" : ""}>{children}</div>
        {isLoggedIn && this.props.location.pathname === "/book" ? (
          <Background className="background11" />
        ) : (
          <Background />
        )}
        {isLoggedIn && this.props.location.pathname !== "/book" && (
          <RightCircle />
        )}
        {isLoggedIn && this.props.location.pathname !== "/book" && (
          <LeftCircle />
        )}
        <HomeBackground
          className="background1"
          isHome={location.pathname === "/book"}
        >
          <HomeBackgroundImage id="homebackgroundImage" />
        </HomeBackground>
        {location.pathname === "/book" && (
          <div className="mobilebackground">
            <img
              src={require("../../assets/landing/background2.png")}
              alt=""
              className="mobilebackground__background"
            />
            <img
              src={require("../../assets/landing/people.png")}
              alt=""
              className="mobilebackground__img"
            />
          </div>
        )}

        {!isLoggedIn && (
          <Footer
            isLoggedIn={isLoggedIn}
            onClickLogo={this.onClickLogo}
            onClickProfile={this.onClickProfile}
            onClickLogout={this.onClickLogout}
            pathName={location.pathname}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: auth.loggedInUser !== null
});

const mapDispatchToProps = {
  logoutRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);