import {
  GET_CHECKOUT_PREVIEW_REQUEST,
  GET_CHECKOUT_PREVIEW_SUCCESS,
  GET_CHECKOUT_PREVIEW_FAIL,
  SAVE_TOKEN_REQUEST,
  SAVE_TOKEN_SUCCESS,
  SAVE_TOKEN_FAIL, 
  MOVE_TO_SUPPORT_REQUEST,
  MOVE_TO_SUPPORT_SUCCESS,
  MOVE_TO_SUPPORT_FAIL
} from "../constants/checkout";

export const getCheckoutPreviewRequest = (payload) => ({
  type: GET_CHECKOUT_PREVIEW_REQUEST,
  payload
});

export const getCheckoutPreviewSuccess = payload => ({
  type: GET_CHECKOUT_PREVIEW_SUCCESS,
  payload
});

export const getCheckoutPreviewFail = error => ({
  type: GET_CHECKOUT_PREVIEW_FAIL,
  error
});

export const saveTokenRequest = payload => ({
  type: SAVE_TOKEN_REQUEST,
  payload
})

export const saveTokenSuccess = payload => ({
  type: SAVE_TOKEN_SUCCESS,
  payload
})
export const saveTokenFail = error => ({
  type: SAVE_TOKEN_FAIL,
  error
})

export const moveToSupportRequest = payload => ({
  type: MOVE_TO_SUPPORT_REQUEST,
  payload
})

export const moveToSupportSuccess = payload => ({
  type: MOVE_TO_SUPPORT_SUCCESS,
  payload
})
export const moveToSupportFail = error => ({
  type: MOVE_TO_SUPPORT_FAIL,
  error
})