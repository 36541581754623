import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';



const tagManagerArgs = {
    gtmId: 'GTM-NK8H2VF'
}
 
TagManager.initialize(tagManagerArgs);
Sentry.init({dsn: "https://b2fe9d07aef74918be2ec8522e0c2f63@sentry.io/1805878"});




// Link React app to the HTML
ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();


