export const status = {
  init: "Information",
  applied: "Room Selection",
  interested: "Application",
  pending: "Processing",
  booking: "Regulations",
  checkout: "Booking",
  end: "Member",
  payment: "Payment"
};

export const genderPreference = [
  "Female Only",
  "Female Prefered",
  "Male Only",
  "Male Prefered"
];

export const gender = [
  {
    value: "M",
    label: "Male"
  },
  {
    value: "F",
    label: "Female"
  }
];
export const titles = [
  {
    title: "Welcome to Cubahaus",
    contents:
      "We designed the application process as a unique 4-steps experience for you. We will guide you along each step.",
    button: "Start My Application"
  },
  {
    title: "Please choose your rooms",
    contents: "Explore our rooms & fun communities. Choose your favorites.",
    button: "Choose My Rooms"
  },
  {
    title: "Please tell use more about yourself",
    contents:
      "Tell us more about you - Apply to become part of the Cubahaus community.",
    button: "Write My Application"
  },
  {
    title: "Please wait for approving your request",
    contents:
      "Great! If you are selected, you will be invited for a discussion with one of our colleagues. We will clarify any details you might want to know, from room details to any questions about Montreal. ",
    button: "Check My Approval Status"
  },
  {
    title: "Community Regulations",
    contents: "Read our community regulations and approve them !",
    button: "Let me read"
  },
  {
    title: "Sign your contract and pay!",
    contents: " ",
    button: "Pay and Sign"
  },
  {
    title: "Congrats ! ",
    contents: "",
    button: "Go to myBooking"
  }
];

export const statusList = Object.keys(status).map(key => status[key]);

//export const finalStatus = statusList.indexOf(status.roomPreviewed);
export const finalStatus = statusList.indexOf(status.interested);
