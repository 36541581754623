import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Typography
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";

import { IconHome, IconTag, IconGroup, IconFullSize } from "./Icon";

const useStyles = {
  container: {
    justifyContent: "center",
    display: "flex",
    height: "490px"
  },
  card: {
    width: "300px",
    height: "400px",
    marginTop: "35px",
    borderRadius: 10,
    boxShadow: "0 0 10px 8.1px rgba(210, 215, 255, 0.21)",
    cursor: "pointer"
  },
  cardHover: {
    width: "333px",
    height: "470px",
    marginTop: "0px",
    borderRadius: 20,
    boxShadow: "0 0 78.1px 5.9x rgba(37, 60, 158, 0.24)",
    background: "linear-gradient(27deg, #5980ff 1%, #0000ff 99%)",
    cursor: "pointer"
  },
  media: {
    height: "277px",
    width: "300px"
  },
  mediaHover: {
    height: "300px",
    clipPath: "circle(95% at 49% 0)"
  },
  icon: {
    width: 24,
    marginRight: 10,
    verticalAlign: "bottom",
    color: "#0000ff",
    fill: "#0000ff"
  },
  iconHover: {
    width: 26,
    marginRight: 10,
    verticalAlign: "bottom",
    color: "#0000ff",
    fill: "#ffffff"
  },
  roomIconHover: {
    display: "none"
  },
  textRoomName: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 0.9,
    textAlign: "left",
    color: "#666f98"
  },
  textRoomNameHover: {
    fontFamily: "Montserrat",
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: 1.3,
    textAlign: "left",
    color: "#ffffff"
  },
  textPrice: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.8,
    textAlign: "left",
    color: "rgba(102, 111, 152, 0.9)"
  },
  textPriceHover: {
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 1,
    textAlign: "left",
    color: "rgba(255, 255, 255, 0.9)"
  },
  textShared: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.8,
    textAlign: "left",
    marginLeft: "41px",
    color: "rgba(255, 255, 255, 0.9)"
  },
  textSharedHover: {
    fontFamily: "Montserrat",
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: 1.3,
    textAlign: "left",
    color: "#ffffff"
  },
  textSize: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.8,
    textAlign: "left",
    marginLeft: "41px",
    color: "rgba(102, 111, 152, 0.9)"
  },
  textSizeHover: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.8,
    textAlign: "left",
    marginLeft: "41px",
    color: "rgba(255, 255, 255, 0.9)"
  },
  addItems: {
    marginTop: "25px"
  },
  checkFav: {
    justifyContent: "flex-end",
    paddingRight: "22px",
    position: "absolute"
  },
  favButton: {
    marginLeft: "210px",
    marginTop: "-20px",
    boxShadow: "0 0 35px 5.5px rgba(37,60, 158, 0.3)",
    background: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)"
  },
  favButtonHover: {
    display: "none"
  },
  addFavHover: {
    border: "solid 1px #f8f8f8",
    marginTop: "-17px",
    width: 220,
    height: 47,
    borderRadius: 23.5,
    marginLeft: 100
  },
  addFav: {
    display: "none"
  },
  addFavText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    letterSpacing: 0.8,
    textAlign: "left",
    marginLeft: "5px",
    color: "#f8f8f8"
  }
};

class RoomCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }

  handleHover = () => {
    // this.setState({
    //   isHovered: !this.state.isHovered
    // });
  };

  render() {
    const {
      classes,
      room,
      idx,
      selected,
      onClickRoom,
      onClick,
      roomSelectRequest,
      width
    } = this.props;
    const { isHovered } = false; //this.state;
    //console.log("Is shared: " , room.is_shared);
    const isShared =
      room.is_shared === true ? "Shared Bedroom" : "Private Bedroom";
    return (
      <div className={classes.container}>
        <Card
          onMouseOver={this.handleHover}
          onMouseOut={this.handleHover}
          className={isHovered ? classes.cardHover : classes.card}
          id="cardHover"
          raised={true}
          style={width < 450 ? { width: "310px" } : null}
        >
          <CardMedia
            image={room.images[0]}
            title={room.room_name}
            className={isHovered ? classes.mediaHover : classes.media}
            id="mediaHover"
            onClick={onClick}
            style={width < 450 ? { width: "310px" } : null}
          />

          <CardContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography
                //     inline={true}
                gutterBottom={false}
                variant="h5"
                component="h2"
                style={width < 450 ? { fontSize: "20px" } : null}
                className={
                  isHovered ? classes.textRoomNameHover : classes.textRoomName
                }
                id="textRoomNameHover"
              >
                <IconHome
                  className={isHovered ? classes.roomIconHover : classes.icon}
                  id="roomIconHover"
                />
                {room.room_name}
              </Typography>
              <Typography
                //     inline={true}
                gutterBottom={false}
                variant="h5"
                component="h2"
                className={
                  isHovered ? classes.textPriceHover : classes.textPrice
                }
                id="textPriceHover"
              >
                <IconTag
                  className={isHovered ? classes.iconHover : classes.icon}
                  id="iconHover"
                />
                ${room.price}
              </Typography>
              <Typography
                //      inline={true}
                gutterBottom={false}
                variant="h5"
                component="h2"
                className={
                  isHovered ? classes.textRoomNameHover : classes.textRoomName
                }
                style={width < 450 ? { fontSize: "20px" } : null}
                id="textRoomNameHover"
              >
                {isShared}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.addItems}
            >
              {/* <Typography
           //     inline = {true}
                gutterBottom={false}
                variant="h5"
                component="h2"
                className={
                  isHovered ? classes.textPriceHover : classes.textPrice
                }
                id="textPriceHover"
              >
                <IconGroup
                  className={isHovered ? classes.iconHover : classes.icon}
                  id="iconHover"
                />
                {room.house.house_capacity}
              </Typography> */}
              {/* <Typography
         //       inline = {true}
                gutterBottom={false}
                variant="h5"
                component="h2"
                className={isHovered ? classes.textSizeHover : classes.textSize}
                id="textSizeHover"
              >
                <IconFullSize
                  className={isHovered ? classes.iconHover : classes.icon}
                  id="iconHover"
                />
                50 m2
              </Typography> */}
            </Grid>
          </CardContent>

          <CardActions disableactionspacing className={classes.checkFav}>
            <IconButton
              className={isHovered ? classes.favButtonHover : classes.favButton}
              id="favButtonHover"
              aria-label="Add to favorites"
              onClick={() => roomSelectRequest(idx)}
            >
              <FavoriteIcon color={selected ? "secondary" : "inherit"} />
            </IconButton>
            <IconButton
              className={isHovered ? classes.addFavHover : classes.addFav}
              id="addFavHover"
              aria-label="Add to favorites"
              onClick={() => roomSelectRequest(idx)}
              style={width < 450 ? { marginLeft: "70px" } : null}
            >
              <FavoriteIcon color={selected ? "secondary" : "inherit"} />

              <Typography
                //      inline={true}
                gutterBottom={false}
                variant="h6"
                component="h2"
                className={classes.addFavText}
              >
                {selected ? "Remove from favorites" : "Add to favorites"}
              </Typography>
            </IconButton>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withStyles(useStyles)(RoomCard);
