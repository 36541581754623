import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { IconLocationPin, IconLeft, IconRight } from "../Icon";
import Slider from "react-slick";
import "./style.scss";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "10px",
    display: "flex !important",
    justifyContent: "center"
    // width: "500px"
  },
  slider: {
    maxWidth: "670px",
    width: "100%",
    height: "100%"
  },
  mobileSlider: {
    width: "389px"
  },
  imgItem: {
    width: "95%",
    height: "223px",
    borderRadius: "20px"
  },
  padImgItem: {
    // width: "95%",
    // height: "180px",
    width: "327px",
    height: "223px",
    borderRadius: "20px"
  },
  mobileImgItem: {
    // width: "100%",
    // height: "200px",
    width: "327px",
    height: "223px",
    borderRadius: "20px"
  }
});

class LandingMobileSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpShow: false,
      height: props.height
    };

    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentWillMount() {
    this.updateScreenHeight();
  }

  updateScreenHeight = () => {
    this.setState({
      height: window.innerHeight
    });
  };

  render() {
    const { classes, width } = this.props;
    const isPad = false;
    const isMobile = true;
    return (
      <Slider
        dots={true}
        infinite={true}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
        nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
        className={classes.mobileSlider}
        rows={1}
        slidesPerRow={1}
        centerPadding="60px"
        arrows={false}
      >
        <div className={classes.root}>
          <img
            alt="img"
            className={
              isPad
                ? classes.padImgItem
                : isMobile
                ? classes.mobileImgItem
                : classes.imgItem
            }
            src={
              "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565319774/Homepage%20-%20ch.ca%20-%20Room%20carousel/1.%202003/4_of_4_-_2019-08-08_-_12-24-32_-_Screen_Shot_2019-08-08_at_12.24.26.jpg"
            }
          />
        </div>
        <div className={classes.root}>
          <img
            alt="img"
            className={
              isPad
                ? classes.padImgItem
                : isMobile
                ? classes.mobileImgItem
                : classes.imgItem
            }
            src={
              "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565319774/Homepage%20-%20ch.ca%20-%20Room%20carousel/1.%202003/2_of_4_-_2019-08-08_-_12-25-15_-_Screen_Shot_2019-08-08_at_12.25.10.jpg"
            }
          />
        </div>
        <div className={classes.root}>
          <img
            alt="img"
            className={
              isPad
                ? classes.padImgItem
                : isMobile
                ? classes.mobileImgItem
                : classes.imgItem
            }
            src={
              "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565319774/Homepage%20-%20ch.ca%20-%20Room%20carousel/1.%202003/3_of_4_-_2019-08-08_-_12-25-09_-_Screen_Shot_2019-08-08_at_12.25.04.jpg"
            }
          />
        </div>

        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565319774/Homepage%20-%20ch.ca%20-%20Room%20carousel/1.%202003/1_of_4_-_2019-08-08_-_12-25-37_-_Screen_Shot_2019-08-08_at_12.25.32.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320165/Homepage%20-%20ch.ca%20-%20Room%20carousel/5.%2034/1.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320164/Homepage%20-%20ch.ca%20-%20Room%20carousel/5.%2034/4.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320097/Homepage%20-%20ch.ca%20-%20Room%20carousel/4.%201806/20.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320165/Homepage%20-%20ch.ca%20-%20Room%20carousel/5.%2034/38.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320055/Homepage%20-%20ch.ca%20-%20Room%20carousel/3.%201900/4_of_4_-_2019-06-16_-_00-44-15_-_DSC_0030.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320054/Homepage%20-%20ch.ca%20-%20Room%20carousel/3.%201900/2_of_19_-_2019-06-11_-_01-49-21_-_DSC_0075.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320054/Homepage%20-%20ch.ca%20-%20Room%20carousel/3.%201900/7_of_19_-_2019-06-11_-_01-51-44_-_DSC_0083.jpg"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div className={classes.root}>
            <img
              alt="img"
              className={isPad ? classes.padImgItem : classes.imgItem}
              src={
                "https://res.cloudinary.com/dfdulgqpf/image/upload/q_auto/v1565320095/Homepage%20-%20ch.ca%20-%20Room%20carousel/4.%201806/4a.jpg"
              }
            />
          </div>
        )}
      </Slider>
    );
  }
}

export default withStyles(styles)(LandingMobileSlider);
