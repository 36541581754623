import React from "react";
import { Grid } from "@material-ui/core";

import CheckIcon from "../../components/CheckIcon";
import IncludeText from "../../components/IncludeText";
import DescriptiveText from "../../components/DescriptiveText";
import { IconChecked } from "../../components/Icon";

const Bedroom = ({ bedroom }) => {
  const {
    bed_linen,
    bed_size,
    // is_shared,
    pillows
    // room_is_furnished
  } = bedroom;
  //console.log('bedroom :', bedroom);
  return (
    <div className="roomdetail__description__amenities">
      <Grid container spacing={24} justify="space-evenly">
        <Grid item xs={10} md={4}>
          <div className="roomdetail__description__amenities--item">
            <IconChecked className="icon checked" />
            <div>
              <p>Rooms is fully furnished</p>
              <span>Bed, Closet, Desk, Lamp and Chair</span>
            </div>
          </div>
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={pillows} />
            <div>
              <p>Pillows</p>
              <IncludeText included={pillows} />
            </div>
          </div>
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={bed_linen} />
            <div>
              <p>Bed linen</p>
              <IncludeText included={bed_linen} />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} md={4}>
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={bed_size} />
            <div>
              <p>Bed Size </p>
              <DescriptiveText included={bed_size} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Bedroom;
