import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, withStyles, List, ListItem } from "@material-ui/core";
import endImg from "../../assets/landing/17.svg";
import Reward from "react-rewards";
import { stateUpdateRequest } from "../../actions/state";
import {
  setStartBookingRequest,
  getStartBookingRequest
} from "../../actions/end";
import { FrameIcon, End1Icon, End2Icon } from "../../components/Icon";

import "./style.scss";

const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 auto",
    display: "flex",
    minWidth: "135px"
  }
});

class End extends React.Component {
  constructor(props) {
    super(props);
  }

  goToMyBooking = () => {
    const { match, stateUpdateRequest } = this.props;
    // stateUpdateRequest({ agreeTerm: true });
    this.props.setStartBookingRequest(true);
    this.props.getStartBookingRequest();
    this.props.history.push(`/myBooking`);
  };

  render() {
    const { classes, application, room } = this.props;
    const appDetail = application.detail;
    const { start_at, end_at } = appDetail != null && appDetail;
    setTimeout(() => {
      if (this.reward) {
        this.reward.rewardMe();
      }
    }, 2000);

    console.log("start_at", room);
    let startDate = start_at && new Date(start_at.seconds * 1000);
    let endDate = end_at && new Date(end_at.seconds * 1000);
    startDate =
      startDate &&
      startDate.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    endDate =
      endDate &&
      endDate.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });

    return (
      //TODO Update user to real user data
      <div className="content">
        <div className="end">
          <div className="end__body">
            <Reward
              children=""
              ref={ref => {
                this.reward = ref;
              }}
              type="confetti"
            ></Reward>
            <p className="end__title">Congratulations!</p>
            <p className="end__text">You've just booked a room </p>
            <div className="end__text1">
              <div>
                {/* {startDate} ~ {endDate} */}
                <End1Icon alt="" className="end__img" />

                {room.detail && <span>{room.detail.data.room_name}</span>}
              </div>
              <div>
                <span>&nbsp;&nbsp; for &nbsp;&nbsp;</span>
                <End2Icon alt="" className="end__img" />
                <span>
                  {application.detail &&
                    moment(application.detail.datesPreference).format(
                      "DD.MM.YYYY"
                    )}
                  {" - "}
                  {application.detail &&
                    moment(application.detail.datesPreference)
                      .add(application.detail.lengthPreference, "months")
                      .format("DD.MM.YYYY")}
                </span>
              </div>
            </div>
            <div className="end__frame">
              <FrameIcon alt="" className="end__frame__img" />
              <p>
                Please <span>check your email</span> to see if you have received
                the contract and the receipt
              </p>
            </div>
            <Button className={classes.button} onClick={this.goToMyBooking}>
              Go to My Booking
            </Button>
          </div>
          {/* <img alt="" src={endImg} className="end__img" /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ application, room }) => ({
  application: application,
  room
});
const mapDispatchToProps = {
  stateUpdateRequest,
  setStartBookingRequest,
  getStartBookingRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(End));
