//import axios from "axios";
import { all, fork, put, takeLatest, select } from "redux-saga/effects";
import { STATE_REQUEST, STATE_UPDATE_REQUEST } from "../constants/state";
import {
  stateSuccess,
  stateFail,
  stateUpdateSuccess,
  stateUpdateFail
} from "../actions/state";
import { statusList } from "../constants/user_status";
import { db, rsf } from "../rsf";

//axios.defaults.baseURL = "https://cubahaus-api.herokuapp.com";
export function* stateRequestHandler() {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  try {
    const res = yield db
      .collection("users")
      .doc(cUser.uid)
      .get();
    let idx = res.data().status;
    yield put(stateSuccess(statusList[parseInt(idx)]));
    yield fork(detectApprovement);
  } catch (err) {
    yield put(stateFail("Status not found"));
  }
}

function* detectApprovement() {
  const state = yield select();
  const cUser = state.auth.loggedInUser;
  yield fork(rsf.firestore.syncDocument, `users/${cUser.uid}`, {
    successActionCreator: stateSuccess,
    failureActionCreator: stateFail,
    transform: transformer
  });
}

const transformer = user => statusList[parseInt(user.data().status)];

// it's called when the user select time slot
function* stateUpdateRequestHandler({ payload }) {
  const state = yield select();
  const { uid } = state.auth.loggedInUser;
  const { userStatus } = state.state;
  let statusIndex = statusList.indexOf(userStatus);
  if (statusIndex < 7)
    statusIndex = payload.agreeTerm == true ? statusIndex + 1 : statusIndex - 1;
  try {
    yield db
      .collection("users")
      .doc(uid)
      .update({
        status: statusIndex
      });
    const statusValue = statusList[statusIndex];
    yield put(stateUpdateSuccess(statusValue));
  } catch (err) {
    // //console.log(err);
    yield put(stateUpdateFail(err));
  }
}

export default function* authSaga() {
  yield takeLatest(STATE_REQUEST, stateRequestHandler);
  yield takeLatest(STATE_UPDATE_REQUEST, stateUpdateRequestHandler);
}
