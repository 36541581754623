import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

import NButton from "../../components/ButtonContainer";
import InputTextField from "../../components/InputTextField";
import ErrorMessage from "../../components/ErrorMessage";
// import Description from "../../components/ContentDescription";
import facebookIcon from "../../assets/SVG/facebook-logo.svg";
import googleIcon from "../../assets/SVG/google-plus-symbol.svg";
import emailIcon from "../../assets/SVG/email.svg";
import signupImg1 from "../../assets/landing/16.svg";
import signupImg2 from "../../assets/landing/17.svg";

// import { REGISTER_SUCCESS, REGISTER_FAIL } from "../../constants/auth";
import { registerRequest } from "../../actions/auth";
import "./signupPanel.scss";

const UserIcon = styled.img`
  width: 27px;
  height: 27;
  filter: invert(1);
`;
export class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password1: "",
      password2: "",
      open: true,
      flag: true,
      firstname: "",
      lastname: "",
      valid: true
    };
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = nextProps;
    const { error } = auth;
    if (auth.error) {
      this.setState({
        errorMsg: error.message,
        valid: true
      });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      flag: false,
      [name]: value,
      usernameError: false,
      emailError: false,
      password1Error: false,
      password2Error: false
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { registerRequest } = this.props;
    const { email, password1, password2 } = this.state;
    // if (username === "" || lastname === "") {
    //   this.setState({ usernameError: true });
    // } else
    if (email === "") {
      this.setState({ emailError: "Input Email" });
    } else if (password1 === "" || password1.length < 8) {
      this.setState({
        password1Error: "Invalid Passowrd. Must be more 8 characters."
      });
    } else if (password2 !== password1) {
      this.setState({ password2Error: "Incorrect password" });
    } else {
      //  var username = firstname + lastname;
      if (window.amplitude) {
        window.amplitude.getInstance().setUserId(email);
        window.amplitude.getInstance().logEvent("SIGNUP");
      }
      if (window.Intercom) {
        window.Intercom("boot", {
          email: email
        });
      }

      this.setState({
        valid: false,
        errorMsg: ""
      });

      var username = email;
      registerRequest({ username, email, password1, password2 });
    }
  };

  signWithSocial = index => () => {
    const { registerRequest } = this.props;
    registerRequest({ index });
  };

  signupBack = () => {
    this.setState({
      flag: true
    });
  };

  signupOpen = () => {
    this.setState({
      flag: false
    });
  };

  render() {
    const {
      // firstname,
      // lastname,
      email,
      password1,
      password2,
      // usernameError,
      emailError,
      password1Error,
      password2Error,
      flag,
      errorMsg,
      valid
    } = this.state;

    return (
      <div className="signupForm">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Signup | Cubahaus</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://cubahaus.ca/register" />
        </Helmet>

        {flag ? (
          <div className="signUp">
            <IconButton className="signUp__close" onClick={this.props.onClose}>
              <CloseIcon />
            </IconButton>
            <div className="signUp__buttons">
              <p className="signUp__buttons__title">Sign up</p>
              <Button
                onClick={this.signWithSocial(1)}
                className="signUp__buttons__button"
                style={{ backgroundColor: "#ff4747" }}
              >
                <UserIcon src={googleIcon} alt="" />
                <p className="signUp__buttons__button__text">Google</p>
              </Button>
              <Button
                onClick={this.signWithSocial(2)}
                className="signUp__buttons__button"
                style={{ backgroundColor: "#252fff" }}
              >
                <UserIcon src={facebookIcon} alt="" />
                <p className="signUp__buttons__button__text">Facebook</p>
              </Button>
              <Button
                onClick={this.signupOpen}
                className="signUp__buttons__button"
                style={{ backgroundColor: "#4c9cff" }}
              >
                <UserIcon src={emailIcon} alt="" />
                <p className="signUp__buttons__button__text">Email</p>
              </Button>
            </div>
            <div className="signUp__form">
              <img
                alt=""
                src={signupImg1}
                className="signUp__form__background"
              />
            </div>
          </div>
        ) : (
          <div className="email">
            <div className="email__content">
              <p className="email__content__title">Sign up with email</p>
              {/* <InputTextField
                name="firstname"
                label="First name:"
                type="text"
                onChange={this.handleInputChange}
                state={firstname}
                margin="normal"
                className="email__content__registerInput"
              />
              <ErrorMessage msg={usernameError} />

              <InputTextField
                name="lastname"
                label="Last name:"
                type="text"
                onChange={this.handleInputChange}
                state={lastname}
                margin="normal"
                className="email__content__registerInput"
              />
              <ErrorMessage msg={usernameError} /> */}

              {/* Email field */}
              <InputTextField
                name="email"
                label="Email:"
                type="email"
                onChange={this.handleInputChange}
                state={email}
                margin="normal"
                className="email__content__registerInput"
              />
              <ErrorMessage msg={emailError} />

              {/* "Enter password" field */}
              <InputTextField
                name="password1"
                label="Password:"
                type="password"
                onChange={this.handleInputChange}
                state={password1}
                margin="normal"
                className="email__content__registerInput"
              />
              <ErrorMessage msg={password1Error} />

              {/* "Confirm password" field */}
              <InputTextField
                name="password2"
                label="Confirm Password:"
                type="password"
                onChange={this.handleInputChange}
                state={password2}
                margin="normal"
                className="email__content__registerInput"
              />
              <ErrorMessage msg={password2Error} />
              <ErrorMessage msg={errorMsg} />
              <div className="email__content__container">
                <IconButton
                  className="email__content__container__LineButton"
                  onClick={this.signupBack}
                >
                  Back
                </IconButton>
                <IconButton
                  style={{ padding: "0px", borderRadius: "25px" }}
                  onClick={this.handleSubmit}
                  //       className={`bottomBtn ${
                  //         valid ? "valid-button" : "invalid-button"
                  //       }`}
                  disabled={valid ? false : true}
                >
                  <NButton type="gradient" size="small">
                    Sign up
                  </NButton>
                </IconButton>
              </div>
            </div>
            <img alt="" src={signupImg2} className="email__background" />
          </div>
        )}
      </div>
      /* <React.Fragment>
        <div className="main">
          <div style={{ marginTop: '60px' }}>
            <h1>
              Create a new
              <br />
              Cubahaus account
            </h1>
            <Description style={{ textAlign: 'center' }}>
              Use 8 or more characters with a mix of letters,
              <br />
              numbers & symbols
            </Description>
          </div>
          <div className="form-content">
            <form>
              <InputTextField
                name="username"
                label="Username"
                type="text"
                onChange={this.handleInputChange}
                state={username}
                margin="normal"
                className="registerInput"
              />
              <ErrorMessage msg={usernameError} />

              
              <InputTextField
                name="email"
                label="Email"
                type="email"
                onChange={this.handleInputChange}
                state={email}
                margin="normal"
                className="registerInput"
              />
              <ErrorMessage msg={emailError} />

              
              <InputTextField
                name="password1"
                label="Enter password"
                type="password"
                onChange={this.handleInputChange}
                state={password1}
                margin="normal"
                className="registerInput"
              />
              <ErrorMessage msg={password1Error} />

              <InputTextField
                name="password2"
                label="Confirm password"
                type="password"
                onChange={this.handleInputChange}
                state={password2}
                margin="normal"
                className="registerInput"
              />
              <ErrorMessage msg={password2Error} />
            </form>
          </div>
        </div>
        <div
          onClick={e => (valid ? this.handleSubmit(e) : null)}
          className={`bottomBtn ${valid ? 'valid-button' : 'invalid-button'}`}
        >
          <div className="gap" />
          <span>Sign up</span>
        </div>
      </React.Fragment> */
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  registerRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
