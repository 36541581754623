import React from "react";
import PropTypes from "prop-types";

import InputNameTextField from "../../components/InputNameTextField";
import InputTextField from "../../components/InputTextField";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel
} from "@material-ui/core";
import TitleText from "../../components/TitleText";
import {
  // IconInfo,
  AgeCandles,
  Globe,
  NameBadge,
  GenderIcons
  // IconClose,
  // IconHeart,
  // IconMenu
} from "../../components/Icon";
import "./style.scss";
const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  }
});

function NameForm({
  // classes,
  firstName,
  lastName,
  age,
  gender,
  country,
  handleInput,
  handleChange
}) {
  return (
    <div className="name">
      <div className="name__title">
        <TitleText>General Information</TitleText>
      </div>
      <form autoComplete="off">
        <Grid
          className="name"
          container
          direction="column"
          justify="space-around"
          alignContent="center"
        >
          <Grid item xs={9} md={6} sm={6} className="name__formgrid">
            <NameBadge className="name__icon" />
            <InputNameTextField
              name="firstName"
              label="First name"
              placeholder="First name"
              type="text"
              onChange={handleInput}
              value={firstName}
              state={firstName}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6} className="name__formgrid">
            <NameBadge className="name__icon" />
            <InputNameTextField
              name="lastName"
              label="Last name"
              placeholder="Last name"
              type="text"
              onChange={handleInput}
              value={lastName}
              state={lastName}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6} className="name__formgrid">
            <AgeCandles className="name__icon" />
            <InputTextField
              name="age"  
              label="Age"
              type="number"
              onChange={handleInput}
              value={age}
              state={age}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6} className="name__formgrid">
            <GenderIcons className="name__icon" />
            {/* <InputTextField
              name="gender"
              label="Gender"
              type="text"
              onChange={handleInput}
              value={gender}
              state={gender}
              margin="normal"
            /> */}
            <div>
              <FormLabel component="legend" className="name__gendertext">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="gender"
                value={gender}
                onChange={handleChange}
                state={gender}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" />}
                  label="Male"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" />}
                  label="Female"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" />}
                  label="Other"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </Grid>
          <Grid item xs={6} className="name__formgrid">
            <Globe className="name__icon" />
            <InputNameTextField
              name="country"
              label="Country of Origin"
              type="text"
              onChange={handleInput}
              value={country}
              state={country}
              margin="normal"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

NameForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  age: PropTypes.number,
  gender: PropTypes.string,
  country: PropTypes.string,
  handleInput: PropTypes.func
};

export default withStyles(styles)(NameForm);
