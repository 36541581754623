import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { Button, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import InputTextField from "../../components/InputTextField";
import cubahausImg from "../../assets/SVG/cubahaus.png";
import SelectRoom from "../../components/SelectRoom";
import rightArrow from "../../assets/SVG/right_arrow.svg";
import "./style.scss";
import {
  IconChecked,
  IconUnchekced,
  IconCalendar,
  IconTag,
  IconCash,
  CubahausIcon
} from "../../components/Icon";
import BookingDetail from "../BookingDetail";

import {
  saveBookingRequest,
  saveAcceptRequest,
  getBookingRequest,
  saveSelectRoomRequest,
  getSelectRoomRequest
} from "../../actions/booking";
import { roomListRequest } from "../../actions/room";
import { stateUpdateRequest } from "../../actions/state";
const styles = theme => ({
  uncheck: {
    width: "24px",
    height: "24px",
    border: "2px solid gray",
    borderRadius: "50%",
    margin: "0px",
    minWidth: "24px"
  },
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 5px",
    minWidth: "135px",
    textTransform: "capitalize"
  },
  accepted: {
    minWidth: "135px",
    width: "135px",
    height: "50px",
    borderRadius: "24.5px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ffffff",
    letterSpacing: "0.7px",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  accept: {
    minWidth: "135px",
    width: "135px",
    height: "50px",
    borderRadius: "24.5px",
    border: "solid 2px #e6813b",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#e6813b",
    letterSpacing: "0.7px",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  selected: {
    width: "120px",
    height: "35px",
    borderRadius: "17.5px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    textTransform: "capitalize"
  },
  select: {
    width: "96px",
    height: "35px",
    borderRadius: "17.5px",
    backgroundColor: "#ffffff",
    border: "2px solid #a5b2d5",
    color: "#a5b2d5",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "capitalize"
  }
});

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      community: false,
      building: false,
      home: false,
      avatar: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",
      numPages: null,
      pageNumber: 1,
      acceptList: [true, false, false],
      roomNumber: -1,
      acceptDialog: false,
      selectedRoom: null
    };
  }

  componentDidMount() {
    this.props.roomListRequest(); //wishRoomList road
    this.props.getBookingRequest(); // roomInfo, roomAInfo road
    this.props.getSelectRoomRequest(); // selectRoomId road
  }

  componentDidUpdate(prevProps) {
    const { acceptList, roomNumber, avatarURL, progress } = this.state;
    const { saveAcceptRequest, selectRoomId, wishList, userInfo } = this.props;
    const acceptedAll = acceptList[0] && acceptList[1] && acceptList[2];

    if (selectRoomId !== null) {
      wishList &&
        wishList.map((roomId, index) => {
          if (selectRoomId === roomId) {
            roomNumber === -1 &&
              this.setState({
                roomNumber: index
              });
          }
        });
    }
    if (userInfo && userInfo.acceptRegulations === 1) {
      !acceptedAll &&
        this.setState({
          acceptList: [true, true, true]
        });
    }
    if (userInfo && userInfo.cardURL !== undefined) {
      progress !== 100 && this.setState({ progress: 100 });
    }
    if (acceptedAll) {
      saveAcceptRequest();
    }
    if (avatarURL !== "") {
      const payload = { avatarURL: avatarURL };
      this.props.saveBookingRequest(payload);
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onClose = () => {
    this.setState({
      acceptDialog: false
    });
  };

  nextPage = () => {
    this.setState({
      acceptDialog: true
    });
  };

  onAccept = () => {
    this.onClose();
    const {
      community,
      building,
      home,
      progress,
      acceptList,
      roomNumber
    } = this.state;
    if (
      acceptList[0] &&
      acceptList[1] &&
      acceptList[2] &&
      roomNumber > -1 &&
      progress === 100
    )
      this.submitForm();
    else return;
  };

  submitForm = () => {
    const {
      saveBookingRequest,
      saveSelectRoomRequest,
      stateUpdateRequest,
      userInfo
    } = this.props;
    const { avatarURL, roomNumber } = this.state;

    if (avatarURL === "") {
      if (userInfo.cardURL !== undefined) {
        stateUpdateRequest({ agreeTerm: true });
        // this.props.getSelectRoomRequest();
      } else {
        return;
      }
    } else {
      const payload = { avatarURL: avatarURL };
      saveBookingRequest(payload);
      stateUpdateRequest({ agreeTerm: true });
    }
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    const userId = this.props.auth.loggedInUser.uid;
    firebase
      .storage()
      .ref(`users/${userId}/`)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url });
      });
  };

  acceptTerm = i => e => {
    e.preventDefault();
    let acceptListData = this.state.acceptList;
    acceptListData[i] = true;
    this.setState({
      acceptList: acceptListData
    });
  };

  selectRoom = i => e => {
    e.preventDefault();
    this.setState({
      roomNumber: i
    });
    this.props.saveSelectRoomRequest(i);
  };

  onSelectRoom = roomData => {
    this.setState({
      selectedRoom: roomData
    });
  };

  unSelectRoom = () => {
    this.setState({
      selectedRoom: null
    });
  };

  render() {
    const {
      classes,
      wishList,
      wishRoomList,
      userAInfo,
      selectRoomId,
      userInfo
    } = this.props;
    let {
      community,
      building,
      home,
      pageNumber,
      numPages,
      progress,
      acceptList,
      roomNumber,
      selectedRoom
    } = this.state;

    let roomdata;
    if (wishRoomList && wishRoomList.length !== 0) {
      roomNumber === -1
        ? (roomdata = wishRoomList[0].data)
        : (roomdata = wishRoomList[roomNumber].data);
    }
    const acceptedAll = acceptList[1] && acceptList[2];
    const userId = this.props.auth.loggedInUser.uid;
    return selectedRoom ? (
      <BookingDetail roomData={selectedRoom} onBack={this.unSelectRoom} />
    ) : (
      <div className="content">
        {wishRoomList && wishRoomList.length !== 0 && userInfo && (
          <div>
            <SelectRoom
              open={this.state.acceptDialog}
              onClose={this.onClose}
              onBack={this.onClose}
              onSelect={this.onAccept}
              roomData={roomdata}
              userAInfo={userAInfo}
            />
            <div className="booking">
              <div className="">
                <form autoComplete="off">
                  {/* // community regulations */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <p className="booking__title">Booking Page</p>
                    <p className="booking__text">
                      Accept All Regulations to proceed
                    </p>
                    {/* <Grid item xs={12} className="booking__item">
                      <div className="booking__item__body">
                        {acceptList[0] ? (
                          <IconChecked />
                        ) : (
                          <p className={classes.uncheck} />
                        )}
                        <div>
                          <p>Accept Community Regulations</p>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur
                            <br />
                            <br />
                          </span>
                          <span>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/cubahaus-6d3b7.appspot.com/o/pdf%2Fdummy.pdf?alt=media&token=c3658332-0e7c-4eac-85fb-00cf711459d9"
                              target="_blank"
                            >
                              View PDF
                            </a>
                          </span>
                        </div>
                      </div>
                      <Button
                        className={
                          acceptList[0] ? classes.accepted : classes.accept
                        }
                        onClick={this.acceptTerm(0)}
                      >
                        {acceptList[0] ? "Accepted" : "Accept"}
                      </Button>
                    </Grid> */}
                  </Grid>
                  {/* // building regulations */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid item xs={12} className="booking__item">
                      <div className="booking__item__body">
                        {acceptList[1] ? (
                          <IconChecked />
                        ) : (
                          <div className={classes.uncheck} />
                        )}
                        <div>
                          <p>Accept Building Regulations</p>
                          <span>
                            Here you will find the building regulations from our
                            landlords.
                            <br />
                            <br />
                          </span>
                          <span>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/cubahausbook.appspot.com/o/regulations%2FCubahausBuildingRegulations.pdf?alt=media&token=15e56947-af02-4413-a7b5-1eaa3930adc8"
                              target="_blank"
                            >
                              View PDF
                            </a>
                          </span>
                        </div>
                      </div>
                      <Button
                        className={
                          acceptList[1] ? classes.accepted : classes.accept
                        }
                        onClick={this.acceptTerm(1)}
                      >
                        {acceptList[1] ? "Accepted" : "Accept"}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* //home regulations */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid item xs={12} className="booking__item">
                      <div className="booking__item__body">
                        {acceptList[2] ? (
                          <IconChecked />
                        ) : (
                          <div className={classes.uncheck} />
                        )}
                        <div>
                          <p>Accept Home Regulations</p>
                          <span>
                            Please carefully our cleaning rules!
                            <br />
                            <br />
                          </span>
                          <span>
                            <a
                              href="https://firebasestorage.googleapis.com/v0/b/cubahausbook.appspot.com/o/regulations%2FCubahausApartmentRegulations.pdf?alt=media&token=e95adef8-9727-4cec-a7e2-7aa8e9e5e72a"
                              target="_blank"
                            >
                              View PDF
                            </a>
                          </span>
                        </div>
                      </div>
                      <Button
                        className={
                          acceptList[2] ? classes.accepted : classes.accept
                        }
                        onClick={this.acceptTerm(2)}
                      >
                        {acceptList[2] ? "Accepted" : "Accept"}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* //upload Id */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid item xs={12} className="booking__item">
                      <div className="booking__item__body">
                        {progress === 100 ? (
                          <IconChecked />
                        ) : (
                          <div className={classes.uncheck} />
                        )}
                        <div style={{ maxWidth: "600px" }}>
                          <p>Upload Passport or ID Image</p>
                          <span>
                            Please sent us your passport, driving license, or an
                            official government identity documents.
                            <br />
                            <br />
                          </span>
                          <Grid>
                            {/* <label>Avatar:</label> */}
                            {this.state.isUploading && (
                              <p>Progress: {this.state.progress}</p>
                            )}
                            {/* {this.state.avatarURL && (
                              <img src={this.state.avatarURL} />
                            )} */}
                            <label className="fileuploader">
                              <FileUploader
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={firebase
                                  .storage()
                                  .ref(`users/${userId}/`)}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                              />
                              <span>Upload File Chosen</span>
                            </label>
                          </Grid>
                        </div>
                      </div>
                      <div
                        className={
                          progress === 100 ? classes.accepted : classes.accept
                        }
                        //   onClick={this.handleUploadStart}
                        id="uploadedButton"
                      >
                        {progress === 100 ? "Uploaded" : "Upload"}
                      </div>
                    </Grid>
                  </Grid>
                </form>
                <div
                  style={
                    acceptedAll && progress === 100
                      ? {}
                      : { opacity: "0.5", pointerEvents: "none" }
                  }
                >
                  <p className="checkoutpreview__title">Checkout Preview</p>
                  <p className="checkoutpreview__text">
                    Please <b> select 1 room </b> from favorites
                  </p>
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    {wishRoomList &&
                      wishRoomList.map((room, index) => (
                        <Grid
                          item
                          xs={12}
                          className="checkoutpreview__item"
                          key={index}
                        >
                          <img
                            className="checkoutpreview__item__img"
                            alt="alt"
                            src={room.data.images[0]}
                          />
                          <div className="checkoutpreview__item__data">
                            <div>
                              <div className="checkoutpreview__item__data__name">
                                <span>{room.data.room_name}</span>
                                <span>
                                  <div className="checkoutpreview__item__price">
                                    <IconTag
                                      alt=""
                                      style={{ width: "27px", fill: "#5980ff" }}
                                    />
                                    <p>${room.data.price}</p>
                                  </div>
                                </span>
                              </div>
                              <p className="checkoutpreview__item__data__community">
                                {room.data.community} community
                              </p>
                              <p className="checkoutpreview__item__data__address">
                                Address: {room.data.house.house_address}
                              </p>
                   
                              <div className="checkoutpreview__item__data__date">
                                <IconCalendar
                                  alt=""
                                  style={{ width: "27px", fill: "#5980ff" }}
                                />
                                {userAInfo && (
                                  <p>
                                    {moment(userAInfo.datesPreference).format(
                                      "YYYY.DD.MM"
                                    )}{" "}
                                    -{" "}
                                    {moment(userAInfo.datesPreference)
                                      .add(userAInfo.lengthPreference, "months")
                                      .subtract(1, "day")
                                      .format("YYYY.DD.MM")}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="checkoutpreview__item__pricediv">
                              <div className="checkoutpreview__item__price">
                                <IconTag
                                  alt=""
                                  style={{ width: "27px", fill: "#5980ff" }}
                                />
                                <p>${room.data.price}</p>
                              </div>
                            </div>
                            <div className="checkoutpreview__item__buttons">
                              {room.data.booked && (
                                <p className="checkoutpreview__item__buttons__text1">
                                  Room has been booked
                                </p>
                              )}
                              <Button
                                disabled={room.data.booked}
                                className={
                                  roomNumber === index
                                    ? classes.selected
                                    : classes.select
                                }
                                onClick={this.selectRoom(index)}
                              >
                                {roomNumber === index ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <IconChecked
                                      style={{
                                        width: "18px",
                                        fill: "#ffffff",
                                        marginRight: "7px"
                                      }}
                                    />
                                    Selected
                                  </div>
                                ) : (
                                  "Select"
                                )}
                              </Button>
                              {room.data.booked && (
                                <p className="checkoutpreview__item__buttons__text">
                                  Room has been booked
                                </p>
                              )}
                              <Button
                                //  to={{
                                //    pathname: `/application/booking/${room.data.room_name}`,
                                //    state: {
                                //      roomData: room.data,
                                //      index: index,
                                //      userAInfo: userAInfo
                                //    }
                                //  }}
                                onClick={() => this.onSelectRoom(room)}
                              >
                                <div className="checkoutpreview__item__buttons__moreInfo">
                                  More info about this Room
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <div className="form__button__container">
                  <Button className={classes.button} onClick={this.nextPage}>
                    <span className="form__nextButton__text form__nextButton__text--right">
                      Next
                    </span>
                    <img
                      src={rightArrow}
                      className="form__arrow form__arrow--right"
                      alt="rightArrow"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, room, booking }) => ({
  auth,
  wishRoomList: room.wishRoomList,
  wishList: room.wishlist,
  bookedList: room.bookedList,
  userInfo: booking.userInfo,
  userAInfo: booking.userAInfo,
  selectRoomId: booking.selectRoomId
});

const mapDispatchToProps = {
  saveBookingRequest,
  saveAcceptRequest,
  getBookingRequest,
  saveSelectRoomRequest,
  getSelectRoomRequest,
  stateUpdateRequest,
  roomListRequest
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Booking));
