import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { routerMiddleware, routerReducer } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import reducers from "./reducers";
import sagas from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage
};

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    router: routerReducer
  })
);
const store = createStore(persistedReducer, initialState, composedEnhancers);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
export default store;
