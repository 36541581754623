import {
  SET_START_BOOKING_REQUEST,
  SET_START_BOOKING_SUCCESS,
  SET_START_BOOKING_FAIL,
  GET_START_BOOKING_REQUEST,
  GET_START_BOOKING_FAIL,
  GET_START_BOOKING_SUCCESS
} from "../constants/end";

export function getStartBookingRequest(payload) {
  return {
    type: GET_START_BOOKING_REQUEST
  };
}
export function getStartBookingSuccess(payload) {
  return {
    type: GET_START_BOOKING_SUCCESS,
    payload
  };
}

export function getStartBookingFail(payload) {
  return {
    type: GET_START_BOOKING_FAIL,
    payload
  };
}
export function setStartBookingRequest(payload) {
  return {
    type: SET_START_BOOKING_REQUEST,
    payload
  };
}

export function setStartBookingSuccess(payload) {
  return {
    type: SET_START_BOOKING_SUCCESS,
    payload
  };
}

export function setStartBookingFail(payload) {
  return {
    type: SET_START_BOOKING_FAIL,
    payload
  };
}
