import {
  GET_BOOKING_FAIL,
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  SAVE_BOOKING_REQUEST,
  SAVE_BOOKING_FAIL,
  SAVE_BOOKING_SUCCESS,
  SAVE_ACCEPT_REQUEST,
  SAVE_ACCEPT_SUCCESS,
  SAVE_ACCEPT_FAIL,
  GET_SELECTROOM_REQUEST,
  GET_SELECTROOM_SUCCESS,
  GET_SELECTROOM_FAIL,
  SAVE_SELECTROOM_REQUEST,
  SAVE_SELECTROOM_SUCCESS,
  SAVE_SELECTROOM_FAIL
} from "../constants/booking";

const initialState = {
  loading: false,
  term: false,
  userInfo: null,
  userAInfo: null,
  selectRoomId: null,
  error: null
};

export default function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SELECTROOM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_SELECTROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectRoomId: action.payload.selectRoom
      };
    case GET_SELECTROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case SAVE_SELECTROOM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SAVE_SELECTROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectRoomId: action.payload.selectRoom
      };
    case SAVE_SELECTROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.userInfo,
        userAInfo: action.payload.userAInfo
      };
    case GET_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case SAVE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SAVE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        term: action.payload
      };
    case SAVE_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case SAVE_ACCEPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SAVE_ACCEPT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SAVE_ACCEPT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
