import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Mapbox from "mapbox";
import ReactMapboxGl, { Marker, Layer, Feature } from "react-mapbox-gl";
import { COMMUNITY_WISHLIST_TEXT } from "../../constants/communityText";

import RoomCard from "../../components/RoomCard";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemAvatar,
  Button,
  withStyles,
  IconButton
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CommunitySlider from "../../components/CommunitySlider";
import { stateRequest } from "../../actions/state";
import TitleText from "../../components/TitleText";
import BackButton from "../../components/BackButton";
import Slider from "react-slick";
import RoomDetail from "../RoomDetail";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/fourSeconds.json";

import {
  roomListRequest,
  roomSelectRequest,
  wishListComplete,
  triggerFavoriteRequest
} from "../../actions/room";
import {
  communityListRequest,
  communityDetailRequest
} from "../../actions/community";

import accessToken from "../../config/map";

import leftArrow from "../../assets/SVG/left_arrow_orange.svg";
import rightArrow from "../../assets/SVG/right_arrow_orange.svg";
import {
  IconWalk,
  IconTransport,
  IconClose,
  IconHeart,
  IconInfo,
  IconLeft,
  IconRight
} from "../../components/Icon";

import "./styles.scss";

const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 20px",
    minWidth: "150px"
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 15.5,
    fontWeight: 500,
    lineHeight: 2.94,
    letterSpacing: 0.4,
    textAlign: "left",
    color: "#596390",
    textTransform: "none"
  },
  icon: {
    width: 17,
    marginRight: 13
  },
  unSelectButton: {
    backgroundColor: "#dcdcdc",
    borderRadius: "10px",
    textAlign: "left",
    fontSize: "18px",
    textTransform: "initial",
    padding: "30px 15px 25px 20px",
    boxShadow: "rgba(247, 247, 247, 0.39) 0px 0px 40px 3px",
    color: "#ffffff",
    lineHeight: "1.21",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  },
  selectButton: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "10px",
    fontSize: "18px",
    textAlign: "left",
    textTransform: "initial",
    padding: "30px 40px 25px 20px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    lineHeight: "1.21",
    color: "#ffffff",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  },
  likeIcon: {
    width: "24px",
    height: "21px",
    fill: "#ffffff",
    marginRight: "5px"
  },
  icon2: {
    width: "36px",
    height: "36px",
    fill: "#f1b143",
    position: "absolute",
    top: 0,
    right: "15%",
    padding: "5px",
    border: "solid 1px #e4e7f6",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  icon1: {
    width: "36px",
    height: "36px",
    fill: "#f1b143",
    position: "absolute",
    top: 0,
    left: "15%",
    padding: "0px",
    border: "solid 1px #e4e7f6",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  roomSlider: {
    width: "1040px"
  },
  mobileRoomSlider: {
    width: "400px"
  },
  mobileRoomSlider1: {
    width: "310px"
  }
});

const mapbox = new Mapbox(accessToken);
const Map = ReactMapboxGl({ scrollZoom: false, accessToken: accessToken });

export class Community extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      width: props.width,
      currentCommunity: 0,
      walkingDuration: 0,
      walkingDistance: 0,
      walkingDirections: [],
      trafficDuration: 0,
      trafficDistance: 0,
      trafficDirections: [],
      selectedRoom: null
    };
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentDidMount() {
    this._isMounted = true;
    const { communityDetailRequest, roomListRequest } = this.props;
    if (this.props.list.length !== 0 && this.state.currentCommunity === 0) {
      communityDetailRequest(this.props.list[0].name);
      roomListRequest();
      this.setState({
        currentCommunity: 0
      });
    }
  }

  componentWillMount() {
    this.updateScreenWidth();

    // const { communityListRequest } = this.props;
    // communityListRequest();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  updateCurrentCommuinity = community => {
    this.setState({
      currentCommunity: community % 3
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { detail, list } = this.props;
    const { currentCommunity } = this.state;
    if (detail) {
      if (
        detail !== prevProps.detail ||
        currentCommunity !== prevState.currentCommunity
      ) {
        const { _lat, _long } = detail.uni.geolocation;
        const latitude = detail.communityDetail.location._lat;
        const longitude = detail.communityDetail.location._long;

        mapbox
          .getDirections(
            [
              { longitude: longitude, latitude: latitude },
              { longitude: _long, latitude: _lat }
            ],
            {
              profile: "walking",
              instructions: "html",
              alternatives: false,
              geometry: "geojson"
            }
          )
          .then(results => {
            const {
              entity: { routes }
            } = results;
            this.setState({
              walkingDuration: routes[0].duration,
              walkingDistance: routes[0].distance,
              walkingDirections: routes[0].geometry.coordinates
            });
          });
        mapbox
          .getDirections(
            [
              { longitude: _long, latitude: _lat },
              { longitude: longitude, latitude: latitude }
            ],
            {
              profile: "driving-traffic",
              instructions: "html",
              alternatives: false,
              geometry: "geojson"
            }
          )
          .then(results => {
            const {
              entity: { routes }
            } = results;
            this.setState({
              trafficDuration: routes[0].duration,
              trafficDistance: routes[0].distance,
              trafficDirections: routes[0].geometry.coordinates
            });
          });
      }
    }
  }

  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  triggerFavorite = i => () => {
    //console.log(i);
    const { triggerFavoriteRequest } = this.props;
    triggerFavoriteRequest(i);
  };

  goToQuestion = () => {
    if (this.props.wishlist >= 1) this.props.wishListComplete();
  };

  onSelectRoom = roomData => {
    this.setState({
      selectedRoom: roomData
    });
  };

  unSelectRoom = () => {
    this.setState({
      selectedRoom: null
    });
  };

  emptyWishList = (wishRoomList, width, classes) => {
    const list = [];
    for (let i = 0; i < 5 - wishRoomList.length; i++) {
      list.push(
        <div
          key={i}
          className="roomwishlist__room"
          style={
            width < 750
              ? {
                  width: width / 4,
                  padding: "0 5px",
                  marginBottom: "20px"
                }
              : width < 1050
              ? { width: width / 5 }
              : null
          }
        >
          <div
            className="roomwishlist__room__img"
            //   src={item.data.images[0]}
            alt=""
            style={
              width < 750
                ? { height: width / 4 }
                : width < 1050
                ? { height: width / 5 - 36 }
                : null
            }
          >
            <span
              style={
                width < 450 ? { fontSize: "3vw", marginTop: "10px" } : null
              }
            >
              Add <br />
              one more
              <br />
              room
            </span>
          </div>
          {/* <IconButton
            className={classes.icon1}
            //     component={Link}
            //     to={`/application/rooms/${i}`}
            //  onClick={() => this.onSelectRoom(wishRoomList[i])}
            color="inherit"
            style={width < 500 ? { left: "2%" } : null}
          >
            <IconInfo className="roomwishlist__room__icon1" />
          </IconButton>
          <IconButton
            className={classes.icon2}
            //     onClick={this.triggerFavorite(i)}
            style={width < 500 ? { right: "2%" } : null}
          >
            <IconClose className="roomwishlist__room__icon2" />
          </IconButton> */}
          <p className="roomwishlist__room__title">
            Room
            {width < 520 && <br />}
            {i + 1}
          </p>
          <dir className="roomwishlist__room__fav">
            <FavoriteIcon color={"inherit"} />
          </dir>
        </div>
      );
    }
    return list;
  };

  render() {
    const { classes, list, history } = this.props;
    const { width, currentCommunity, selectedRoom } = this.state;

    const { loading, detail, roomSelectRequest, room, wishlist } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    if (loading || detail === null) {
      return null;
    }

    for (var i = 0; i < list.length; i++) {
      if (list[i].name === list[currentCommunity].name) {
        detail.communityDetail = list[i];
      }
    }
    if (room.list && list.length !== 0) {
      room.list.rooms = [];
      for (i = 0; i < room.list.roomList.length; i++) {
        if (
          room.list.roomList[i].data.community === list[currentCommunity].name
        ) {
          room.list.rooms.push(room.list.roomList[i]);
        }
      }
    }

    const { communityDetail } = detail;
    const { location } = communityDetail;
    let { _lat, _long } = [0, 0];
    if (detail.uni) {
      _lat = detail.uni.geolocation._lat;
      _long = detail.uni.geolocation._long;
    }
    const { walkingDuration, walkingDirections, trafficDuration } = this.state;
    const wishRoomList = room.wishRoomList;
    const active = wishlist < 1 ? classes.unSelectButton : classes.selectButton;

    return (
      <div>
        {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
        <div className="content">
          <div className="wishlist">
            {/* <BackButton onClick={() => history.goBack()} /> */}
            <div
              className="roomwishlist"
              style={
                width > 1500
                  ? { position: "relative" }
                  : width < 750
                  ? // ? room.wishRoomList && wishRoomList.length < 4
                    width < 400
                    ? {
                        flexWrap: "wrap",
                        height: "370px",
                        justifyContent: "center",
                        padding: "0px"
                      }
                    : {
                        flexWrap: "wrap",
                        height: "395px",
                        justifyContent: "center",
                        padding: "0px"
                      }
                  : null

                /* : width < 500
                  ? { height: "430px" }
                  : width < 400
                  ? { height: "380px" } 
                  : null */
              }
            >
              <div
                className="position-relative roomwishlist__nextStep"
                style={
                  width < 1500
                    ? {
                        right: "0px",
                        left: "0px",
                        bottom: "-170px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "175px"
                      }
                    : null
                }
              >
                <Button
                  //  type="gradient"
                  onClick={this.goToQuestion}
                  className={active}
                  style={{ backgroundColor: "#dcdcdc" }}
                >
                  <IconHeart alt="" className={classes.likeIcon} />
                  Go to the
                  <br />
                  next step
                  <span className="favoriteButton">{wishlist}</span>
                </Button>
              </div>
              {wishRoomList &&
                wishRoomList.map((item, i) => (
                  <div
                    key={i}
                    className="roomwishlist__room"
                    style={
                      width < 750
                        ? {
                            width: width / 4,
                            padding: "0 5px",
                            marginBottom: "20px"
                          }
                        : width < 1050
                        ? { width: width / 5 }
                        : null
                    }
                  >
                    <img
                      className="roomwishlist__room__img"
                      src={item.data.images[0]}
                      alt=""
                      style={
                        width < 750
                          ? { height: width / 4 }
                          : width < 1050
                          ? { height: width / 5 - 36 }
                          : null
                      }
                    />
                    <IconButton
                      className={classes.icon1}
                      //     component={Link}
                      //     to={`/application/rooms/${i}`}
                      onClick={() => this.onSelectRoom(wishRoomList[i])}
                      color="inherit"
                      style={width < 500 ? { left: "2%" } : null}
                    >
                      <IconInfo className="roomwishlist__room__icon1" />
                    </IconButton>
                    <IconButton
                      className={classes.icon2}
                      onClick={this.triggerFavorite(i)}
                      style={width < 500 ? { right: "2%" } : null}
                    >
                      <IconClose className="roomwishlist__room__icon2" />
                    </IconButton>
                    <p className="roomwishlist__room__title">
                      {item.data.room_name}
                    </p>
                    <dir className="roomwishlist__room__fav">
                      <FavoriteIcon color={"inherit"} />
                    </dir>
                  </div>
                ))}
              {room.wishRoomList &&
                this.emptyWishList(wishRoomList, width, classes)}
            </div>

            <p className="wishlist__text text-center">
              {COMMUNITY_WISHLIST_TEXT}
            </p>
          </div>

          {selectedRoom ? (
            <RoomDetail
              width={width}
              roomData={selectedRoom}
              onBack={this.unSelectRoom}
            />
          ) : (
            <div>
              <div className="community">
                <div className="community__title">
                  <TitleText>Locations</TitleText>
                </div>
                <div className="community__list">
                  <CommunitySlider
                    showCount={width > 1200 ? 3 : width > 900 ? 1 : 1}
                    communityArray={list}
                    updateCurrentCommuinity={this.updateCurrentCommuinity}
                    currentCommunity={currentCommunity}
                    width={width}
                  />
                </div>
                {/* <Grid container className="community__list">
              {list.map((community, i) => (
                <Grid key={i} item xs={6} lg={6}>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/${community.name}`}
                  >
                    <div className="community__item__imageDiv">
                      <img
                        src={community.thumbnail}
                        className="community__item__image"
                      />
                    </div>
                    <div className="community__item__container">
                      <span className="community__item__name">
                        {community.name}
                      </span>
                    </div>
                  </ListItem>
                </Grid>
              ))}
            </Grid> */}
              </div>

              <Grid
                container
                spacing={24}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div
                    className="community__detail__title"
                    style={width < 500 ? { marginTop: "0px" } : null}
                  >
                    <TitleText>
                      {room.list && room.list.rooms.length} Rooms For You in
                      this Community
                    </TitleText>
                  </div>
                  <div className="room">
                    <Slider
                      className={classes.roomSlider}
                      dots={true}
                      infinite={false}
                      autoplay={true}
                      centerMode={true}
                      centerPadding={"0px"}
                      prevArrow={
                        <IconLeft alt="" style={{ fill: "#ea923e" }} />
                      }
                      nextArrow={
                        <IconRight alt="" style={{ fill: "#ea923e" }} />
                      }
                      slidesToShow={
                        room.list && room.list.rooms.length < 2 ? 1 : 3
                      }
                      slidesToScroll={1}
                      onSwipe={e => this.setState({ swiping: false })}
                    >
                      {room.list &&
                        room.list.rooms.map((room, i) => (
                          <div key={i} className="roomCard">
                            <RoomCard
                              room={room.data}
                              idx={i}
                              //  onClickRoom={() =>
                              //    history.push({
                              //     pathname: `/application/rooms/${i}`,
                              //      state: {
                              //        room: room.data
                              //      }
                              //    })
                              //  }
                              onClick={() => this.onSelectRoom(room)}
                              selected={room.selected}
                              roomSelectRequest={roomSelectRequest}
                            />
                          </div>
                        ))}
                      {room.list &&
                        room.list.rooms.map((room, i) => (
                          <div key={i} className="roomCard">
                            <RoomCard
                              room={room.data}
                              idx={i}
                              //  onClickRoom={() =>
                              //    history.push({
                              //      pathname: `/application/rooms/${i}`,
                              //      state: {
                              //       room: room.data
                              //      }
                              //    })
                              //  }
                              onClick={() => this.onSelectRoom(room)}
                              selected={room.selected}
                              roomSelectRequest={roomSelectRequest}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                  <div className="room__mobile">
                    <Slider
                      className={
                        width > 449
                          ? classes.mobileRoomSlider
                          : classes.mobileRoomSlider1
                      }
                      arrows={width < 450 ? false : true}
                      dots={true}
                      infinite={true}
                      prevArrow={
                        <IconLeft alt="" style={{ fill: "#ea923e" }} />
                      }
                      nextArrow={
                        <IconRight alt="" style={{ fill: "#ea923e" }} />
                      }
                      autoplay={true}
                      slidesToShow={1}
                      slidesToScroll={1}
                      onSwipe={e => this.setState({ swiping: false })}
                    >
                      {room.list &&
                        room.list.rooms.map((room, i) => (
                          <div key={i} className="roomCard">
                            <RoomCard
                              room={room.data}
                              idx={i}
                              //  onClickRoom={() => history.push(`/application/rooms/${i}`) }
                              onClick={() => this.onSelectRoom(room)}
                              selected={room.selected}
                              roomSelectRequest={roomSelectRequest}
                              width={width}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        {!selectedRoom && (
          <Grid item xs={12}>
            <div className="community__detail__title">
              <span
                className="mapTitleText"
                style={
                  width < 1499
                    ? { marginTop: "110px", marginBottom: "75px" }
                    : null
                }
              >
                Map{" "}
              </span>
            </div>
            <div className="map">
              <div className="map__distance">
                <div className="map__distance__container">
                  <div>
                    <IconWalk className="icon" />
                    <span>
                      <span className="bold">
                        {Math.floor(walkingDuration / 60)} min
                      </span>{" "}
                      &nbsp;
                    </span>
                  </div>
                  <div>
                    <IconTransport className="icon" />
                    <span>
                      <span className="bold">
                        {Math.floor(trafficDuration / 60)} min
                      </span>{" "}
                      &nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <Map
                style={"mapbox://styles/mik6e/ck0ihifpe04sv1cob2x67te5z"}
                zoom={[13]}
                center={[location._long, location._lat]}
                pitch={[0]} // pitch in degrees
                // bearing={[-60]} // bearing in degrees
                containerStyle={{
                  width: "100%",
                  height: "581px",
                  clipPath: "ellipse(66% 55% at 50% 60%)"
                }}
              >
                <Marker
                  coordinates={[location._long, location._lat]}
                  className={"map--marker"}
                />
                <Marker coordinates={[_long, _lat]} className={"map--marker"} />
                <Layer
                  type="line"
                  layout={{ "line-cap": "round", "line-join": "round" }}
                  paint={{ "line-color": "#4790E5", "line-width": 5 }}
                >
                  <Feature coordinates={walkingDirections} />
                </Layer>
              </Map>
            </div>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ community, room, application, list }) => ({
  ...community,
  detail: community.detail,
  room,
  wishlist: room.wishlist ? room.wishlist.length : 0
});

const mapDispatchToProps = {
  communityListRequest,
  stateRequest,
  communityDetailRequest,
  roomListRequest,
  roomSelectRequest,
  wishListComplete,
  triggerFavoriteRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Community));
