import { status } from "../constants/user_status";

import Application from "../containers/Application";
import Community from "../containers/Community";
import CommunityDetail from "../containers/CommunityDetail";
import RoomDetail from "../containers/RoomDetail";
import Questions from "../containers/Questions";
import Pending from "../containers/Pending";
import Booking from "../containers/Booking";
// import WishList from "../containers/WishList";
import Checkout from "../containers/Checkout";
import End from "../containers/End";
import CheckoutPreview from "../containers/CheckoutPreview";
import ArrivalInfo from "../containers/ArrivalInfo";
// import Contract from "../containers/Contract";
import BookingDetail from "../containers/BookingDetail";
import Payment from "../containers/Payment";

const routes = [
  {
    key: status.init,
    path: "form",
    component: Application
  },
  {
    key: status.applied,
    path: "community",
    component: Community
  },
  {
    key: status.interested,
    path: "questions",
    component: Questions
    // component: Final
  },
  {
    key: status.pending,
    path: "Pending",
    component: Pending
  },
  {
    key: status.booking,
    path: "Booking",
    component: Booking
  },
  {
    key: status.checkout,
    path: "checkout",
    component: Checkout
  },
  // {
  //   path: "wishlist/checkout/:id",
  //   component: Checkout
  // },
  {
    key: status.end,
    path: "end",
    component: End
  },
  {
    key: status.payment,
    path: "payment",
    component: Payment
  },
  // {
  //   key: status.roomPreviewed,
  //   path: "lease",
  //   component: Contract
  // },
  {
    key: status.info,
    path: "info",
    component: ArrivalInfo
  },
  {
    path: "wishlist/checkout/preview",
    component: CheckoutPreview
  },
  {
    path: "community/:id",
    component: CommunityDetail
  },
  {
    path: "rooms/:id",
    component: RoomDetail
  },
  {
    path: "booking/:id",
    component: BookingDetail
  },
  {
    path: "checkout/:id",
    component: BookingDetail
  }
];

export default routes;
