import { put, takeLatest, select } from "redux-saga/effects";
import {
  SET_START_BOOKING_REQUEST,
  GET_START_BOOKING_REQUEST
} from "../constants/end";
import {
  getStartBookingSuccess,
  getStartBookingFail,
  setStartBookingSuccess,
  setStartBookingFail
} from "../actions/end";
//import { stateRequest } from "../actions/state";

import { db } from "../rsf";

function* getStartBookingRequestHandler() {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    const user = yield db
      .collection("users")
      .doc(uid)
      .get();
    const userInfo = user.data();
    yield put(getStartBookingSuccess(userInfo.startmybooking));
  } catch (err) {
    yield put(getStartBookingFail(err.response));
  }
}

function* setStartBookingRequestHandler({ payload }) {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    yield db
      .collection("users")
      .doc(uid)
      .update({
        startmybooking: true
      });
    yield put(setStartBookingSuccess(true));
    //  yield put(stateRequest());
  } catch (err) {
    yield put(setStartBookingFail(err.response));
  }
}

export default function* authSaga() {
  yield takeLatest(GET_START_BOOKING_REQUEST, getStartBookingRequestHandler);
  yield takeLatest(SET_START_BOOKING_REQUEST, setStartBookingRequestHandler);
}
