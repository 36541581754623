import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import CheckIcon from "../../components/CheckIcon";
import IncludeText from "../../components/IncludeText";
import { IconChecked } from "../../components/Icon";
import { IconLeft, IconRight } from "../../components/Icon";
import DescriptiveText from "../../components/DescriptiveText";

import Slider from "react-slick";

const styles = theme => ({
  root: {
    width: "310px",
    margin: "auto"
  }
});

//const Bathroom = ({ bathroom, width }) => {
class Bathroom extends React.Component {
  render() {
    const { width, bathroom, classes } = this.props;
    const { images } = bathroom;

    return (
      <div className="roomdetail__description__amenities">
        <Grid container spacing={24} justify="space-evenly">
          <Grid item xs={10} md={4}>
            <div className="roomdetail__description__amenities--item">
              <div>
                <p>Bathroom is Shared with</p>
                <DescriptiveText
                  included={bathroom.bahtroom_will_be_shared_with}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="roomdetail__description__amenities--item">
              <div>
                <p>Bathroom Type:</p>
                <DescriptiveText included={bathroom.bathroom_type} />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="roomdetail__description__description">
          <Slider
            dots={true}
            slidesToShow={width < 800 ? 1 : images.length >= 2 ? 3 : 1}
            slidesToScroll={1}
            prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
            nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
            className={
              width < 800 ? null : images.length < 2 ? classes.root : null
            }
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={image.image_title}
                  style={
                    width < 800
                      ? { width: "220px", margin: "auto" }
                      : images.length < 3
                      ? { margin: "0 auto", marginTop: "10px" }
                      : null
                  }
                />
              </div>
            ))}
            {images.length <= 3 &&
              images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={image.image_title}
                    style={
                      width < 800
                        ? { width: "220px", margin: "auto" }
                        : images.length < 3
                        ? { margin: "0 auto", marginTop: "10px" }
                        : null
                    }
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Bathroom);
