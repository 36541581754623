import React from "react";
import { connect } from "react-redux";
import { Button, withStyles, List, ListItem, Grid } from "@material-ui/core";
import TitleText from "../../components/TitleText";

import rightArrow from "../../assets/SVG/right_arrow.svg";
import pending from "../../assets/2001.png";
import laptop from "../../assets/laptop.png";
import googleads from "../../assets/SVG/Google_Ads_logo.svg";
import facebook from "../../assets/SVG/facebook3.svg";
import roomgo from "../../assets/SVG/roomgo-logo.svg";
import google from "../../assets/SVG/google-logo.png";
import erasmusu from "../../assets/SVG/erasmusu_logo.png";
import facebookAds from "../../assets/SVG/facebook-ads.png";
import craigslistlogo from "../../assets/SVG/craigslistlogo.svg";
import kijiji from "../../assets/SVG/kijiji.svg";
import airbnb from "../../assets/SVG/airbnb.png";

import "./style.scss";

const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 5px",
    minWidth: "135px"
  },
  imgGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

class Pending extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: ""
    };
  }

  componentDidMount() {
    // this.props.getMessageRequest()
    // this.setState({message: this.props.message})
  }

  // handleFormInput = e => {
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value
  //   });
  // };

  // nextPage = () => {
  //     this.submitForm()
  // };

  // submitForm = () => {
  //   const { message } = this.state;
  //   this.props.addMessageRequest( message );
  // };

  render() {
    const { classes } = this.props;
    return (
      <div className="pending content">
        <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} className="">
            <div className="pending__title">
              <p className="pending__title__text">
                <b> You have successfully completed your application.</b>
                <br />

                We’ll review your application and availability. We’ll keep in touch and arrange a Skype interview with you over the next few days.<br/>
                 Remember to check your email for news and updates from us.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.imgGrid}>
            <img className="pending__title__img" src={pending} alt="" />
          </Grid>
        </Grid>
        {/* <p className="pending__text1">
          In the meantime you can answer a few extra questions for us!
        </p>
        <p className="pending__text2">Where did you hear about us?</p> */}
        {/* <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={5} className={classes.imgGrid}>
            <img className="pending__aboutus__img" src={laptop} alt="" />
          </Grid>
          <Grid item xs={12} md={7} className={classes.imgGrid}>
            <Grid container>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={googleads} alt="" style={{ width: "64px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={google} alt="" style={{ width: "95px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={facebook} alt="" style={{ width: "64px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={roomgo} alt="" style={{ width: "130px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={erasmusu} alt="" style={{ width: "115px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={facebookAds} alt="" style={{ width: "125px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={craigslistlogo} alt="" style={{ width: "99px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={kijiji} alt="" style={{ width: "85px" }} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} className={classes.imgGrid}>
                <div className="pending__aboutus__item">
                  <img src={airbnb} alt="" style={{ width: "85px" }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

const mapStateToProps = () => {};

// const mapDispatchToProps = {
//   getMessageRequest,
//   addMessageRequest
// };

export default connect()(withStyles(styles)(Pending));
//  mapStateToProps
// mapDispatchToProps
