export const INITIALIZE_STATUS = "AUTH/INITIALIZE_STATUS";
export const CHECK_AUTHORIZATION = "AUTH/CHECK_AUTHORIZATION";
export const AUTH_TOKEN_EXPIRED = "AUTH/TOKEN_EXPIRED";

export const LOGIN_REQUEST = "AUTH/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS";
export const LOGIN_FAIL = "AUTH/LOGIN_FAIL";

export const LOGOUT_REQUEST = "AUTH/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "AUTH/LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "AUTH/LOGOUT_FAIL";

export const REGISTER_REQUEST = "AUTH/REGISTER_REQUEST";
export const REGISTER_SUCCESS = "AUTH/REGISTER_SUCCESS";
export const REGISTER_FAIL = "AUTH/REGISTER_FAIL";

export const EMAIL_VERIFY_REQUEST = "AUTH/EMAIL_VERIFY_REQUEST";
export const EMAIL_VERIFY_SUCCESS = "AUTH/EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAIL = "AUTH/EMAIL_VERIFY_FAIL";

export const PROFILE_GET_REQUEST = "AUTH/PROFILE_GET_REQUEST";
export const PROFILE_GET_SUCCESS = "AUTH/PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAIL = "AUTH/PROFILE_GET_FAIL";

export const PROFILE_UPDATE_REQUEST = "AUTH/PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "AUTH/PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "AUTH/PROFILE_UPDATE_FAIL";

export const PROFILE_PARTIAL_UPDATE_REQUEST = "AUTH/PROFILE_PARTIAL_UPDATE_REQUEST";
export const PROFILE_PARTIAL_UPDATE_SUCCESS = "AUTH/PROFILE_PARTIAL_UPDATE_SUCCESS";
export const PROFILE_PARTIAL_UPDATE_FAIL = "AUTH/PROFILE_PARTIAL_UPDATE_FAIL";

export const PASSWORD_CHANGE_REQUEST = "AUTH/PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "AUTH/PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAIL = "AUTH/PASSWORD_CHANGE_FAIL";

export const PASSWORD_RESET_REQUEST = "AUTH/PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "AUTH/PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "AUTH/PASSWORD_RESET_FAIL";


export const PASSWORD_RESET_CONFIRM_REQUEST =
  "AUTH/PASSWORD_RESET_CONFIRM_REQUEST";
export const PASSWORD_RESET_CONFIRM_SUCCESS =
  "AUTH/PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "AUTH/PASSWORD_RESET_CONFIRM_FAIL";
