import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  withStyles
} from "@material-ui/core";
import { IconCalendar, IconTag } from "../../components/Icon";
import "./styles.scss";

const styles = theme => ({
  backButton: {
    width: "203px",
    borderRadius: "24.5px",
    border: "2px solid #f2b143",
    fontSize: "14px",
    textAlign: "left",
    color: "#f2b143",
    textTransform: "initial",
    padding: "12px",
    backgroundColor: "#ffffff",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  },
  acceptButton: {
    width: "135px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "24.5px",
    fontSize: "14px",
    textWeight: "bold",
    textTransform: "initial",
    padding: "10px 40px 10px 40px",
    color: "#ffffff",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  }
});

class SelectRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.setState({
      email: "",
      passowrd: ""
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const {
      open,
      onClose,
      onBack,
      onSelect,
      history,
      roomData,
      userAInfo,
      classes
    } = this.props;
    const {} = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        className="dialog"
      >
        <DialogContent className="signup" style={{}}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="selectRoom">
            <p className="selectRoom__text">
              Are you sure you want to choose this room
            </p>
            <div className="selectRoom__body">
              <img
                alt=""
                src={roomData.images[0]}
                className="selectRoom__body__img"
              />
              <div className="selectRoom__body__detail">
                <p className="selectRoom__body__name">
                  You have selected {roomData.room_name}
                </p>
                <div className="selectRoom__body__price">
                  <IconTag alt="" />
                  <p>${roomData.price} CAD/month</p>
                </div>
                <div className="selectRoom__body__date">
                  <IconCalendar alt="" />
                  {userAInfo && (
                    <p>
                      {moment(userAInfo.datesPreference).format("YYYY.DD.MM")} -{" "}
                      {moment(userAInfo.datesPreference)
                        .add(userAInfo.lengthPreference, "months")
                        .subtract(1, "day")
                        .format("YYYY.DD.MM")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="selectRoom__buttons">
              <Button
                //    type="gradient"
                onClick={onBack}
                className={classes.backButton}
                id="backBtn"
              >
                Back to Booking Page
              </Button>
              <Button
                //     type="gradient"
                onClick={onBack}
                className={classes.backButton}
                id="backBtn1"
              >
                Back
              </Button>
              <Button
                //      type="gradient"
                onClick={onSelect}
                className={classes.acceptButton}
              >
                Accept
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectRoom));
