export const APPLICATION_DETAIL_REQUEST = 'APPLICATION/APPLICATION_DETAIL_REQUEST'
export const APPLICATION_DETAIL_SUCCESS = 'APPLICATION/APPLICATION_DETAIL_SUCCESS'
export const APPLICATION_DETAIL_FAIL = 'APPLICATION/APPLICATION_DETAIL_FAIL'

export const APPLICATION_DETAIL_UPDATE_REQUEST = 'APPLICATION/APPLICATION_DETAIL_UPDATE_REQUEST'
export const APPLICATION_DETAIL_UPDATE_SUCCESS = 'APPLICATION/APPLICATION_DETAIL_UPDATE_SUCCESS'
export const APPLICATION_DETAIL_UPDATE_FAIL = 'APPLICATION/APPLICATION_DETAIL_UPDATE_FAIL'

export const UNIVERSITY_LIST_REQUEST = 'APPLICATION/UNIVERSITY_LIST_REQUEST';
export const UNIVERSITY_LIST_SUCCESS = 'APPLICATION/UNIVERSITY_LIST_SUCCESS';
export const UNIVERSITY_LIST_FAIL = 'APPLICATION/UNIVERSITY_LIST_FAIL';

export const GENDER_LIST_REQUEST = 'APPLICATION/GENDER_LIST_REQUEST';
export const GENDER_LIST_SUCCESS = 'APPLICATION/GENDER_LIST_SUCCESS';
export const GENDER_LIST_FAIL = 'APPLICATION/GENDER_LIST_FAIL';

export const SEMESTER_LIST_REQUEST = 'APPLICATION/SEMESTER_LIST_REQUEST';
export const SEMESTER_LIST_SUCCESS = 'APPLICATION/SEMESTER_LIST_SUCCESS';
export const SEMESTER_LIST_FAIL = 'APPLICATION/SEMESTER_LIST_FAIL';


export const DATES_LIST_REQUEST = 'APPLICATION/DATES_LIST_REQUEST';
export const DATES_LIST_SUCCESS = 'APPLICATION/DATES_LIST_SUCCESS';
export const DATES_LIST_FAIL = 'APPLICATION/DATES_LIST_FAIL';

export const LENGTH_LIST_REQUEST = 'APPLICATION/LENGTH_LIST_REQUEST';
export const LENGTH_LIST_SUCCESS = 'APPLICATION/LENGTH_LIST_SUCCESS';
export const LENGTH_LIST_FAIL = 'APPLICATION/LENGTH_LIST_FAIL';
