import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { Button, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import InputTextField from "../../components/InputTextField";
import cubahausImg from "../../assets/SVG/cubahaus.png";
import SelectRoom from "../../components/SelectRoom";
import rightArrow from "../../assets/SVG/right_arrow.svg";
import download from "../../assets/SVG/download1.png";
import "./style.scss";
import {
  IconChecked,
  IconUnchekced,
  IconCalendar,
  IconTag,
  CubahausIcon
} from "../../components/Icon";
import BookingDetail from "../BookingDetail";
import BookingStepper from "./stepper.js";

import {
  saveBookingRequest,
  saveAcceptRequest,
  getBookingRequest,
  saveSelectRoomRequest,
  getSelectRoomRequest
} from "../../actions/booking";
import { roomListRequest } from "../../actions/room";
import { stateUpdateRequest } from "../../actions/state";
const styles = theme => ({
  uncheck: {
    width: "24px",
    height: "24px",
    border: "2px solid gray",
    borderRadius: "50%",
    margin: "0px",
    minWidth: "24px"
  },
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 5px",
    minWidth: "135px",
    textTransform: "capitalize"
  },
  accepted: {
    minWidth: "135px",
    width: "135px",
    height: "50px",
    borderRadius: "24.5px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ffffff",
    letterSpacing: "0.7px",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  accept: {
    minWidth: "135px",
    width: "135px",
    height: "50px",
    borderRadius: "24.5px",
    border: "solid 2px #e6813b",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#e6813b",
    letterSpacing: "0.7px",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:active": {
      opacity: "0.7"
    }
  },
  selected: {
    width: "120px",
    height: "35px",
    borderRadius: "17.5px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    textTransform: "capitalize"
  },
  select: {
    width: "96px",
    height: "35px",
    borderRadius: "17.5px",
    backgroundColor: "#ffffff",
    border: "2px solid #a5b2d5",
    color: "#a5b2d5",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "capitalize"
  }
});

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      community: false,
      building: false,
      home: false,
      avatar: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",
      numPages: null,
      pageNumber: 1,
      acceptList: [true, false, false],
      roomNumber: -1,
      acceptDialog: false,
      selectedRoom: null,
      contractUrl: ""
    };
  }

  componentDidMount() {
    // this.props.roomListRequest(); //wishRoomList road
    // this.props.getBookingRequest(); // roomInfo, roomAInfo road
    this.props.getSelectRoomRequest(); // selectRoomId road
    this.getContractUrl();
  }

  componentDidUpdate(prevProps) {
    const { acceptList, roomNumber, avatarURL, progress } = this.state;
    const { saveAcceptRequest, selectRoomId, wishList, userInfo } = this.props;
    const acceptedAll = acceptList[0] && acceptList[1] && acceptList[2];

    if (selectRoomId !== null) {
      wishList &&
        wishList.map((roomId, index) => {
          if (selectRoomId === roomId) {
            roomNumber === -1 &&
              this.setState({
                roomNumber: index
              });
          }
        });
    }
    if (userInfo && userInfo.acceptRegulations === 1) {
      !acceptedAll &&
        this.setState({
          acceptList: [true, true, true]
        });
    }
    if (userInfo && userInfo.cardURL !== undefined) {
      progress !== 100 && this.setState({ progress: 100 });
    }
    if (acceptedAll) {
      saveAcceptRequest();
    }
    if (avatarURL !== "") {
      const payload = { avatarURL: avatarURL };
      this.props.saveBookingRequest(payload);
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onClose = () => {
    this.setState({
      acceptDialog: false
    });
  };

  nextPage = () => {
    this.setState({
      acceptDialog: true
    });
  };

  onAccept = () => {
    this.onClose();
    const {
      community,
      building,
      home,
      progress,
      acceptList,
      roomNumber
    } = this.state;
    if (
      acceptList[0] &&
      acceptList[1] &&
      acceptList[2] &&
      roomNumber > -1 &&
      progress === 100
    )
      this.submitForm();
    else return;
  };

  submitForm = () => {
    const {
      saveBookingRequest,
      saveSelectRoomRequest,
      stateUpdateRequest,
      userInfo
    } = this.props;
    const { avatarURL, roomNumber } = this.state;

    if (avatarURL === "") {
      if (userInfo.cardURL !== undefined) {
        stateUpdateRequest({ agreeTerm: true });
        // this.props.getSelectRoomRequest();
      } else {
        return;
      }
    } else {
      const payload = { avatarURL: avatarURL };
      saveBookingRequest(payload);
      stateUpdateRequest({ agreeTerm: true });
    }
  };

  handleDownloadStart = () => {};
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    const userId = this.props.auth.loggedInUser.uid;






    // let userContractRef = firebase.storage().ref(`users/${userId}/`).listAll();
    // console.log('userContractRef :', userContractRef);


    firebase
      .storage()
      .ref(`users/${userId}/`)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url });
      });
  };

  getContractUrl = async() => {
    const userId = this.props.auth.loggedInUser.uid;
    console.log('userId :', userId);

    let storageRef = firebase.storage().ref();
    let listRef = await storageRef.child(`users/${userId}/`).listAll();



    firebase
      .storage()
      .ref(listRef.items[1].location.path)
      .getDownloadURL()
      .then(url => {
      console.log('url :', url);
        // console.log(url);
        this.setState({ contractUrl: url });
      })
      .catch(err => {
        console.log(err);
      });
  };

  acceptTerm = i => e => {
    e.preventDefault();
    let acceptListData = this.state.acceptList;
    acceptListData[i] = true;
    this.setState({
      acceptList: acceptListData
    });
  };

  selectRoom = i => e => {
    e.preventDefault();
    this.setState({
      roomNumber: i
    });
    this.props.saveSelectRoomRequest(i);
  };

  onSelectRoom = roomData => {
    this.setState({
      selectedRoom: roomData
    });
  };

  unSelectRoom = () => {
    this.setState({
      selectedRoom: null
    });
  };

  render() {
    const {
      classes,
      wishList,
      wishRoomList,
      userAInfo,
      selectRoomId,
      userInfo,
      detail
    } = this.props;
    let {
      community,
      building,
      home,
      pageNumber,
      numPages,
      progress,
      acceptList,
      roomNumber,
      selectedRoom
    } = this.state;

    const roomInfo = detail;
    let roomdata;
    if (wishRoomList && wishRoomList.length !== 0) {
      roomNumber === -1
        ? (roomdata = wishRoomList[0].data)
        : (roomdata = wishRoomList[roomNumber].data);
    }
    const acceptedAll = acceptList[1] && acceptList[2];
    const userId = this.props.auth.loggedInUser.uid;
    // console.log(this.getContractUrl());

    // console.log('conractUrl :', this.state.contractUrl);

    // console.log(userInfo.cardURL);
    // console.log(userAInfo);
    return selectedRoom ? (
      <BookingDetail roomData={selectedRoom} onBack={this.unSelectRoom} />
    ) : (
      <div className="content">
        {userInfo && (
          <div>
            <SelectRoom
              open={this.state.acceptDialog}
              onClose={this.onClose}
              onBack={this.onClose}
              onSelect={this.onAccept}
              roomData={roomdata}
              userAInfo={userAInfo}
            />
            <div className="booking">
              <div className="">
                <form autoComplete="off">
                  {/* // community regulations */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <p className="booking__title">Booking Page</p>
                    {/* <p className="booking__text">
                      Accept All Regulations to proceed
                    </p> */}
                  </Grid>

                  {/* //upload Id */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid item xs={12} className="booking__item">
                      <div className="booking__item__body">
                        <div style={{ maxWidth: "600px", marginLeft: "0px" }}>
                          <p>Download your contract</p>
                        </div>
                      </div>
                      <a href={this.state.contractUrl} target="_blank">
                        <div
                          className={classes.accept}
                          //    onClick={this.handleDownloadStart}
                          id="uploadedButton"
                        >
                          <img alt="" src={download} />
                          <span style={{ textDecoration: "underline white" }}>
                            &nbsp;Download
                          </span>
                        </div>
                      </a>
                    </Grid>
                  </Grid>
                </form>
                <div>
                  {/* <p className="checkoutpreview__title">Checkout Preview</p>
                  <p className="checkoutpreview__text">
                    Please <b> select 1 room </b> from favorites
                  </p> */}
                  <Grid
                    className="name__form"
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid
                      item
                      xs={12}
                      className="checkoutpreview__item1"
                      //   key={index}
                    >
                      <div className="checkoutpreview__item1__data">
                        <img
                          className="checkoutpreview__item1__img"
                          alt="alt"
                          src={roomInfo.data.images[0]}
                        />
                        <div style={{ marginLeft: "-250px" }}>
                          <div className="checkoutpreview__item1__data__name">
                            <span>{roomInfo.data.room_name}</span>
                            <span>
                              <div className="checkoutpreview__item1__price">
                                <IconTag
                                  alt=""
                                  style={{ width: "27px", fill: "#5980ff" }}
                                />
                                <p>${roomInfo.data.price}</p>
                              </div>
                            </span>
                          </div>
                          <p className="checkoutpreview__item1__data__community">
                            {roomInfo.data.community} community
                          </p>
                          <p className="checkoutpreview__item1__data__address">
                            Address:
                          </p>
                          <p className="checkoutpreview__item1__data__apartment">
                            Apartment:
                          </p>
                          <div className="checkoutpreview__item1__data__date">
                            <IconCalendar
                              alt=""
                              style={{ width: "27px", fill: "#5980ff" }}
                            />
                            {userAInfo && (
                              <p>
                                {moment(userAInfo.datesPreference).format(
                                  "YYYY.DD.MM"
                                )}{" "}
                                -{" "}
                                {moment(userAInfo.datesPreference)
                                  .add(userAInfo.lengthPreference, "months")
                                  .format("YYYY.DD.MM")}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="checkoutpreview__item1__buttons">
                          <div className="checkoutpreview__item1__pricediv">
                            <div className="checkoutpreview__item1__price">
                              <IconTag
                                alt=""
                                style={{ width: "27px", fill: "#5980ff" }}
                              />
                              <p>${roomInfo.data.price}</p>
                            </div>
                          </div>
                          <Button
                            //  to={{
                            //    pathname: `/application/booking/${room.data.room_name}`,
                            //    state: {
                            //      roomData: room.data,
                            //      index: index,
                            //      userAInfo: userAInfo
                            //    }
                            //  }}
                            onClick={() => this.onSelectRoom(roomInfo)}
                          >
                            <div className="checkoutpreview__item1__buttons__moreInfo">
                              View more info about this Room
                            </div>
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {/* <div className="form__button__container">
                  <Button className={classes.button} onClick={this.nextPage}>
                    <span className="form__nextButton__text form__nextButton__text--right">
                      Next
                    </span>
                    <img
                      src={rightArrow}
                      className="form__arrow form__arrow--right"
                      alt="rightArrow"
                    />
                  </Button>
                </div> */}
                <div className="bookingStep">
                  <p>What happens next?</p>
                  <BookingStepper fail={false} roomInfo={roomInfo} />
                  <img alt="" src={cubahausImg} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, room, booking }) => ({
  auth,
  wishRoomList: room.wishRoomList,
  wishList: room.wishlist,
  bookedList: room.bookedList,
  userInfo: booking.userInfo,
  userAInfo: booking.userAInfo,
  selectRoomId: booking.selectRoomId,
  detail: room.detail
});

const mapDispatchToProps = {
  saveBookingRequest,
  saveAcceptRequest,
  getBookingRequest,
  saveSelectRoomRequest,
  getSelectRoomRequest,
  stateUpdateRequest,
  roomListRequest
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Booking));
