import React from 'react'
import { injectStripe } from 'react-stripe-elements'
import SignaturePad from 'react-signature-pad'

class Contract extends React.Component {
  constructor(props) {
    super(props)    
  }

  render() {
    const { application, detail } = this.props
    const appDetail = application.detail
    const { start_at, end_at } = appDetail != null && appDetail
    let startDate = start_at && new Date(start_at.seconds*1000)
    let endDate = end_at && new Date(end_at.seconds*1000)
    startDate = startDate && startDate.toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'})
    endDate = endDate && endDate.toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'})
    const { price, room_name, community } = detail.data

    return (
      <div className="Checkout">
        <h1>Sign the main contract</h1>
        <div>
          <div>
            by the present, the following is agreed on between  { community }, the applicant and ... 
          </div>
          <div>
            2. Rental period: from { startDate } to { endDate }
          </div>
          <div>
            3. Cost of the rent { price } $ CAD/month
          </div>
          <div>
            9. Rental Deposit { price }$ CAD.   
          </div>
        </div>
        <h2>
          Agreement Signed:
        </h2>
          
      </div>
    );
  }
}

export default Contract