export const GET_CHECKOUT_PREVIEW_REQUEST = 'CHECKOUT/GET_CHECKOUT_PREVIEW_REQUEST';
export const GET_CHECKOUT_PREVIEW_SUCCESS = 'CHECKOUT/GET_CHECKOUT_PREVIEW_SUCCESS';
export const GET_CHECKOUT_PREVIEW_FAIL = 'CHECKOUT/GET_CHECKOUT_PREVIEW_FAIL';

export const SAVE_TOKEN_REQUEST = 'CHECKOUT/SAVE_TOKEN_REQUEST';
export const SAVE_TOKEN_SUCCESS = 'CHECKOUT/SAVE_TOKEN_SUCCESS';
export const SAVE_TOKEN_FAIL = 'CHECKOUT/SAVE_TOKEN_FAIL';

export const MOVE_TO_SUPPORT_REQUEST = 'CHECKOUT/MOVE_TO_SUPPORT_REQUEST';
export const MOVE_TO_SUPPORT_SUCCESS = 'CHECKOUT/MOVE_TO_SUPPORT_SUCCESS';
export const MOVE_TO_SUPPORT_FAIL = 'CHECKOUT/MOVE_TO_SUPPORT_FAIL';