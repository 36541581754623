
export const step1ErrorMessages = [
  {
    title: "Please Fill the Information above",
  },
  {
    title: "Select an approximate Monthly budget for the period you will live in CubaHaus. ",
  },
  {
    title: "We offer different types of accommodation to suit your needs, including female-only accommodation.",
  },
  {
    title: "The dates below correspond to the start of the University's semester. Please select the date which matches your arrival.",
  },
  {
    title: "Please Select your duration of stay",
  },
  {
    title: "Please Select your university",
  }
];
