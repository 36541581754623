import React, { Component } from "react";
import { connect } from "react-redux";
import { moveToSupportRequest } from "../../actions/checkout";
import "./styles.scss";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      seconds: 0,
      time: 0,
      startFlag: false,
      startCount: true
    };
  }
  secondsRemaining = -1;
  intervalHandle;
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if (this.state.startFlag) return;
    const { remainTime, startTimeSeconds } = this.props;
    if (startTimeSeconds > 0 && remainTime > 0 && this.state.startCount) {
      this._isMounted && this.setState({ startCount: false });
      this.startCountDown(remainTime);
    }
  }

  componentDidUpdate() {
    const { moveToSupportRequest, remainTime, startTimeSeconds } = this.props;
    if (this.state.startFlag && this.secondsRemaining === 1)
      moveToSupportRequest();

    if (startTimeSeconds > 0 && remainTime > 0 && this.state.startCount) {
      this._isMounted && this.setState({ startCount: false });
      this.startCountDown(remainTime);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  tick = () => {
    var min = Math.floor(this.secondsRemaining / 60);
    var sec = this.secondsRemaining - min * 60;

    if (this._isMounted) {
      this.setState({
        time: min,
        seconds: sec
      });

      if (sec < 10) {
        this.setState({
          seconds: "0" + this.state.seconds
        });
      }

      if (min < 10) {
        this.setState({
          time: "0" + min
        });
      }
    }

    if ((min === 0) & (sec === 0)) {
      clearInterval(this.intervalHandle);
    }
    this.secondsRemaining--;
  };

  startCountDown = remainTime => {
    this.setState({ startFlag: true });
    this.secondsRemaining = remainTime * 60;
    this.intervalHandle = setInterval(this.tick, 1000);
  };

  render() {
    const { time, seconds } = this.state;
    return (
      <div className="timer">
        <div className="timer__body">
          <p className="timer__time">
            <span>{time}</span> min <b>:</b> <span>{seconds}</span> sec
          </p>
          <p className="timer__text">
            <span>*</span> You have 30 minutes to <br />
            complete this step
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ checkout }) => ({});

const mapDispatchToProps = {
  moveToSupportRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timer);
