import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet";

import FaqItem from "../../components/FaqItem";
import Signup from "../../components/Signup";

import faqImg from "../../assets/landing/14.svg";
import contactImg from "../../assets/landing/17.svg";

import { IconRocket } from "../../components/Icon";

import "./faq.scss";

// const FETCH_FAQ = "FETCH_FAQ";
const API_BASE_URL = "https://cdn.contentful.com";
const API_SPACE_ID = "fwv88fbbv7ho";
const API_TOKEN = "1M289ycxUqhKwvcii33NXdQg2JAavhQzZfiEYnAI_4w";

const Title = styled.div`
  font-family: Montserrat;
  font-size: 60px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.2px;
  color: #4c5788;
  margin-bottom: 30px;
`;

const MainText = styled.div`
  font-family: Montserrat;
  font-size: 22.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.97;
  text-align: left;
  letter-spacing: 0.5px;
  margin-top: 42px;
  margin-bottom 61px;
  color: rgba(39, 52, 110, 0.73);
`;

class Faq extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      signUpShow: false,
      faqArray: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    axios
      .get(
        `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=faq`
      )
      .then(res => {
        if (this._isMounted) {
          this.setState({
            faqArray: res.data.items
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = nextProps;
    if (auth.error) {
      this.setState({});
    }
  }
  handleOpenSignup = e => {
    this.setState({
      signUpShow: true
    });
  };

  onClose = e => {
    this.setState({ signInShow: false, signUpShow: false });
  };

  render() {
    // const { history, classes } = this.props;
    const { faqArray } = this.state;

    return (
      <div className="faqContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Frequently Asked Question | Cubahaus</title>
          <meta
            name="description"
            content="Browse frequently asked questions and answers about Cubahaus, Downtown Montreal's international student housing. Learn about our large apartments, location, amenities, and more."
          />
          <link rel="canonical" href="https://cubahaus.ca/faq" />
          <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What do Cubahaus units include?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All bedrooms and communal spaces are fully furnished. Cubahaus units also include: Heating, Electricity, Hot water, Internet, Washer, Dryer, Equipped Kitchen etc."
    }
  }, {
    "@type": "Question",
    "name": "What are the move in and move out dates?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our leases starts on September 1st, January 1st and May 1st. Your actual move in date can be later than those dates but not before. Our leases ends on December 31st, April 30th and August 31st. Your actual move out date can be before those dates but not after. We can make exceptions during summer as long as your move in and move out dates are anywhere between May 1st and August 31st."
    }
  }, {
    "@type": "Question",
    "name": "Where in Montreal is Cubahaus located?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Cubahaus consists of 3 communities (3 buildings) all located Downtown Montreal. Those 3 buildings are respectively 5 minutes walk from 3 different Metro stations. One at station Saint-Laurent, one at station Guy-Concordia, and one at station Berri-UQAM. They are all within 15 minute walk from grocery stores, iconic bars, great restaurants, trendy clubs, shopping streets, gyms etc."
    }
  }]
  }
        `}</script>
        </Helmet>

        <Signup open={this.state.signUpShow} onClose={this.onClose} />
        <div className="faq">
          <div style={{}}>
            <Title className="faq__title">
              Frequently
              <br />
              Asked Questions
            </Title>
            <img alt="" src={faqImg} className="faq__img" />
            {faqArray.length !== 0 &&
              faqArray.map((faq, key) => (
                <FaqItem
                  key={key}
                  question={faq.fields.question}
                  answer={faq.fields.answer}
                />
              ))}
          </div>
        </div>
        <div className="faqsection4__contact">
          <Title
            className="faqsection4__contact__title"
            onClick={() => window.amplitude.getInstance().logEvent("APPLY_FAQ")}
          >
            Apply Now
          </Title>
          <div className="faqsection4__contact__body">
            <div className="faqsection4__contact__body__items">
              <MainText className="faqsection4__contact__body__text">
                <b>
                  Check out our rooms, make your wishlist and send us your
                  application letter.
                </b>
              </MainText>

              <IconButton
                className="section2__apply__ButtonApply"
                //  onClick={this.handleOpenSignup}
                component={Link}
                to="/register"
                style={{ padding: "unset" }}
              >
                <IconRocket className="ButtonIcon" />
                Apply
              </IconButton>
            </div>
            <img
              alt=""
              src={contactImg}
              className="faqsection4__contact__img"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Faq);
