import {
  all
  //fork,
  // take
} from "redux-saga/effects";
import { REHYDRATE } from "redux-persist/lib/constants";
import auth from "./auth";
import community from "./community";
import room from "./room";
import slot from "./slot";
import application from "./application";
import questions from "./questions";
import state from "./state";
import docs from "./docs";
import upload from "./upload";
import info from "./info";
import checkout from "./checkout";
import booking from "./booking";
import end from "./end";

export default function* rootSaga() {
  yield all([
    auth(),
    community(),
    room(),
    slot(),
    application(),
    questions(),
    state(),
    docs(),
    upload(),
    info(),
    checkout(),
    booking(),
    end()
  ]);
}
