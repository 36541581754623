import React from "react";
// import { Elements } from "react-stripe-elements";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_snyIzT57doqhw2oHrOES5Pv400Cz1c2HbB");

class StripeSection extends React.Component {
  render() {
    const { checkoutRequest, loading } = this.props;
    return (
      // <StripeProvider apiKey='pk_test_6pRNASCoBOKtIshFeQd4XMUh'>
      <Elements stripe={stripePromise}>
        <CheckoutForm checkoutRequest={checkoutRequest} loading={loading} />
      </Elements>
      // </StripeProvider>
    );
  }
}

export default StripeSection;
