export const COMMUNITY_WISHLIST_TEXT = "Choose your favorite from the rooms that match your user profile. We know that your room is an important part of this living experience. That’s why you have choices.";

export const TABS = [
    {
      title: "Amenities"
    },
    {
      title: "Bedroom"
    },
    {
      title: "Kitchen"
    },
    {
      title: "Bathroom"
    },
    {
      title: "Shared"
    }
  
  ];
