import React from "react";
import { Grid } from "@material-ui/core";

import CheckIcon from "../../components/CheckIcon";
import IncludeText from "../../components/IncludeText";
import { IconChecked } from "../../components/Icon";

const Amenities = ({ amenities }) => {
  const {
    ac,
    bed_linen,
    dryer,
    electricity,
    heating,
    hotwater,
    wifi
  } = amenities;
  return (
    <div className="roomdetail__description__amenities">
      <Grid container spacing={24} justify="space-evenly">
        <Grid item xs={10} md={4}>
          {/* <div className="roomdetail__description__amenities--item">
            <IconChecked className="icon checked" />
            <div>
              <p>Room is Fully Furnished</p>
              <span>Bed, Closet, Desk, Lamp and Chair</span>
            </div>
          </div> */}

          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={hotwater} />
            <div>
              <p>Hot water</p>
              <IncludeText included={hotwater} />
            </div>
          </div>
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={wifi} />
            <div>
              <p>WiFi</p>
              <IncludeText included={wifi} />
            </div>
          </div>

          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={electricity} />
            <div>
              <p>Electricity</p>
              <IncludeText included={electricity} />
            </div>
          </div>
          
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={heating} />
            <div>
              <p>Heating</p>
              <IncludeText included={heating} />
            </div>
          </div>


          {/* <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={pillows} />
            <div>
              <p>Pillows</p>
              <IncludeText included={pillows} />
            </div>
          </div> */}
          {/* <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={bed_linen} />
            <div>
              <p>Bed linen</p>
              <IncludeText included={bed_linen} />
            </div>
          </div> */}

        </Grid>
        <Grid item xs={10} md={4}>
       

          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={ac} />
            <div>
              <p>Air conditioned</p>
              <IncludeText included={ac} />
            </div>
          </div>
  
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={wifi} />
            <div>
              <p>Washer</p>
              <span></span>
            </div>
          </div>
          <div className="roomdetail__description__amenities--item">
            <CheckIcon checked={dryer} />
            <div>
              <p>Dryer</p>
              <IncludeText included={dryer} />
            </div>
          </div>

        </Grid>
      </Grid>
    </div>
  );
};

export default Amenities;
