import React from "react"
import "./room.scss";

class Support extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  render() {

    return (
        <div className="content">
            <div className="main">
                <div className="form">
                    <center>
                    <h1>Contact Support team...</h1>
                    <h3>Exceeded limit time 30min</h3>
                    </center>
                </div>
            </div>
      </div>
    );
  }
}

export default Support

