export const GET_INFO_REQUEST = 'INFO/GET_INFO_REQUEST';
export const GET_INFO_SUCCESS = 'INFO/GET_INFO_SUCCESS';
export const GET_INFO_FAIL = 'INFO/GET_INFO_FAIL';

export const UPDATE_INFO_REQUEST = 'INFO/UPDATE_INFO_REQUEST';
export const UPDATE_INFO_SUCCESS = 'INFO/UPDATE_INFO_SUCCESS';
export const UPDATE_INFO_FAIL = 'INFO/UPDATE_INFO_FAIL';

export const PARTIAL_UPDATE_INFO_REQUEST = 'INFO/PARTIAL_UPDATE_INFO_REQUEST';
export const PARTIAL_UPDATE_INFO_SUCCESS = 'INFO/PARTIAL_UPDATE_INFO_SUCCESS';
export const PARTIAL_UPDATE_INFO_FAIL = 'INFO/PARTIAL_UPDATE_INFO_FAIL';