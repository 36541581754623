export const SAVE_BOOKING_REQUEST = "BOOKING/SAVE_BOOKING_REQUEST";
export const SAVE_BOOKING_SUCCESS = "BOOKING/SAVE_BOOKING_REQUEST";
export const SAVE_BOOKING_FAIL = "BOOKING/SAVE_BOOKING_FAIL";
export const SAVE_ACCEPT_REQUEST = "BOOKING/SAVE_ACCEPT_REQUEST";
export const SAVE_ACCEPT_SUCCESS = "BOOKING/SAVE_ACCEPT_SUCCESS";
export const SAVE_ACCEPT_FAIL = "BOOKING/SAVE_ACCEPT_FAIL";
export const GET_BOOKING_REQUEST = "BOOKING/GET_BOOKING_REQUEST";
export const GET_BOOKING_SUCCESS = "BOOKING/GET_BOOKING_SUCCESS";
export const GET_BOOKING_FAIL = "BOOKING/GET_BOOKING_FAIL";
export const GET_SELECTROOM_REQUEST = "BOOKING/GET_SELECTROOM_REQUEST";
export const GET_SELECTROOM_SUCCESS = "BOOKING/GET_SELECTROOM_SUCCESS";
export const GET_SELECTROOM_FAIL = "BOOKING/GET_SELECTROOM_FAIL";
export const SAVE_SELECTROOM_REQUEST = "BOOKING/SAVE_SELECTROOM_REQUEST";
export const SAVE_SELECTROOM_SUCCESS = "BOOKING/SAVE_SELECTROOM_SUCCESS";
export const SAVE_SELECTROOM_FAIL = "BOOKING/SAVE_SELECTROOM_FAIL";
