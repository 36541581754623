import { call, put, takeLatest, select } from "redux-saga/effects";
// import firebase from "firebase";
import {
  COMMUNITY_LIST_REQUEST,
  COMMUNITY_DETAIL_REQUEST
} from "../constants/community";
import {
  communityListSuccess,
  communityListFail,
  communityDetailSuccess,
  communityDetailFail
} from "../actions/community";
import { rsf, db } from "../rsf";

function* communityListRequestHandler() {
  try {
    const res = yield call(rsf.firestore.getCollection, "community");
    let communities = [];
    res.forEach(item => communities.push(item.data()));
    yield put(communityListSuccess(communities));
  } catch (e) {
    yield put(communityListFail(e.response));
  }
}

function* communityDetailRequestHandler({ payload }) {
  //not able to fetch the user university for the first time
  // after I refresh it gets it
  const state = yield select();
  let university;
  if (state.application.detail)
    university = state.application.detail.university;
  const cUser = state.auth.loggedInUser;
  try {
    let userAInfo = yield db
      .collection("users")
      .doc(cUser.uid)
      .collection("application")
      .doc("1")
      .get();
    userAInfo = userAInfo.data();
    if (university === undefined) university = userAInfo.university;
    let communityDetail, universities;
    let res = yield db
      .collection("community")
      .where("name", "==", payload)
      .get();
    yield res.forEach(item => (communityDetail = item.data()));
    universities = state.university.list;

    let uni;
    yield universities.forEach(item => {
      if (item.name == university) {
        uni = item;
        return;
      }
    });

    yield put(communityDetailSuccess({ communityDetail, uni }));
  } catch (e) {
    yield put(communityDetailFail(e.response));
  }
}

export default function* communitySaga() {
  yield takeLatest(COMMUNITY_LIST_REQUEST, communityListRequestHandler);
  yield takeLatest(COMMUNITY_DETAIL_REQUEST, communityDetailRequestHandler);
}
