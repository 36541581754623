import auth from "./auth";
import community from "./community";
import room from "./room";
import slot from "./slot";
import application from "./application";
import questions from "./questions";
import state from "./state";
import docs from "./docs";
import upload from "./upload";
import gender from "./gender";
import university from "./university";
import semester from "./semesters";
import dates from "./dates";
import length from "./length";
import snackbar from "./snackbar";
import info from "./info";
import checkout from "./checkout";
import booking from "./booking";
import end from "./end";

const rootReducer = {
  auth,
  community,
  room,
  slot,
  application,
  questions,
  state,
  docs,
  upload,
  gender,
  dates,
  length,
  university,
  semester,
  snackbar,
  info,
  checkout,
  booking,
  end
};

export default rootReducer;
