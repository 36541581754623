import {
  ADD_MESSAGE_FAIL,
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL
} from "../constants/questions";

const initialState = {
  loading: false,
  detail: null,
  status: null,
  error: null
};

export default function questionsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MESSAGE_REQUEST:
      return {
        ...state,
        detail: null,
        status: type,
        error: null
      };
    case ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        detail: payload,
        status: type,
        error: null
      };
    case ADD_MESSAGE_FAIL:
      return {
        ...state,
        detail: null,
        status: type,
        error: payload
      };
    case GET_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
        detail: null,
        status: type,
        error: null
      };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: payload,
        status: type,
        error: null
      };
    case GET_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
        detail: null,
        status: type,
        error: payload
      };
    default:
      return state;
  }
}
