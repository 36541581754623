import firebase from "firebase";
import { call, put, takeLatest, select, fork } from "redux-saga/effects";

import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REGISTER_REQUEST,
  EMAIL_VERIFY_REQUEST,
  PROFILE_UPDATE_REQUEST,
  PROFILE_GET_REQUEST,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_CONFIRM_REQUEST,
  CHECK_AUTHORIZATION,
  AUTH_TOKEN_EXPIRED,
  PROFILE_PARTIAL_UPDATE_REQUEST
} from "../constants/auth";

import {
  loginRequest,
  loginSuccess,
  loginFail,
  logoutSuccess,
  logoutFail,
  // registerSuccess,
  registerFail,
  emailVerifySuccess,
  emailVerifyFail,
  profileUpdateSuccess,
  profileUpdateFail,
  profileGetSuccess,
  profileGetFail,
  passwordChangeSuccess,
  passwordChangeFail,
  passwordResetSuccess,
  passwordResetFail,
  passwordResetConfirmSuccess,
  passwordResetConfirmFail
} from "../actions/auth";

import {
  getHeaders,
  // setAuthTokenHeader,
  checkExpirity,
  clearToken
} from "../utils/authHelper";
import axios from "axios";
import { enqueueSnackbar } from "../actions/snackbar";
import { rsf, addUser, db } from "../rsf";
import { stateRequest } from "../actions/state";
import { statusList } from "../constants/user_status";

import { stateSuccess, stateFail } from "../actions/state";

axios.defaults.baseURL = "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev";

export function* checkAuthorizationHandler() {
  const result = checkExpirity();
  if (result.error) {
    clearToken();
    yield put({ type: AUTH_TOKEN_EXPIRED });
  } else {
    yield put({ type: PROFILE_GET_REQUEST });
  }
}

export function* loginRequestHandler({ payload }) {
  try {
    if (payload.index > 0) {
      //console.log("PAY.INDEX>0");
      const authProvider =
        payload.index === 1
          ? new firebase.auth.GoogleAuthProvider()
          : new firebase.auth.FacebookAuthProvider();
      const res = yield call(rsf.auth.signInWithPopup, authProvider);
      const cUser = yield firebase.auth().currentUser;
      //console.log('cUser :', cUser);

      //console.log('res :', res);
      //console.log('cUser :', cUser);
      const res1 = yield db
        .collection("users")
        .doc(cUser.uid)
        .get();
      //console.log('res1 :', res1);
      const displayName = cUser.displayName;
      //console.log('displayName :', displayName);
      //console.log('email :', cUser.email);
      const userName = displayName != null ? displayName.split(" ") : ["", ""];
      //console.log('userName :', userName);
      if (!res1.exists) {
        //console.log("DOEST NOT EXIST");
        yield addUser({
          firstName: userName[0],
          lastName: userName[1],
          email: cUser.email,
          docName: cUser.uid,
          message: "",
          uid: cUser.uid
        });

        yield put(
          loginSuccess({
            email: cUser.email,
            token: res.accessToken,
            first_name: userName[0],
            last_name: userName[1],
            uid: cUser.uid,
            message: ""
          })
        );

        localStorage.setItem("ch-username", displayName);
        localStorage.setItem("ch-userToken", res.accessToken);



      } else {
        yield put(
          loginSuccess({
            email: cUser.email,
            token: res.accessToken,
            first_name: userName[0],
            last_name: userName[1],
            uid: cUser.uid,
            message: ""
          })
        );

        localStorage.setItem("ch-username", displayName);
        localStorage.setItem("ch-userToken", res.accessToken);



      }
    } else {
      // const rep = yield db.collection('users').where('email', '==', payload.username).get()
      //console.log("EXIST NOT EXIST")
      let username = payload.username;
      username = username.toLowerCase();

      yield call(
        rsf.auth.signInWithEmailAndPassword,
        username,
        payload.password
      );
      const cUser = yield firebase.auth().currentUser;
      const rep = yield db
        .collection("users")
        .doc(cUser.uid)
        .get();
      //console.log('rep :', rep);
      let user;
      user = rep.data();
      const token = cUser.refreshToken;

      yield put(
        loginSuccess({
          // email: user.email,
          token: token,
          uid: cUser.uid,
          first_name: user.firstName,
          last_name: user.lastName
        })
      );
      //console.log('cUser.firstName :', cUser.firstName);

      localStorage.setItem("ch-username", cUser.firstName + cUser.lastName);
      localStorage.setItem("ch-userToken", token);

      
      payload = {
        url: window.location.href,
        referrer: document.referrer,
        userId: cUser.uid,
        eventType: 'login'
      }
      const params = {
        url: "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev/collect",
        method: "post",
        data: payload
      };

       yield call(axios.request, params);

       





    }
    yield put(stateRequest());
    // yield fork(detectApprovement)

    yield put(
      enqueueSnackbar({
        message: "Login Success",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    // yield put(
    //   enqueueSnackbar({
    //     message: err.message,
    //     options: { variant: "error" }
    //   })
    // );
    yield put(loginFail("Invalid Username or Password"));
  }
}

export function* logoutRequestHandler({ payload }) {
  try {
    const res = yield call(rsf.auth.signOut);
    localStorage.clear();
    yield put(logoutSuccess(res));
    yield put(
      enqueueSnackbar({
        message:
          "See you later. Remember to check your email for other updates from Cubahaus.",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(logoutFail(payload));
  }
}

export function* registerRequestHandler({ payload }) {
  try {
    if (payload.index > 0) {
      const authProvider =
        payload.index === 1
          ? new firebase.auth.GoogleAuthProvider()
          : new firebase.auth.FacebookAuthProvider();
      const res = yield call(rsf.auth.signInWithPopup, authProvider);
      const cUser = yield firebase.auth().currentUser;
      const res1 = yield db
        .collection("users")
        .doc(cUser.uid)
        .get();
      const displayName = cUser.displayName;
      const userName = displayName != null ? displayName.split(" ") : ["", ""];
      if (!res1.exists)
        yield addUser({
          firstName: userName[0],
          lastName: userName[1],
          email: cUser.email,
          docName: cUser.uid,
          message: "",
          uid: cUser.uid
        });

      yield put(
        loginSuccess({
          email: cUser.email,
          token: res.accessToken,
          first_name: userName[0],
          last_name: userName[1],
          uid: cUser.uid,
          message: ""
        })
      );

      localStorage.setItem("ch-username", displayName);
      localStorage.setItem("ch-userToken", res.accessToken);
    } else {
      // const res = yield call(rsf.auth.createUserWithEmailAndPassword, payload.email, payload.password1);
      let email = payload.email;
      email = email.toLowerCase();
      const res = yield call(
        rsf.auth.createUserWithEmailAndPassword,
        email,
        payload.password1
      );
      const displayName = res.displayName;
      const userName = displayName != null ? displayName.split(" ") : ["", ""];

      yield addUser({
        firstName: userName[0],
        lastName: userName[1],
        email: res.user.email,
        docName: res.user.uid,
        message: ""
      });
      // yield put(registerSuccess(res));

      const actionCodeSettings = {
        url: "https://cubahaus.ca",
        handleCodeInApp: true
      };

      yield call(rsf.auth.sendEmailVerification, actionCodeSettings);
      payload = {
        ...payload,
        password: payload.password1
      };
      yield put(loginRequest(payload));
      yield put(
        enqueueSnackbar({
          message: "A verification link has been sent to your email.",
          options: { variant: "success" }
        })
      );
    

      payload = {
        url: window.location.href,
        referrer: document.referrer,
        userId: email,
        eventType: 'reigster'
      }
      const params = {
        url: "https://ctg45ygilc.execute-api.us-east-1.amazonaws.com/dev/collect",
        method: "post",
        data: payload
      };

       yield call(axios.request, params);


    }
  } catch (err) {
    // console.log('err :', err);

    //   yield put(
    //     enqueueSnackbar({
    //       message: "err.message",
    //       options: { variant: "error" }
    //     })
    //   )
    yield put(registerFail(err));
  }
}

export function* emailVerifyRequestHandler({ payload }) {
  const params = {
    url: "/accounts/registration/verify-email",
    method: "post",
    data: payload
  };

  try {
    const res = yield call(axios.request, params);
    yield put(emailVerifySuccess(res));
  } catch (err) {
    yield put(emailVerifyFail(payload));
  }
}

export function* profileGetRequestHandler() {
  const { Authorization } = getHeaders();
  if (!Authorization) {
    return;
  }
  const params = {
    url: "/accounts/user/",
    method: "get",
    headers: getHeaders()
  };

  try {
    const res = yield call(axios.request, params);
    yield put(profileGetSuccess(res.data));
  } catch (err) {
    yield put(profileGetFail(err.response));
  }
}

export function* updateProfileRequestHandler({ payload }) {
  const params = {
    url: "/accounts/user/",
    method: "put",
    data: payload,
    headers: getHeaders()
  };

  try {
    const res = yield call(axios.request, params);
    yield put(profileUpdateSuccess(res.data));
    yield put(
      enqueueSnackbar({
        message: "Update Success",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(profileUpdateFail(err.response));
  }
}

export function* updatePartialProfileRequestHandler({ payload }) {
  const state = yield select();
  const auth = state.auth;
  const uid = auth.loggedInUser.uid;

  try {
    yield call(
      rsf.firestore.updateDocument,
      `users/${uid}`,
      "firstName",
      payload.first_name,
      "lastName",
      payload.last_name
    );
    yield put(
      profileUpdateSuccess({
        ...auth.loggedInUser,
        ...payload
      })
    );
    yield put(
      enqueueSnackbar({
        message: "Update Success",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(profileUpdateFail(err.response));
  }
}

export function* changePasswordRequestHandler({ payload }) {
  try {
    const res = yield call(rsf.auth.updatePassword, payload.new_password1);
    yield put(passwordChangeSuccess(res));
    yield put(
      enqueueSnackbar({
        message: "Your password has been changed successfully",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(passwordChangeFail(err.response));
    yield put(
      enqueueSnackbar({
        message: "Change Password Failed, Make sure the passwords match",
        options: { variant: "error" }
      })
    );
  }
}

export function* resetPasswordRequestHandler({ payload }) {
  try {
    const actionCodeSettings = {
      url: "http://localhost:3000",
      handleCodeInApp: true
    };

    yield call(
      rsf.auth.sendPasswordResetEmail,
      payload.email,
      actionCodeSettings
    );
    yield put(passwordResetSuccess());
    yield put(
      enqueueSnackbar({
        message: "Request Reset Password Success",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(passwordResetFail(err.response));
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: { variant: "error" }
      })
    );
  }
}

export function* resetPasswordConfirmRequestHandler({ payload }) {
  try {
    const res = yield call(rsf.auth.confirmPasswordReset, payload);
    yield put(passwordResetConfirmSuccess(res.data));
    yield put(
      enqueueSnackbar({
        message: "Reset Password Success",
        options: { variant: "success" }
      })
    );
  } catch (err) {
    yield put(passwordResetConfirmFail(err.response));
    yield put(
      enqueueSnackbar({
        message: "Reset Password Failed",
        options: { variant: "error" }
      })
    );
  }
}

// function* detectApprovement() {
//   const state = yield select();
//   const cUser = state.auth.loggedInUser;
//   yield fork(rsf.firestore.syncDocument, `users/${cUser.uid}`, {
//     successActionCreator: stateSuccess,
//     failureActionCreator: stateFail,
//     transform: transformer
//   });
// }

const transformer = user => statusList[parseInt(user.data().status)];

export default function* authSaga() {
  yield takeLatest(CHECK_AUTHORIZATION, checkAuthorizationHandler);
  yield takeLatest(LOGIN_REQUEST, loginRequestHandler);
  yield takeLatest(LOGOUT_REQUEST, logoutRequestHandler);
  yield takeLatest(REGISTER_REQUEST, registerRequestHandler);
  yield takeLatest(EMAIL_VERIFY_REQUEST, emailVerifyRequestHandler);
  yield takeLatest(PROFILE_GET_REQUEST, profileGetRequestHandler);
  yield takeLatest(PROFILE_UPDATE_REQUEST, updateProfileRequestHandler);
  yield takeLatest(
    PROFILE_PARTIAL_UPDATE_REQUEST,
    updatePartialProfileRequestHandler
  );
  yield takeLatest(PASSWORD_CHANGE_REQUEST, changePasswordRequestHandler);
  yield takeLatest(PASSWORD_RESET_REQUEST, resetPasswordRequestHandler);
  yield takeLatest(
    PASSWORD_RESET_CONFIRM_REQUEST,
    resetPasswordConfirmRequestHandler
  );
}
