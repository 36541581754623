import firebase from "firebase";
import "firebase/functions";
import { push } from "react-router-redux";
import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  getCheckoutPreviewFail,
  getCheckoutPreviewSuccess,
  saveTokenFail,
  saveTokenSuccess
} from "../actions/checkout";
// import { stateUpdateRequest } from "../actions/state";
import {
  GET_CHECKOUT_PREVIEW_REQUEST,
  SAVE_TOKEN_REQUEST,
  MOVE_TO_SUPPORT_REQUEST
} from "../constants/checkout";
import { db, rsf } from "../rsf";
import { roomDetailSuccess, roomBookingRequest } from "../actions/room";

function* getCheckoutPreviewRequestHandler(payload) {
  const state = yield select();
  const selectRoomId = payload.payload;
  const wishRoomList = yield state.room.wishRoomList;
  const wishList = yield state.room.wishlist;
  const cUser = state.auth.loggedInUser;
  let idx = -1;
  if (selectRoomId !== null) {
    wishList &&
      wishList.map((roomId, index) => {
        if (selectRoomId === roomId) {
          idx = index;
        }
        return idx;
      });
  }
  // //console.log("idx", idx, selectRoomId, wishList, wishRoomList, state);

  try {
    const detail = {
      id: wishList[idx],
      data: wishRoomList[idx].data,
      selected: true
    };
    const booking = yield db.collection("booking").get();
    booking.forEach(item => {
      if (item.data().room_id === detail.id)
        db.collection("booking")
          .doc(item.id)
          .delete();
    });
    yield db.collection("booking").add({
      room_id: detail.id
    });
    let roomItem = yield db
      .collection("rooms")
      .doc(detail.id)
      .get();
    roomItem = roomItem.data();
    roomItem.data.booked = true;
    yield db
      .collection("rooms")
      .doc(detail.id)
      .set(roomItem);
    const user = yield db
      .collection("users")
      .doc(cUser.uid)
      .get();
    const userInfo = user.data();
    let startTime = userInfo.startTime;
    if (startTime == undefined || startTime == 0) {
      startTime = yield firebase.firestore.FieldValue.serverTimestamp();
      yield db
        .collection("users")
        .doc(cUser.uid)
        .update({
          startTime: startTime
        });
      startTime = -2;
    }
    yield put(getCheckoutPreviewSuccess({ startTime }));
    yield put(roomDetailSuccess(detail));
  } catch (e) {
    // //console.log(e);
    yield put(getCheckoutPreviewFail());
  }
}

function* saveTokenRequestHandler(payload) {
  const state = yield select();
  // //console.log('state :', state.application);
  const cUser = state.auth.loggedInUser;
  var roomData = null;
  var userData = null;
  if (state.room.detail) {
    roomData = state.room.detail;
  } else {
    userData = yield db
      .collection("users")
      .doc(cUser.uid)
      .get();
    let roomItem = yield db
      .collection("rooms")
      .doc(userData.data().selectRoomId)
      .get();
    roomData = roomItem.data();
  }
  console.log("userData", roomData);
  try {
    payload = payload.payload;

    const charges = {
      token: payload.token.token,
      amount: roomData.data.price,
      sign: payload.sign,
      bookedRoom: roomData.id ? roomData.id : userData.data().selectRoomId
      // startingDate: state.application.detail.datesPreference,
      // contractDuration: state.application.detail.lengthPreference
    };

    const signature = {
      sign: payload.sign
    };

    //send signature

    const sigRef = yield call(
      rsf.firestore.addDocument,
      `users/${cUser.uid}/contract`,
      signature
    );

    const docRef = yield call(
      rsf.firestore.addDocument,
      `users/${cUser.uid}/charges`,
      charges
    );

    var charge;
    if (state.state.selectedStatus === "Booking")
      charge = yield firebase
        .functions()
        .httpsCallable("createStripeSubscription");
    else if (state.state.selectedStatus === "Payment")
      charge = yield firebase.functions().httpsCallable("attemptStripeCharge");

    const request = {
      uid: cUser.uid,
      id: docRef.id,
      data: charges
    };
    const response = yield charge(request);
    // yield put(roomBookingRequest(roomData.id));
    yield put(saveTokenSuccess());
    console.log("response", response);
    //   if (response.status) yield put(push("/end"));
    // }

    // if (response.data.status === "active") {
    // yield db
    //   .collection("users")
    //   .doc(cUser.uid)
    //   .update({
    //     user_charges: {
    //       hasFailed: false,
    //       errMessage: ""
    //     }
    //   });
    // yield put(stateUpdateRequest({ agreeTerm: true }));
    // } else if (response.data.error.charge === null) {
    // yield db
    //   .collection("users")
    //   .doc(cUser.uid)
    //   .update({
    //     user_charges: {
    //       hasFailed: true,
    //       errMessage: response.data.error.code
    //     }
    //   });

    // yield db
    //   .collection("users")
    //   .doc(cUser.uid)
    //   .collection("charges")
    //   .doc(docRef.id)
    //   .delete();
    //   let error = {};
    //   error.code = response.data.error.code;
    //   yield put(saveTokenFail(error));
    // }
  } catch (error) {
    yield put(roomBookingRequest(roomData.id));
    yield put(saveTokenFail(error));
  }
}

export function* moveToSupportRequestHandler() {
  const state = yield select();
  const detail = state.room.detail;
  const cUser = state.auth.loggedInUser;
  const startTime = 0;
  try {
    let roomItem = yield db
      .collection("rooms")
      .doc(detail.id)
      .get();
    roomItem = roomItem.data();
    roomItem.data.booked = false;
    yield db
      .collection("rooms")
      .doc(detail.id)
      .set(roomItem);

    yield db
      .collection("users")
      .doc(cUser.uid)
      .update({
        startTime: startTime
      });
    const booking = yield db.collection("booking").get();
    booking.forEach(item => {
      if (item.data().room_id === detail.id)
        db.collection("booking")
          .doc(item.id)
          .delete();
    });
    yield db
      .collection("users")
      .doc(cUser.uid)
      .update({
        //   startTime: -2,
        status: 4,
        selectRoomId: ""
      });
    yield put(push("/application/booking"));
  } catch (err) {
    yield put(getCheckoutPreviewSuccess(err));
  }
}

export default function* communitySaga() {
  yield takeLatest(
    GET_CHECKOUT_PREVIEW_REQUEST,
    getCheckoutPreviewRequestHandler
  );
  yield takeLatest(SAVE_TOKEN_REQUEST, saveTokenRequestHandler);
  yield takeLatest(MOVE_TO_SUPPORT_REQUEST, moveToSupportRequestHandler);
}
