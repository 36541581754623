import {
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAIL,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_FAIL,
  GET_MESSAGE_SUCCESS
} from "../constants/questions";

export function getMessageRequest(payload) {
  return {
    type: GET_MESSAGE_REQUEST
  };
}
export function getMessageSuccess(payload) {
  return {
    type: GET_MESSAGE_SUCCESS,
    payload
  };
}

export function getMessageFail(payload) {
  return {
    type: GET_MESSAGE_FAIL,
    payload
  };
}
export function addMessageRequest(payload) {
  return {
    type: ADD_MESSAGE_REQUEST,
    payload
  };
}

export function addMessageSuccess(payload) {
  return {
    type: ADD_MESSAGE_SUCCESS,
    payload
  };
}

export function addMessageFail(payload) {
  return {
    type: ADD_MESSAGE_FAIL,
    payload
  };
}
