import { put, takeLatest, select, call } from "redux-saga/effects";
import {
  ADD_MESSAGE_REQUEST,
  GET_MESSAGE_REQUEST
} from "../constants/questions";
import {
  addMessageSuccess,
  addMessageFail,
  getMessageSuccess,
  getMessageFail
} from "../actions/questions";
import { stateRequest } from "../actions/state";

import { db, rsf } from '../rsf';
import axios from "axios";


function* getMessageRequestHandler() {
  const state = yield select();
  const uid = state.auth.loggedInUser.uid;
  try {
    const qItem = yield db
      .collection("users")
      .doc(uid)
      .get();
    const mss = qItem.data().message;
    yield put(getMessageSuccess(mss));
  } catch (err) {
    yield put(getMessageFail(err.response));
  }
}

function* addMessageRequestHandler({ payload }) {
  const state = yield select();
  const status = 3;
  const uid = state.auth.loggedInUser.uid;
  try {
    yield db
      .collection("users")
      .doc(uid)
      .update({
        message: payload
      });
    yield put(addMessageSuccess(payload));
    yield db
      .collection("users")
      .doc(uid)
      .update({
        status: status
      });
    yield put(stateRequest());
  } catch (err) {
    // //console.log(err, 'question')
    yield put(addMessageFail(err.response));
  }
}

export default function* authSaga() {
  yield takeLatest(GET_MESSAGE_REQUEST, getMessageRequestHandler);
  yield takeLatest(ADD_MESSAGE_REQUEST, addMessageRequestHandler);
}
