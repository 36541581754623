export const STATE_REQUEST = 'STATE/STATE_REQUEST'
export const STATE_SUCCESS = 'STATE/STATE_SUCCESS'
export const STATE_FAIL = 'STATE/STATE_FAIL'

export const STATE_UPDATE_REQUEST = 'STATE/STATE_UPDATE_REQUEST'
export const STATE_UPDATE_SUCCESS = 'STATE/STATE_UPDATE_SUCCESS'
export const STATE_UPDATE_FAIL = 'STATE/STATE_UPDATE_FAIL'

export const SET_SELECTED_STATE = 'STATE/SET_SELECTED_STATE';

export const STATE_TITLE_SET_SUCCESS = 'STATE/STATE_TITLE_SET_SUCCESS'


export const GET_TITLES_REQUEST = "STATE/GET_TITLES_REQUEST";
export const GET_TITLES_SUCCESS = "STATE/GET_TITLES_SUCCESS";
export const GET_TITLES_FAIL = "STATE/GET_TITLES_FAIL";