import React from "react";
import { connect } from "react-redux";
import { Button, withStyles } from "@material-ui/core";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {
  applicationDetailRequest,
  applicationUpdateRequest,
  genderPreferenceListRequest,
  universityListRequest,
  semesterListRequest,
  datesListRequest,
  lengthListRequest
} from "../../actions/application";
import ErrorMessage from "../../components/ErrorMessage";
import { profilePartialUpdateRequest } from "../../actions/auth";

import NameForm from "./NameForm";
import UniversityForm from "./UniversityForm";
import GenderPreferenceForm from "./GenderPreference";
import DatesForm from "./moveinDate";
import LengthForm from "./lengthStay";
import BudgetForm from "./BudgetForm";
import SemesterForm from "./SemesterForm";
import rightArrow from "../../assets/SVG/right_arrow.svg";
import leftArrow from "../../assets/SVG/left_arrow.svg";
import { step1ErrorMessages } from "../../constants/step1Error";
import "./style.scss";

const styles = theme => ({
  buttonsWrapper: {
    padding: theme.spacing(4)
  },
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    margin: "0 5px",
    minWidth: "135px"
  }
});

class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      age: 0,
      semesters: Array(4).fill(null),
      university: "",
      gender: "male",
      country: "",
      genderPreference: "",
      datesPreference: "",
      lengthPreference: "",
      min_budget: "",
      max_budget: "",
      message: "",
      page: 1,
      start_at: "",
      end_at: "",
      errorInput: false
    };
  }

  componentDidMount() {
    // this.props.applicationDetailRequest();
    this.props.genderPreferenceListRequest();
    this.props.datesListRequest();
    this.props.lengthListRequest();
    this.props.semesterListRequest();
  }

  componentWillReceiveProps(nextProps) {
    const { detail, user } = nextProps;

    if (detail && this.state.page === 1) {
      const {
        age,
        gender,
        country,
        genderPreference,
        datesPreference,
        lengthPreference,
        university,
        min_budget,
        max_budget,
        start_at,
        end_at
      } = detail;
      this.setState({
        age,
        gender,
        country,
        genderPreference,
        datesPreference,
        lengthPreference,
        university,
        min_budget,
        max_budget,
        start_at,
        end_at
      });
    }
    if (user && this.state.page === 1) {
      const { first_name, last_name } = user;
      this.setState({
        firstName: first_name,
        lastName: last_name
      });
    }
  }

  // POST request debug
  handleFormInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: name === "age" ? (value ? parseInt(value) : 0) : value
    });
  };

  handleGenderChange = gender => {
    this.setState({
      gender: gender.target.value
    });
  };

  submitForm = () => {
    const {
      profilePartialUpdateRequest,
      applicationUpdateRequest
    } = this.props;
    const { firstName, lastName } = this.state;

    profilePartialUpdateRequest({
      first_name: firstName,
      last_name: lastName
    });

    const { semesters } = this.state;
    let dates = [];
    semesters.forEach(semester => {
      if (semester) {
        dates.push(semester.start_at);
        dates.push(semester.end_at);
      }
    });

    dates.sort((a, b) => new Date(a) - new Date(b));

    let {
      age,
      gender,
      country,
      genderPreference,
      datesPreference,
      lengthPreference,
      university,
      min_budget,
      max_budget,
      start_at,
      end_at
    } = this.state;

    const payload = {
      age: age,
      gender: gender,
      country: country,
      genderPreference: genderPreference,
      datesPreference: datesPreference,
      lengthPreference: lengthPreference,
      university: university,
      min_budget: min_budget,
      max_budget: max_budget,
      start_at: start_at,
      end_at: end_at
      // semesters
    };

    applicationUpdateRequest(payload);
    window.amplitude.getInstance().logEvent("INFO_COMPLETE");
    var metadata = {
      age: payload["age"],
      gender: payload["gender"],
      country: payload["country"],
      genderPreference: payload["genderPreference"],
      datesPreference: payload["datesPreference"],
      lengthPreference: payload["lengthPreference"],
      university: payload["university"],
      min_budget: payload["min_budget"].toString(),
      max_budget: payload["max_budget"].toString()
    };
    window.Intercom("trackEvent", "info-complete", metadata);
  };

  nextPage = () => {
    const {
      firstName,
      lastName,
      age,
      gender,
      country,
      page,
      genderPreference,
      datesPreference,
      lengthPreference,
      university
    } = this.state;

    if (page === 1) {
      if (
        firstName === "" ||
        lastName === "" ||
        age < 1 ||
        gender === "" ||
        country === ""
      ) {
        this.setState({ errorInput: true });
        return;
      } else this.setState({ page: this.state.page + 1, errorInput: false });
    } else if (
      (page === 3 && genderPreference === undefined) ||
      (page === 4 && datesPreference === undefined) ||
      (page === 5 && lengthPreference === undefined) ||
      (page === 6 && university === "")
    ) {
      this.setState({ errorInput: true });
      return;
    }
    if (page === 6) {
      this.submitForm();
      return;
    }
    this.setState({ page: this.state.page + 1, errorInput: false });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  onSemesterChange = (item, index) => {
    const semesters = this.state.semesters;
    semesters[index] = item;

    this.setState({ semesters });
  };

  onChangeStartingDate = date => this.setState({ start_at: date });
  onChangeEndDate = date => this.setState({ end_at: date });

  render() {
    const {
      classes,
      universityList,
      genderPreferenceList,
      datesList,
      lengthList,
      semesterList
    } = this.props;
    const {
      firstName,
      lastName,
      age,
      gender,
      country,
      genderPreference,
      datesPreference,
      lengthPreference,
      semesters: selectedSemesters,
      university,
      min_budget,
      max_budget,
      page,
      start_at,
      end_at,
      errorInput
    } = this.state;
    return (
      <div className="content">
        <div className="main" style={{ padding: "0 20px" }}>
          <div className="form">
            {page === 1 && (
              <NameForm
                firstName={firstName}
                lastName={lastName}
                age={age}
                gender={gender}
                country={country}
                handleInput={this.handleFormInput}
                handleChange={this.handleGenderChange}
                onNext={this.nextPage}
              />
            )}
            {page === 2 && (
              <BudgetForm
                min={min_budget}
                max={max_budget}
                onBudgetChange={v =>
                  this.setState({ min_budget: v.min, max_budget: v.max })
                }
                onNext={this.nextPage}
                onPrevious={this.previousPage}
              />
            )}
            {page === 3 && (
              <GenderPreferenceForm
                genderPreferenceList={genderPreferenceList}
                curPreference={genderPreference}
                onSelectGenderPreference={genderPreference =>
                  this.setState({ genderPreference })
                }
                onNext={this.nextPage}
                onPrevious={this.previousPage}
              />
            )}
            {page === 4 && (
              <DatesForm
                datesList={datesList}
                curDate={datesPreference}
                onSelectDates={datesPreference =>
                  this.setState({ datesPreference })
                }
                onNext={this.nextPage}
                onPrevious={this.previousPage}
              />
            )}
            {page === 5 && (
              <LengthForm
                lengthList={lengthList}
                curLength={lengthPreference}
                onSelectLength={lengthPreference =>
                  this.setState({ lengthPreference })
                }
                onNext={this.nextPage}
                onPrevious={this.previousPage}
              />
            )}
            {page === 6 && (
              <UniversityForm
                universityList={universityList}
                curUniversity={university}
                onSelectUniversity={university => this.setState({ university })}
                onNext={this.nextPage}
                onPrevious={this.previousPage}
              />
            )}
            {errorInput && (
              <ErrorMessage msg={step1ErrorMessages[page - 1].title} />
            )}
            <div className="form__button__container">
              {this.state.page > 1 && (
                <Button className={classes.button} onClick={this.previousPage}>
                  <img
                    src={leftArrow}
                    className="form__arrow form__arrow--left"
                    alt="leftArrow"
                  />
                  <span className="form__nextButton__text form__nextButton__text--left">
                    Previous
                  </span>
                </Button>
              )}
              <Button className={classes.button} onClick={this.nextPage}>
                <span className="form__nextButton__text form__nextButton__text--right">
                  {this.state.page === 6
                    ? "Complete"
                    : "Next " + this.state.page + "/6"}
                </span>
                <img
                  src={rightArrow}
                  className="form__arrow form__arrow--right"
                  alt="rightArrow"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  application,
  gender,
  dates,
  length,
  university,
  semester
}) => ({
  detail: application.detail,
  user: auth.loggedInUser,
  genderPreferenceList: gender.list,
  datesList: dates.list,
  lengthList: length.list,
  universityList: university.list,

  semesterList: semester.list
});

const mapDispatchToProps = {
  applicationDetailRequest,
  applicationUpdateRequest,
  genderPreferenceListRequest,
  datesListRequest,
  lengthListRequest,
  universityListRequest,
  semesterListRequest,
  profilePartialUpdateRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Application));
