import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withStyles, Button } from "@material-ui/core";
import TitleText from "../../components/TitleText";
import { statusList, titles } from "../../constants/user_status";
import { Helmet } from "react-helmet";

import timeLeft from "../../assets/SVG/time-left.svg";
// import arrow from "../../assets/SVG/right_arrow_orange.svg";
import rocket from "../../assets/SVG/rocket.svg";
import { communityListRequest } from "../../actions/community";
import { getStartBookingRequest } from "../../actions/end";

import "./dashboard.scss";

const styles = {
  startButton: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "5px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff"
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upText: "",
      downText: {},
      goTomybooking: false
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-90692358-1");
    ReactGA.pageview(this.props.location.pathname);
    this.props.getStartBookingRequest();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startmybooking &&
      prevProps.startmybooking !== this.props.startmybooking
    ) {
      this.setState({
        goTomybooking: true
      });
    }
  }

  startApplication = () => {
    const { communityListRequest } = this.props;
    communityListRequest();
    if (this.state.goTomybooking) this.props.history.push("/myBooking");
    else this.props.history.push("/application");
  };

  render() {
    const { user, classes, state } = this.props;
    const { userStatus } = state;
    const index = userStatus && statusList.indexOf(userStatus);
    let upText;
    if (index > 0) {
      upText =
        userStatus === null || userStatus === "Init"
          ? "Under consideration"
          : statusList[index - 1] + " completed";
    } else upText = "Not Started";
    const tInx = index == null ? 0 : index;
    const downText = titles[tInx];
    const welcomeText =
      user.first_name === ""
        ? "Hello Future Member"
        : "Hello, " + user.first_name + "!";

    return (
      <div className="content">
        <Helmet>
          <title>Cubahaus | Book </title>
        </Helmet>

        <div className="dashboard">
          <TitleText>{welcomeText}</TitleText>
          <p className="text narrow">Your Application Status</p>
          <div className="verificationStatus">
            <img src={timeLeft} className="timer" alt="timer" />
            <span className="statusText">{upText && upText}</span>
          </div>
          {/* <div className="more">
            <span className="moreText">More</span>
            <img src={arrow} className="arrow" alt="arrow" />
          </div> */}
          <span className="welcomeText">{downText && downText.title}</span>
          <div className="description">
            <p className="text wide">{downText && downText.contents}</p>
          </div>
          <Button
            onClick={this.startApplication}
            //  className="startBtn"
            className={classes.startButton}
          >
            <img src={rocket} className="rocketImage" alt="rocket" />

            <span className="applicationButtonText">
              {" "}
              {downText && downText.button}
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, state, community, end }) => ({
  ...community,
  user: auth.loggedInUser,
  state: state,
  startmybooking: end.startmybooking
});

const mapDispatchToProps = {
  communityListRequest,
  getStartBookingRequest
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
