import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import StepConnector from "@material-ui/core/StepConnector";
import {
  DocumentIcon,
  BillIcon,
  Money1Icon,
  // CubahausIcon,
  Money2Icon
} from "../../components/Icon";
import "./style.scss";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#286ff9"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#784af4"
    }
  },
  line: {
    borderColor: "#c1cbf9",
    borderTopWidth: 1,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#286ff9",
    borderRadius: "50%",
    padding: 4,
    border: "solid 1px #286ff9",
    height: 14,
    marginLeft: -5,
    marginBottom: 20,
    marginTop: 10
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "#286ff9"
  },
  completed: {
    color: "#ffffff",
    zIndex: 1,
    fontSize: 18,
    borderRadius: "50%",
    backgroundColor: "#286ff9",
    padding: 3,
    marginLeft: -6,
    marginBottom: 8
  },
  fail: {
    color: "#ffffff",
    zIndex: 1,
    fontSize: 18,
    borderRadius: "50%",
    backgroundColor: "#eb5757",
    padding: 3,
    marginLeft: -6,
    marginBottom: 8
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  //console.log("fail", fail);
  var fail = false;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active && !fail
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : fail && active ? (
        <Close className={classes.fail} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  fail: PropTypes.bool
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  stepper: {
    backgroundColor: "transparent"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return [
    "Submit your signature and payment",
    "Pay for the first month of stay",
    "Rent payment ",
    "Rent payment ",
    "REnt payment "
  ];
}

export default function BookingStepper({ roomInfo }) {
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps();

  const failed = true;

  return (
    <div className={classes.root}>
      <Stepper
        orientation="vertical"
        activeStep={2}
        connector={<QontoConnector />}
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              {index === 0 && (
                <div className="stepItem">
                  <DocumentIcon alt="" />
                  <p> {label}</p>
                </div>
              )}
              {index === 1 && (
                <div className="stepItem">
                  <BillIcon alt="" />
                  <p> {label}</p>
                </div>
              )}
              {index === 3 && (
                <div className="stepItem1">
                  <span>April 1st</span>
                  <Money2Icon alt="" />
                  <p>
                    {" "}
                    {label}
                    <span>${roomInfo.data.price}</span>
                  </p>
                </div>
              )}
              {index === 4 && (
                <div className="stepItem1">
                  <span>March 1st</span>
                  <Money2Icon alt="" />
                  <p>
                    {" "}
                    {label}
                    <span>${roomInfo.data.price}</span>
                  </p>
                </div>
              )}
              {index === 2 && !failed && (
                <div className="stepItem2">
                  <span>February 1st</span>
                  <Money1Icon alt="" />
                  <div>
                    <span>
                      {label}
                      <span style={{ color: "#286ff9" }}>
                        ${roomInfo.data.price}
                      </span>
                    </span>
                    <p>
                      When you submit the payment right now, you will be charged
                      for your first month of stay
                    </p>
                  </div>
                </div>
              )}
              {index === 2 && failed && (
                <div className="stepItem3">
                  <span>February 1st</span>
                  <Money1Icon alt="" />
                  <div>
                    <span>
                      {label}
                      <span style={{ color: "#286ff9" }}>
                        ${roomInfo.data.price}
                      </span>
                    </span>
                    <p>
                      Your payment failed.
                      <br />
                      Please contact your financial insitution, and then{" "}
                      <span>re-enter your payment information</span>
                    </p>
                  </div>
                </div>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
