import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Grid,
  Tabs,
  Tab,
  withStyles,
  IconButton
} from "@material-ui/core";

import Slider from "react-slick";
import FavoriteIcon from "@material-ui/icons/Favorite";

import {
  roomDetailRequest,
  roomSelectRequest,
  wishListComplete
} from "../../actions/room";

import { saveSelectRoomRequest } from "../../actions/booking";
import { TABS } from "../../constants/communityText";
import BackButton from "../../components/BackButton";
import TitleText from "../../components/TitleText";
// import Button from "../../components/ButtonContainer";

import Common from "./Common";
import Amenities from "./Amenities";
import Kitchen from "./Kitchen";
import Description from "./Description";
import Bedroom from "./Bedroom";
import Bathroom from "./Bathroom";
import SelectRoom from "../../components/SelectRoom";

import {
  IconHeart,
  IconClose,
  IconLeft1,
  IconInfo,
  IconBed,
  IconLeft,
  IconRight
} from "../../components/Icon";
import roomTest from "../../assets/rooms/2.jpg";
import leftArrow from "../../assets/SVG/left_arrow_orange.svg";

import {
  IconTag,
  IconGroup,
  IconWashingMachine,
  IconHome
} from "../../components/Icon";

import "./styles.scss";

const styles = theme => ({
  text: {
    fontFamily: "Montserrat",
    fontSize: "21px",
    fontWeight: "500",
    lineHeight: "2.94",
    letterSpacing: "0.53px",
    textAlign: "left",
    color: "#4c5788",
    textTransform: "none"
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "13px",
    padding: "14px",
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "50%",
    boxShadow: "0 0 8px 3px rgba(208, 124, 29, 0.3)",
    fill: "#ffffff"
  },
  unSelectButton: {
    backgroundColor: "#dcdcdc",
    borderRadius: "10px",
    textAlign: "left",
    fontSize: "18px",
    textTransform: "initial",
    padding: "30px 15px 25px 20px",
    boxShadow: "rgba(247, 247, 247, 0.39) 0px 0px 40px 3px",
    color: "#ffffff",
    lineHeight: "1.21",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  },
  selectButton: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "10px",
    fontSize: "18px",
    textAlign: "left",
    textTransform: "initial",
    padding: "30px 40px 25px 20px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    lineHeight: "1.21",
    color: "#ffffff",
    width: "100%",
    justifyContent: "flex-start",
    margin: "30px 0"
  },
  likeIcon: {
    width: "24px",
    height: "21px",
    fill: "#ffffff",
    marginRight: "5px"
  },
  likeButton: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "10px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff"
  },
  tabsContainer: {
    justifyContent: "space-between"
  },
  root: {
    width: "20%"
    //   boxShadow: "0 0 47.7px 0px rgba(210, 215, 255, 0.43)",
    // borderRadius: "10px 10px 0 0"
    //    backgroundImage: "linear-gradient(180deg, #f1f1f1, #fcfcfd 99%)"
  },
  label: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.9,
    textAlign: "left",
    textTransform: "none"
  },
  selected: {
    //  boxShadow: "0 0 78.1px 5.9px rgba(37, 60, 158, 0.24)",
    //   backgroundImage: "linear-gradient(357deg, #4868ff, #0000ff 99%)",
    color: "#4868ff !important"
  },
  textColor: {
    color: "#4b5688",
    opacity: 1
  },
  icon2: {
    width: "36px",
    height: "36px",
    fill: "#f1b143",
    position: "absolute",
    top: 0,
    right: "15%",
    padding: "5px",
    border: "solid 1px #e4e7f6",
    backgroundColor: "#ffffff",
    borderRadius: "50%"
  },
  icon1: {
    width: "25px",
    height: "25px",
    fill: "#f1b143",
    position: "absolute",
    top: 0,
    left: "15%",
    padding: "5px",
    border: "solid 1px #e4e7f6",
    backgroundColor: "#ffffff",
    borderRadius: "50%"
  },
  wrapper: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    fontFamily: "Montserrat"
  }
});

const tabs = TABS;
class RoomDetail extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      tabIndex: 0,
      acceptDialog: false
    };
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentDidMount() {
    this._isMounted = true;
    const { params } = this.props.match;
    const { id } = params;
    // this.props.roomDetailRequest(id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentWillMount() {
    this.updateScreenWidth();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  handleTabChange = (e, index) => {
    this.setState({
      tabIndex: index
    });
  };

  selectRoom = () => {
    this.setState({
      acceptDialog: true
    });
  };

  onClose = () => {
    this.setState({
      acceptDialog: false
    });
  };

  onBack = () => {
    this.setState({
      acceptDialog: false
    });
    this.props.history.push("/application/booking");
  };

  onSelect = () => {
    this.props.saveSelectRoomRequest(this.props.location.state.index);
  };

  onClickBack = () => {
    this.props.onBack();
  };

  render() {
    const {
      classes,
      room,
      history,
      wishlist,
      wishRoomList,
      match,
      location,
      onBack
    } = this.props;

    const { tabIndex, width } = this.state;
    var common,
      // noise,
      amenities,
      house,
      bathroom,
      bedroom,
      kitchen,
      images,
      room_name,
      price,
      roomdata;

    roomdata = this.props.roomData.data;
    common = roomdata.common;
    // noise,
    amenities = roomdata.amenities;
    bedroom = roomdata.bedroom;
    house = roomdata.house;
    bathroom = roomdata.bathroom;
    kitchen = roomdata.kitchen;
    images = roomdata.images;
    room_name = roomdata.room_name;
    price = roomdata.price;

    // if (this.props.location.state) {
    //   roomdata = this.props.location.state.roomData;
    //   userAInfo = this.props.location.state.userAInfo;
    //   common = roomdata.common;
    //   // noise,
    //   amenities = roomdata.amenities;
    //   house = roomdata.house;
    //   bathroom = roomdata.bathroom;
    //   kitchen = roomdata.kitchen;
    //   images = roomdata.images;
    //   room_name = roomdata.room_name;
    //   price = roomdata.price;
    // } else {
    // }
    const isShared =
      roomdata.is_shared == true ? "Shared Bedroom" : "Private Bedroom";

    return (
      <div className="content">
        <BackButton onClick={this.onClickBack} />
        <div
          className="roomdetail__title"
          style={
            width < 800
              ? { marginTop: "200px", marginBottom: "50px", marginTop: "0px" }
              : null
          }
        >
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "30px",
              fontWeight: "bold",
              textSlign: "center",
              letterSpacing: "0.8px",
              color: "#4c5788"
            }}
          >
            <span
              style={{
                borderLeft: "solid 5px #4c5788",
                borderRadius: "10px",
                marginRight: "10px"
              }}
            ></span>
            {room_name}
          </p>
        </div>
        <div
          className="roomdetail position-relative"
          style={
            width < 500 ? { padding: "50px 20px", marginBottom: "50px" } : null
          }
        >
          <Grid
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={12} className="roomdetail__info__body">
              <div
                className="roomdetail__info__slider"
                style={width < 500 ? { marginTop: "-40px" } : null}
              >
                <Slider
                  dots={true}
                  prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
                  nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
                >
                  {images &&
                    images.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={image.image_title}
                          style={width < 500 ? { height: "200px" } : null}
                        />
                      </div>
                    ))}
                </Slider>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div
                className="roomdetail__info"
                style={width < 500 ? { padding: "0" } : null}
              >
                {/* <h2>{room_name}</h2> */}
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <div className="roomdetail__info--item"></div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div className="roomdetail__info--item">
                      <IconTag className="icon" />
                      <p>Price: ${price}</p>
                      <br />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <div className="roomdetail__info--item">
                      <IconWashingMachine className="icon" />
                      <div>
                        <p>House name</p>
                        <span>{house.house_name}</span>
                      </div>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} md={3}>
                    <div className="roomdetail__info--item">
                      <IconBed className="icon" />
                      <div>
                        <p>Bedroom </p>
                        <span>{isShared}</span>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <div className="roomdetail__info--item">
                      <IconGroup className="icon" />
                      <div>
                        <p>Number of people</p>
                        <span>{house.house_capacity}</span>
                      </div>
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div
            className="roomdetail__title1"
            style={width < 800 ? { margin: "20px 0" } : null}
          >
            <TitleText>Rooms Descriptions</TitleText>
          </div>
          <div className="roomdetail__tab">
            <Tabs
              indicatorColor="primary"
              value={tabIndex}
              onChange={this.handleTabChange}
              classes={{ flexContainer: classes.tabsContainer }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.title}
                  classes={{
                    root: classes.root,
                    textColorInherit: classes.textColor,
                    label: classes.label,
                    selected: classes.selected,
                    wrapper: classes.wrapper
                  }}
                />
              ))}
            </Tabs>
          </div>
          <div className="roomdetail__description">
            {tabIndex === 4 && common && (
              <Common common={common} width={width} />
            )}
            {tabIndex === 0 && amenities && <Amenities amenities={amenities} />}
            {tabIndex === 2 && kitchen && (
              <Kitchen kitchen={kitchen} width={width} />
            )}
            {tabIndex === 3 && bathroom && (
              <Bathroom bathroom={bathroom} width={width} />
            )}
            {tabIndex === 1 && bedroom && (
              <Bedroom bedroom={bedroom} width={width} />
            )}
            {/* {tabIndex !== 4 && (
                <div className="roomdetail__description--extra">
                  <p>Extra notes:</p>
                  {/* {noise && (
                  <span>
                    Noise: Level {noise.noise_level} of 4. (
                    {noise.noise_description})
                  </span>
                )} 
                </div>
              )} */}
          </div>
          {/* <div className="row favoriteButtonList ">
            <div className="col-4 position-relative">
              <Button type="gradient" onClick={ this.goToQuestion } className={active}>
                <IconHeart alt="" className={classes.likeIcon} />
                Go to the
                <br />
                next step
                <span className="favoriteButton">{wishlist}/5</span>
              </Button>
            </div>
            
          </div> */}
        </div>
        {/* <div className="roomdetail__button__container">
          <Button
            type="gradient"
            onClick={this.selectRoom}
            className={classes.likeButton}
          >
            Select Room
          </Button>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = ({ room }) => ({
  room: room.detail,
  wishlist: room.wishlist ? room.wishlist.length : 0,
  wishRoomList: room.wishRoomList
});

const mapDispatchToProps = {
  roomDetailRequest,
  roomSelectRequest,
  wishListComplete,
  saveSelectRoomRequest
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(RoomDetail))
);
