import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import CheckIcon from "../../components/CheckIcon";
import IncludeText from "../../components/IncludeText";
import DescriptiveText from "../../components/DescriptiveText";
import { IconChecked } from "../../components/Icon";
import { IconLeft, IconRight } from "../../components/Icon";
import Slider from "react-slick";

const styles = theme => ({
  root: {
    width: "310px",
    margin: "auto"
  }
});

// const Kitchen = ({ kitchen, width }) => {
class Kitchen extends React.Component {
  render() {
    const { width, kitchen, classes } = this.props;

    const {
      coffee_maker,
      dish_washer,
      dished_and_utensiles,
      microwave,
      oven_stove,
      pots_and_pans,
      refrigerator,
      kitchen_will_be_shared_with,
      images
    } = kitchen;

    return (
      <div className="roomdetail__description__amenities">
        <Grid container spacing={24} justify="space-evenly">
          <Grid item xs={10} md={4}>
            <div className="roomdetail__description__amenities--item">
              <div>
                <p>Kitchen is Shared with</p>
                <DescriptiveText included={kitchen_will_be_shared_with} />
              </div>
            </div>

            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={pots_and_pans} />
              <div>
                <p>Pots and pans</p>
              </div>
            </div>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={oven_stove} />
              <div>
                <p>Oven</p>
              </div>
            </div>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={refrigerator} />
              <div>
                <p>Refrigerator</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={microwave} />
              <div>
                <p>Microwave</p>
              </div>
            </div>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={coffee_maker} />
              <div>
                <p>Coffee maker</p>
              </div>
            </div>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={dished_and_utensiles} />
              <div>
                <p>Dishes and utensils</p>
              </div>
            </div>
            <div className="roomdetail__description__amenities--item">
              <CheckIcon checked={dish_washer} />
              <div>
                <p>Dishwasher</p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="roomdetail__description__description">
          <Slider
            dots={true}
            slidesToShow={width < 800 ? 1 : images.length >= 2 ? 3 : 1}
            slidesToScroll={1}
            prevArrow={<IconLeft alt="" style={{ fill: "#ea923e" }} />}
            nextArrow={<IconRight alt="" style={{ fill: "#ea923e" }} />}
            className={
              width < 800 ? null : images.length < 2 ? classes.root : null
            }
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={image.image_title}
                  style={
                    width < 800
                      ? { width: "220px", margin: "auto" }
                      : images.length < 3
                      ? { margin: "0 auto", marginTop: "10px" }
                      : null
                  }
                />
              </div>
            ))}
            {images.length <= 3 &&
              images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={image.image_title}
                    style={
                      width < 800
                        ? { width: "220px", margin: "auto" }
                        : images.length < 3
                        ? { margin: "0 auto", marginTop: "10px" }
                        : null
                    }
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Kitchen);
