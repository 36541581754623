import firebase from 'firebase'
import '@firebase/firestore'
import ReduxSagaFirebase from 'redux-saga-firebase';
import {firebaseConfig} from './config/firebase';

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const rsf = new ReduxSagaFirebase(firebaseApp)
export const db = firebaseApp.firestore()
export function* addUser(data) {
  try{
    yield db.collection('users').doc(data.docName).set({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      createdAt: firebase.firestore.Timestamp.now(),
      status: 0
    })
    yield db.collection('users').doc(data.docName).collection('application').doc('1').set({
      age: 0,
      start_at: new Date(),
      end_at: new Date(),
      max_budget: 2000,
      min_budget: 0,
      university: '',
      message: ''  
    })
  } catch(err) {
    //console.log('Error addings document: ', err)
  }
}



