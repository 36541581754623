import React from "react";
import { connect } from "react-redux";
import { Button, withStyles } from "@material-ui/core";
import StripeSection from "../../components/Stripe";
import { saveTokenRequest } from "../../actions/checkout";
import { PaymentIcon } from "../../components/Icon";
import "./style.scss";
const styles = theme => ({
  button: {
    backgroundImage: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)",
    borderRadius: "25px",
    fontSize: "18px",
    textTransform: "initial",
    padding: "5px 25px",
    boxShadow: "rgba(230, 129, 59, 0.5) 0px 0px 40px 1px",
    letterSpacing: "0.9px",
    color: "#ffffff",
    minWidth: "135px",
    maxWidth: "140px",
    alignSelf: "flex-end",
    textTransform: "capitalize"
  },
  moreButton: {
    padding: "6px 1px",
    minWidth: "unset"
  }
});

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutStep: false,
      signature: null,
      loading: false,
      open: false,
      selectedRoom: null
    };
  }

  checkoutRequest = token => {
    const { saveTokenRequest } = this.props;
    // const sign = this.signature();
    // if (sign == this.state.signature) return;
    const payload = {
      token: token,
      sign: this.state.signature
    };
    this.setState({
      loading: true
    });
    saveTokenRequest(payload);
  };

  render() {
    let { loading, open, signature, selectedRoom } = this.state;

    return (
      //TODO Update user to real user data
      <div className="content">
        <div
          className="checkout__step2 payment"
          style={{ marginBottom: "50px" }}
        >
          <p className="payment__text1">Something went wrong</p>
          <PaymentIcon alt="" className="payment__text1" />
          <p className="payment__text2">Your payment failed.</p>
          <p className="payment__text3">
            Please <span>contact your financial institution,</span> and then
            re-enter your payment information.
          </p>
          <p className="checkout__step1__title">Payment</p>
          <StripeSection
            checkoutRequest={this.checkoutRequest}
            loading={loading}
          />
          {/* <ErrorMessage msg={messageError} /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ application }) => ({
  application: application
});

const mapDispatchToProps = {
  saveTokenRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Payment));
