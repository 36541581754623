import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
import StepConnector from "@material-ui/core/StepConnector";
import {
  DocumentIcon,
  BillIcon,
  Money1Icon,
  CubahausIcon,
  Money2Icon
} from "../../components/Icon";
import "./styles.scss";
import { getSelectRoomRequest } from "../../actions/booking";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#286ff9"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#784af4"
    }
  },
  line: {
    borderColor: "#c1cbf9",
    borderTopWidth: 1,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#286ff9",
    borderRadius: "50%",
    padding: 4,
    border: "solid 1px #286ff9",
    height: 14,
    marginLeft: -5,
    marginBottom: 20,
    marginTop: 10
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "#286ff9"
  },
  completed: {
    color: "#ffffff",
    zIndex: 1,
    fontSize: 18,
    borderRadius: "50%",
    backgroundColor: "#286ff9",
    padding: 3,
    marginLeft: -6,
    marginBottom: 8
  },
  fail: {
    color: "#ffffff",
    zIndex: 1,
    fontSize: 18,
    borderRadius: "50%",
    backgroundColor: "#eb5757",
    padding: 3,
    marginLeft: -6,
    marginBottom: 8
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, fails } = props;

  //console.log("fail", fail);
  var fail = true;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  fail: PropTypes.bool
};

const styles = theme => ({
  root: {
    width: "100%"
  },
  stepper: {
    backgroundColor: "transparent"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

const steps = [
  "Submit your signature and payment",
  "Pay for the first month of stay",
  "Rent payment $",
  "Rent payment $",
  "REnt payment $"
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

class BookingStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1
    };
  }

  componentDidMount() {
    this.props.getSelectRoomRequest(); // selectRoomId road
  }

  render() {
    const { activeStep } = this.state;
    const { detail, classes } = this.props;
    const roomInfo = detail;

    var currentDate = new Date();
    var curMonth = currentDate.getMonth();

    return (
      <div className={classes.root}>
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          connector={<QontoConnector />}
          className={classes.stepper}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {/* {index === 0 && (
                <div className="stepItem">
                  <DocumentIcon alt="" />
                  <p> {label}</p>
                </div>
              )} */}
                {index === 2 && (
                  <div className="stepItem11">
                    <span>{months[curMonth + 1]} 1st</span>
                    <Money2Icon alt="" />
                    <p>
                      {label}
                      {roomInfo.data.price}
                    </p>
                  </div>
                )}
                {index === 3 && (
                  <div className="stepItem11">
                    <span>{months[curMonth + 2]} 1st</span>
                    <Money2Icon alt="" />
                    <p>
                      {label}
                      {roomInfo.data.price}
                    </p>
                  </div>
                )}
                {index === 4 && (
                  <div className="stepItem11">
                    <span>{months[curMonth + 3]} 1st</span>
                    <Money2Icon alt="" />
                    <p>
                      {label}
                      {roomInfo.data.price}
                    </p>
                  </div>
                )}
                {index === 1 && (
                  <div className="stepItem21">
                    <span>Today</span>
                    <BillIcon alt="" />
                    <div>
                      <span>{label}</span>
                      <div className="stepItem21__card">
                        <p>You will Pay  </p>
                        <p>
                          {/* <span style={{ textDecoration: "line-through" }}>
                            ${roomInfo.data.price}
                          </span> */}
                          &nbsp;&nbsp;
                          <span>
                            <b>${roomInfo.data.price}</b>
                          </span>
                          {/* &nbsp;&nbsp;today */}
                        </p>
                        {/* <div>
                          <p>
                            <span>$0</span>
                            <br />
                            off
                          </p>
                        </div> */}
                      </div>
                      <p>
                        When you submit the payment right now, you will be
                        charged for your first month of stay
                      </p>
                    </div>
                  </div>
                )}
                {index === 0 && (
                  <div className="stepItem21">
                    <span
                      style={{
                        color: "#fff",
                        background:
                          "linear-gradient(180deg, #0a49fa 0%, #286ff9 99.31%)"
                      }}
                    >
                      Today
                    </span>
                    <DocumentIcon alt="" />
                    <div>
                      <span>{label}</span>
                      <p>
                        After you submitting signature and payment you will
                        receive your official contract and the first payment
                        receipt
                      </p>
                    </div>
                  </div>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

const mapStateToProps = ({ room }) => ({
  detail: room.detail
});

const mapDispatchToProps = {
  getSelectRoomRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BookingStepper));
